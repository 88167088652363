import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import { axiosInstanceEcom } from '../../utils/axios';
//
import { dispatch } from '../store';
// fetch
import { fetchCreateSinglePriceForProductAI } from '../../utils/fetchData';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingCartItem: [],
  error: null,
  products: [],
  product: null,
  checkout: {
    cart: [],
    total: 0,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state) {
      return state
    },

    // ADD PRODUCT TO CART
    addCart(state, action) {
      const { product } = action.payload;
      
      const isEmptyCart = state.checkout.cart.length === 0;
      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.product_id === product.product_id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'product_id');
    },

    // Removes a single product from the cart
    updateCartContents(state, action) {
      const productID = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;
      const isSingleItemCart = state.checkout.cart.length === 1

      if (isEmptyCart || isSingleItemCart) {
        state.checkout.cart = [];
        state.checkout.total = 0
      } else {
        const foundProduct = state.checkout.cart.find((prod) => prod.product_id === productID);
        // Adjust total for removed item
        state.checkout.total -= foundProduct?.invoice_price;
        
        state.checkout.cart = state.checkout.cart.filter((_product) => _product.product_id !== foundProduct.product_id);
      }
      state.checkout.cart = uniqBy([...state.checkout.cart], 'id');
    },
    
    // CLEAR CART OF EVERYTHING
    resetCart(state) {
      state.checkout.cart = [];
      state.checkout.total = 0;
    },
  },

  // Handles responses from the createAsyncThunks below
  extraReducers: (builder) => {
    builder
      .addCase(sliceAddProductToCart.fulfilled, (state, action) => {
        // // Handle the fetched product data here
        // // For example, you can add it to your state like this:
        // const { productPriceAI, product } = action.payload;
        // console.log('productPriceAI', productPriceAI)
        // const productInCart = state.checkout.cart.find(productItem => productItem.product_id === product.product_id);
        
        // if (productInCart) {
        //   console.log("product in cart already")
        //   let adJustedTotal = productPriceAI.price;
        //   // Add the fetched data to the product in the cart
        //   // NOTE: add 15 for every variant
        //   if(productInCart.variant_count > 1) {
        //     adJustedTotal += ((productInCart.variant_count - 1) * 15)
        //     productInCart.invoice_price = adJustedTotal
        //   } else {
        //     productInCart.invoice_price = adJustedTotal
        //   }
        //   state.checkout.total += adJustedTotal
        // }

        const { productPriceAI, product } = action.payload;
        let productInCart = state.checkout.cart.find(productItem => productItem.product_id === product.product_id);
        
        let adJustedTotal = productPriceAI.price;
        // Add the fetched data to the product in the cart
        // NOTE: add 15 for every variant
        if(product.variant_count > 1) {
          adJustedTotal += ((product.variant_count - 1) * 15)
        }

        if (productInCart) {
          // If product is already in cart, update its invoice_price
          productInCart = {
            ...productInCart,
            invoice_price: adJustedTotal
          };
        } else {
          // If product is not in cart, add it with its invoice_price
          const newProduct = {
            ...product,
            invoice_price: adJustedTotal
          };
          state.checkout.cart.push(newProduct);
          productInCart = newProduct;
        }

        state.checkout.total += adJustedTotal;


      })
      .addCase(sliceAddProductToCart.rejected, (state, action) => {
        const { error, product } = action.payload;
        if(error === "Contact Success") {
          state.checkout.cart = state.checkout.cart.filter(productItem => productItem.product_id !== product.product_id);
        }
        state.error = action.payload.error;
      });
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  updateCartContents,
  resetCart,
} = slice.actions;

// ----------------------------------------------------------------------

// Chat GPT Price API
export const sliceAddProductToCart = createAsyncThunk(
  'product/sliceAddProductToCart',
  async (product, thunkAPI) => {
    try {      
      const state = thunkAPI.getState();

      const payload = {
        store_title: state.account.store.store_title,
        product_title: product.product_title,
        quantity: product.variant_count,
        vendor: product.variant_count,
        handle: product.handle
      };
      
      // if(state.admin.adminStatus) { // Skip price api for admins 
      //   return thunkAPI.fulfillWithValue({ productPriceAI: { price: 0 }, product });
      // }

      const productPriceAI = await fetchCreateSinglePriceForProductAI(payload);
      console.log('productPriceAI:::', productPriceAI)
      
      if(!Number.isInteger(productPriceAI.price)) { // If The AI Doesnt return a price
        return thunkAPI.rejectWithValue({ error: "Contact Success", product });
      }
      // console.log("state", productPriceAI, product)

      return thunkAPI.fulfillWithValue({ productPriceAI, product });
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  }
);

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoadingCartItem());
    try {
      const response = await axiosInstanceEcom.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstanceEcom.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
};


// INDIVIDUAL ITEMS LOADING
// startLoadingCartItem(state, action) {
//   state.isLoadingCartItem = {
//     id: action.payload.productId,
//     isLoading: true
//   };
// },

// stopLoadingCartItem(state) {
//   state.isLoadingCartItem = {
//     id: "",
//     isLoading: false
//   };
// },