import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// COMPONENTS
import BasicDrawer from './BasicDrawer';
import Image from '../Image';
import Iconify from '../Iconify';
// ASSETS
import loadoutExample from '../../assets/images/sections/loadouts/loadout_example.jpg';

LoadoutBestPracticesDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};


export default function LoadoutBestPracticesDrawer({ open, onClose }){
  return (
    <BasicDrawer open={open} onClose={onClose}>
      <Stack direction="column" sx={{ width: '100%', height: '100%', flex: 1 }}>
        <Stack direction="column" sx={{ mb: 0 }}>
  
          {/* Title */}
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} align="center" gutterBottom>
              Best Practices for Loadout Creation
            </Typography>
            <IconButton onClick={onClose} sx={{ position: 'absolute', top: 20, right: 10 }}>
              <Iconify icon={'ic:round-close'} width="20" height="20" />
            </IconButton>
          </Stack>
        </Stack>
  
        {/* Example Pictures */}
        <Box sx={{  maxWidth: '100%', margin: '0 auto'  }}>
          <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
            <Image 
              alt="loadout-example-1"
              src={loadoutExample} 
              sx={{ height: 580, width: '100%' }}
            />
          </Stack>
        </Box>
  
        {/* Action */}
        {/* <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 'auto' }}
        >
          <Button 
            variant="contained" 
            onClick={onClose}
          >
            Close
          </Button>
        </Stack> */}
  
      </Stack>
    </BasicDrawer>
  )
  
} 