import { 
  fetchUpdateEcommerceVariantFields, // 2023
  fetchUpdateModelFields, // 2023
  fetchVariantsByModelId,
  fetchModelCountLiveByStore
} from '../../utils/fetchData';
// helpers
import { getJWTstorage } from '../../utils/jwt'
import { V_LIST_IMPLEMENTATION_TEMPLATE } from '../../utils/levar_data';


// **************************************************************************
// Turn off model for multiple ecommerce variants and reset the variants implementation fields
// Input: 
//    modelStatus => model_status (true || false)
//    ModelID => model_3d_id (from model index) , 
//    EcomVariants => single string or multi list
// Output: Normal ES response
// **************************************************************************
export function setUpdateModel({ model3dId, fields }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      const modelPayload = {
        model_3d_id: model3dId,
        ...fields
      };

      const modelResponse = await fetchUpdateModelFields(modelPayload, jwt);

      return modelResponse.update_response;
    } catch(error) {
      console.log('error on setUpdateModel thunk', error);
      return error;
    }
  }
};


// Want to CONVERT TO ONE BELOW FULL IF we can
export function setModelStatusUpdateVariantFields({ 
  modelStatus, 
  model3dId, 
  ecommVariantIds,
  modelType
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      
      const modelTypeKey = modelType || 'model_3d_id';
          
      // if model_status === false want to write model_3d_id
      // if model_status === true want to delete model_3d_id and reset IMPLEMENTATION_TEMPLATE
      const modelPayload = {
        model_status: modelStatus,
        model_3d_id: model3dId
      };

      // Set Model status to off
      const modelResponse = await fetchUpdateModelFields(modelPayload, jwt);

      // Default Variant Implementation Values
      const defaultImplementation = V_LIST_IMPLEMENTATION_TEMPLATE;

      // Check if there are ecom variants to disconnect 
      if(modelStatus === 'off' && ecommVariantIds.length > 0) {
        await Promise.all(
          ecommVariantIds.map(async (variantId) => {
            const dataToUpdate = {
              variant_id: variantId,
              [modelTypeKey]: "",
              ...defaultImplementation
            };
            await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
          })
        );
      }

      return modelResponse.update_response;
      
    } catch(error) {
      console.log('error on setModelStatusUpdateVariantFields thunk', error);
      return error;
    }
  }
};


// **************************************************************************
// Completely Turn Off Model 2024
// Input: 
//    modelStatus => model_status (true || false)
//    ModelID => model_3d_id (from model index) , 
//    EcomVariants => single string or multi list
// Output: Normal ES response
// **************************************************************************
export function setModelFullOnOff({ 
  modelStatus, 
  model3dId, 
  modelType
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const newModelStatus = modelStatus === 'on' || modelStatus === undefined ? 'off' : 'on';
      const modelTypeKey = modelType || 'model_3d_id';

      if(newModelStatus !== 'on') {
        const modelCount = await fetchModelCountLiveByStore(store.store_id, jwt);
        const modelLimit = store.model_limit ?? 0;
    
        if(modelCount.total >= modelLimit) {
          alert('Not Enough Models In Subscription!')
          return false;
        }; 
      }

      const linkedVariantsResponse = await fetchVariantsByModelId(model3dId, jwt, admin.adminStatus, modelTypeKey);
      const linkedEcommVariantsIDs = linkedVariantsResponse.search_response.map(variant => variant.variant_id);
      
      // if model_status === false want to write model_3d_id
      // if model_status === true want to delete model_3d_id and reset IMPLEMENTATION_TEMPLATE

      const modelPayload = {
        model_status: newModelStatus,
        model_3d_id: model3dId
      };

      // // Set Model status to off || on
      const modelResponse = await fetchUpdateModelFields(modelPayload, jwt);

      // Default Variant Implementation Values
      const defaultImplementation = V_LIST_IMPLEMENTATION_TEMPLATE;

      // Check if there are ecom variants to disconnect 
      if(newModelStatus === 'off' && linkedEcommVariantsIDs.length > 0) {
        await Promise.all(
          linkedEcommVariantsIDs.map(async (variantId) => {
            const dataToUpdate = {
              variant_id: variantId,
              [modelTypeKey]: "",
              ...defaultImplementation
            };
            await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
          })
        );
      }

      return modelResponse.update_response;
      
    } catch(error) {
      console.log('error on setModelStatusUpdateVariantFields thunk', error);
      return error;
    }
  }
};
