import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

// components
import { 
  FormProvider, 
  RHFTextField, 
} from '../hook-form';
import Iconify from '../Iconify';
import { setUpdateEcommVariants } from '../../redux/thunks/ecommVariantThunks';
import { useDispatch } from '../../redux/store';
import { delay } from '../../utils/levarHelpers';

// ----------------------------------------------------------------------

VariantImplementationsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function VariantImplementationsDialog({ onClose, dialogData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  
  const OptionSubSchema = Yup.object().shape({
    option_name: Yup.string().nullable(),
    option_value: Yup.string().nullable(),
    option_prop: Yup.string().nullable(),
  });

  const VariantOptionsSchema = Yup.object().shape({
    current_variant_id: Yup.string(),
    option_1: OptionSubSchema,
    option_2: OptionSubSchema,
    option_3: OptionSubSchema,
  });

  const defaultValues = {
    current_variant_id: '',
    current_variant_title: '',
    option_1: {
      option_name: '',
      option_value: '',
      option_prop: '',
    },
    option_2: {
      option_name: '',
      option_value: '',
      option_prop: '',
    },
    option_3: {
      option_name: '',
      option_value: '',
      option_prop: '',
    },
  };

  const methods = useForm({
    resolver: yupResolver(VariantOptionsSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [state, setState] = useState({
    current_index: 0,
    max_index: 0,
    variants: [],
    pageReady: false,
    isWriting: false,
  });

  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && dialogData.variants && state.current_index === 0) {
      const getResource = async () => {

        if(dialogData.selectedVariant) {
          setValue("current_variant_id", dialogData.selectedVariant);
          setValue("current_variant_title", dialogData.variants.find(variant => variant.variant_id === dialogData.selectedVariant).variant_title);
          dialogData.variants.find(variant => variant.variant_id === dialogData.selectedVariant).options.forEach((option, index) => {
            if(option) {
              setValue(`option_${index + 1}.option_name`, option?.option_name || '');
              setValue(`option_${index + 1}.option_value`, option?.option_value || '');
              setValue(`option_${index + 1}.option_prop`, option?.option_prop || '');
            }
          });
        } else {
          setValue("current_variant_id", dialogData.variants[state.current_index].variant_id);
          setValue("current_variant_title", dialogData.variants[state.current_index].variant_title);
          dialogData.variants[state.current_index].options.forEach((option, index) => {
            if(option) {
              setValue(`option_${index + 1}.option_name`, option?.option_name || '');
              setValue(`option_${index + 1}.option_value`, option?.option_value || '');
              setValue(`option_${index + 1}.option_prop`, option?.option_prop || '');
            }
          });
        }



        setState((prevState) => ({
          ...prevState,
          current_index: 0,
          max_index: dialogData.variants.length - 1,
          variants: dialogData.variants,
          pageReady: true,
          isWriting: false,
        }))
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [dialogData, dispatch, setValue, state.current_index])

  // Submit edited options
  const onSubmit = async () => {
    try {
      const options = []
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith('option_')) {
          if(value.option_name !== '') {
            options.push(value);
          }
        }
      });


      await dispatch(setUpdateEcommVariants({ ecommVariantIds: values.current_variant_id, settingsToWrite: { options }, destructure: true }));
      enqueueSnackbar("Update Success!", { variant: 'success' });
    } catch (error) {
      console.error("onSubmit error", error);
    } finally {
      if(!dialogData.selectedVariant && state.current_index < state.max_index) { // On to the next one
        const nextIndex = state.current_index + 1; // Increase index
        const currentVariant = dialogData.variants[nextIndex]; // Get the next variant
        setValue("current_variant_id", currentVariant.variant_id);
        setValue("current_variant_title", currentVariant.variant_title);

        currentVariant.options.forEach((option, index) => {
          if(option) {
            setValue(`option_${index + 1}.option_name`, option?.option_name || '');
            setValue(`option_${index + 1}.option_value`, option?.option_value || '');
            setValue(`option_${index + 1}.option_prop`, option?.option_prop || '');
          } else { // Reset previous values
            setValue(`option_${index + 1}.option_name`, '');
            setValue(`option_${index + 1}.option_value`, '');
            setValue(`option_${index + 1}.option_prop`, '');
          }
        });
        setState((prevState) => ({
          ...prevState,
          current_index: nextIndex,
          pageReady: true,
          isWriting: false,
        }));
      } else { // Run out of variants reset and close
        await delay(200)
        reset();
        onClose();
      }
    }
  };

  return (
    <Box >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
          <Typography variant="h4" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
            Variant Options
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 20, top: 1 }}>
            <Iconify icon={'ic:round-close'} width="20" height="20" />
          </IconButton>

        <Divider />

        <Box sx={{ mt: 1, px: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Item>
                <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <b>Options For:</b> &nbsp;  { values.current_variant_title } / { values.current_variant_id }
                </Typography>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_1.option_name" size="small" label="Option 1 Name" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_1.option_value" size="small" label="Option 1 Value" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_1.option_prop" size="small" label="Option 1 Prop" />
              </Item>
            </Grid>

            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_2.option_name" size="small" label="Option 2 Name" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_2.option_value" size="small" label="Option 2 Value" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_2.option_prop" size="small" label="Option 2 Prop" />
              </Item>
            </Grid>

            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_3.option_name" size="small" label="Option 3 Name" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_3.option_value" size="small" label="Option 3 Value" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="option_3.option_prop" size="small" label="Option 3 Prop" />
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 1, px: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button
              variant="outlined" 
              size="medium" 
              onClick={() => reset()}
            >
              Reset
            </Button>
            <LoadingButton 
              variant="contained" 
              size="medium" 
              type="submit"
              loading={isSubmitting}
            >
              Write
            </LoadingButton>
          </Stack>
        </Box>
      </Stack>
      </FormProvider>
    </Box>
  )
};