import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import cartReducer from './slices/cart';
import accountReducer from './slices/account';
import loadingReducer from './slices/loading';
import adminReducer from './slices/admin';
import appReducer from './slices/app';
import dialogReducer from './slices/dialog';
import memorizedSettingsReducer from './slices/memorizedSettings'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const accountPersistConfig = {
  key: 'account',
  storage,
  keyPrefix: 'redux-',
};

const adminPersistConfig = {
  key: 'admin',
  storage,
  keyPrefix: 'redux-',
};

const memorizedSettingsPersistConfig = {
  key: 'memorizedSettings',
  storage,
  keyPrefix: 'redux-'
};

const rootReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, cartReducer),
  account: persistReducer(accountPersistConfig, accountReducer),
  loading: loadingReducer,
  admin: persistReducer(adminPersistConfig, adminReducer),
  app: appReducer,
  memorizedSettings: persistReducer(memorizedSettingsPersistConfig, memorizedSettingsReducer),
  dialog: dialogReducer,
});

export { rootPersistConfig, rootReducer };
