import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';

BasicDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
};

export default function BasicDrawer({ 
  onClose, 
  open,
  children,
  ...other 
}) {
  
  return (
    <Drawer
      variant="persistent"
      hideBackdrop
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          height: open ? '100%' : 0,
          position: 'absolute',
          p: open ? 3 : 0
        },
      }}
      SlideProps={{
        unmountOnExit: true
      }}
      {...other}
    >
      { children }
    </Drawer>
  );
}