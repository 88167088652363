import PropTypes from 'prop-types';
import { List, Box } from '@mui/material';
import { useSelector } from '../../../redux/store';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { user } = useAuth();
  const currentUser = useSelector(state => state.account.user);
  // const { integration_id: storeIntegrationID } = useSelector(state => state.account.integration);

  const shouldHideAnalytics = (group) => group.subheader === 'Analytics' && user.role !== 'admin';
  // const isWizardStageFive = () => currentUser.setup_wizard_state === 5 && user.role !== 'admin' && storeIntegrationID === 1;
  const isWizardStageFive = () => currentUser.setup_wizard_state === 5 && user.role !== 'admin';

  const shouldHideFromUser = (path) => [
    '/dashboard/store-admin/micro-site-overview',
    '/dashboard/store-admin/micro-site-builder'
  ].includes(path) && user.role !== 'admin';

  const isDisabledPath = (path) => [
    '/dashboard/analytics/overview',
    '/dashboard/creation/review',
    '/dashboard/creation/projects'
    // ...(storeIntegrationID !== 1 && '/dashboard/ecommerce/library')
    // && storeIntegrationID !== 1 '/dashboard/ecommerce/library'
    // '/dashboard/models/all'
  ].includes(path);

  return (
    <Box {...other}>
      {navConfig.map((group) => {

        if (shouldHideAnalytics(group)) return null;

        group.items.forEach(item => {
          if(shouldHideFromUser(item.path)) {
            item.hide = true
            return
          }
          item.disabled = isWizardStageFive() && isDisabledPath(item.path);
        });

        return (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
                background: 'none !important',
                fontSize: '.95em'
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list) => {
              if(list.hide) return null;
              return (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                  isCollapse={isCollapse}
                />
              )
            }
            )}
          </List>
        )
      })}
    </Box>
  );
}