import { createSlice } from '@reduxjs/toolkit';
// utils
import { axiosInstanceRDS } from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  user: {
    id: null,
    uuid: null,
    email: null,
    accepts_marketing: null,
    first_name: null,
    last_name: null,
    address: null,
    city: null,
    zip: null,
    state: null,
    country: null,
    business_name: null,
    website_url: null,
    phone_number: null,
    stripe_id: null,
    setup_wizard_state: null,
    created_at: null,
    user_shopify_url: null,
    feature_gate: null,
    analytics_gate: null,
    pipedrive_export: null,
    account_id: null,
    render: null,
    three_d: null,
    ar: null,
    social: null
  },
  integration: {
    id: null,
    integration_id: null,
    levar_account_id: null,
    levar_user_id: null
  },
  store: {
    platform: ''
  },
  error: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    },

    // GET INTEGRATION
    getIntegrationSuccess(state, action) {
      state.integration = action.payload;
    },

    getStoreSuccess(state, action) {
      state.store = action.payload;
    },

    getStripeSuccess(state, action) {
      state.store.stripe_billing = action.payload;
    },

    getStoreUpdate(state, action) {
      state.store = action.payload;
      // state.store = action.payload;
    },

    getStoreGA4RefreshToken(state, action) {
      state.store.google_oauth2_refresh_token = action.payload;
    },

    getStoreGA4PropertyId(state, action) {
      state.store.ga4_property_id = action.payload;
    },

    getStoreCustomLogo(state, action) {
      state.store.c_logo = action.payload.link;
    },

    getStoreAppNotInstalled(state, action) {
      state.store.app_intalled_status = action.payload.app_intalled_status
    },
    
    getResetAppNotInstalled(state, action) {
      state.store.app_intalled_status = true;
    },

    getUserInfoUpdates(state, action) {
      state.user.first_name = action.payload.first_name;
      state.user.last_name = action.payload.last_name;
      state.user.email = action.payload.email;
      state.user.country = action.payload.country;
      state.user.business_name = action.payload.business_name;
      state.user.phone_number = action.payload.phone_number;
    },

    resetAccountStore: (state) => {
      state.store = { ...initialState.store };
    },
    resetAccountIntegration: (state) => {
      state.integration = initialState.integration;
    },
    resetAccountUser: (state) => {
      state.user = initialState.user;
    },
    resetAccount: () => initialState
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  getUserSuccess,
  getIntegrationSuccess,
  getStoreSuccess,
  getStoreUpdate,
  getStoreGA4RefreshToken,
  getStoreGA4PropertyId,
  getStoreCustomLogo,
  getUserInfoUpdates,
  getStripeSuccess,
  getStoreAppNotInstalled,
  getResetAppNotInstalled,
  resetAccountStore,
  resetAccountIntegration,
  resetAccountUser,
  resetAccount
} = slice.actions;

// ----------------------------------------------------------------------

export function getUser(accountID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosInstanceRDS.get(`/api/v1/user_uuid/${accountID}`);
      // dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
