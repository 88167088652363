import { createSvgIcon } from '@mui/material/utils';

export const LevarIconStack = createSvgIcon(
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287.02 227.69">
    <g>
      <path className="cls-1" fill="#676c72" d="M165.61,154.45c.41,0,.63,.32,.49,.7l-26.09,72.2c-.07,.19-.29,.35-.49,.35h-14.86c-.2,0-.42-.16-.49-.34l-20.76-57.21h-35.45v11.93h19.78c.29,0,.52,.24,.52,.52v14.65c0,.29-.24,.52-.52,.52h-19.78v14.13h38.51c.29,0,.52,.24,.52,.52v14.75c0,.29-.24,.52-.52,.52H.52c-.29,0-.52-.24-.52-.52v-72.2c0-.29,.24-.52,.52-.52h15.49c.29,0,.52,.24,.52,.52v56.71H51.43v-56.71c0-.29,.23-.52,.52-.52h63.28c.21,0,.43,.16,.49,.35l15.57,45.16c.21,.56,.39,1.06,.55,1.52,.16,.45,.25,.78,.29,.99,0-.1,.07-.39,.21-.86,.14-.47,.31-1.02,.52-1.65l15.68-45.16c.07-.19,.29-.35,.49-.35h16.57Z"/>
      <path className="cls-2" fill="#1d252c" d="M286.96,226.94l-13.24-27.19c2.76-1.22,5.1-3.01,7.04-5.36,1.94-2.35,3.41-4.95,4.42-7.8,1.01-2.84,1.52-5.61,1.52-8.29,0-4.29-.79-8.25-2.38-11.88-1.59-3.63-4.21-6.53-7.87-8.71-3.66-2.18-8.62-3.27-14.86-3.27h-35.79c-.29,0-.52,.23-.52,.52v69.8l-28-70c-.07-.18-.29-.33-.49-.33h-15.09c-.2,0-.41,.15-.49,.33l-28.78,72.2c-.16,.39,.06,.72,.49,.72h16.97c.2,0,.42-.15,.49-.34l5.21-13.58h27.31l5.31,13.58c.07,.18,.29,.33,.49,.33h32.59c.29,0,.52-.23,.52-.52v-24.8h13.92l12.93,25.04c.09,.17,.27,.28,.46,.28h17.37c.39,0,.64-.4,.47-.75Zm-105.82-28.44l5.96-14.65c.21-.49,.44-1.04,.71-1.65,.26-.61,.51-1.24,.76-1.88,.24-.64,.45-1.25,.63-1.8,.17,.56,.38,1.16,.63,1.8,.24,.65,.5,1.27,.76,1.88,.26,.61,.5,1.16,.71,1.65l5.96,14.65h-16.11Zm87.69-16.01c-.47,1.29-1.27,2.33-2.41,3.11-1.13,.78-2.68,1.18-4.63,1.18h-19.99v-16.74h19.99c1.99,0,3.55,.4,4.68,1.2,1.13,.8,1.93,1.83,2.38,3.09,.45,1.26,.68,2.58,.68,3.98,0,1.5-.24,2.9-.71,4.19Z"/>
    </g>
    <path className="cls-2" fill="#1d252c" d="M150.56,90.52l38.69,27.57c.63,.49,.28,1.49-.51,1.49H111.36c-.79,0-1.14-1-.51-1.49l38.69-27.57c.3-.23,.72-.23,1.02,0ZM197.57,.18L88.43,85.45s-.02,.02-.02,.03l-.31,.63s0,.02,0,.03v32.59c0,.7,.8,1.09,1.35,.66L198.59,34.12s.02-.02,.02-.03l.31-.63s0-.02,0-.03V.84c0-.7-.8-1.09-1.35-.66Z"/>
  </svg>, 'levarIconStack');
