// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ACCOUNT_OWNER = '/account';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  wizard: path(ROOTS_AUTH, '/wizard'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'), // Force password reset (cognito)
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'), // Forgot password
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  // ROOT 
  root: ROOTS_DASHBOARD,

  // GENERAL
  general: {
    overview: path(ROOTS_DASHBOARD, '/overview'),
  },

  // CREATION 
  creation: {
    root: path(ROOTS_DASHBOARD, '/creation'),
    projects: path(ROOTS_DASHBOARD, '/creation/projects'), // Table
    loadouts: (name) => path(ROOTS_DASHBOARD, `/creation/projects/${name}`), // Table based on previous table selection
    progress: path(ROOTS_DASHBOARD, '/creation/progress'),
    review: path(ROOTS_DASHBOARD, '/creation/review'),
    approved: path(ROOTS_DASHBOARD, '/creation/approved'),
  },

  // UPLOAD 3D
  upload: {
    root: path(ROOTS_DASHBOARD, '/upload'),
    ecommerce: path(ROOTS_DASHBOARD, '/upload/ecommerce'),
    view: (product) => path(ROOTS_DASHBOARD, `/upload/ecommerce/${product}`),
    model: path(ROOTS_DASHBOARD, '/upload/model'),
  },

  // CREATE 3D 
  create: {
    root: path(ROOTS_DASHBOARD, '/create'),
    threeD: path(ROOTS_DASHBOARD, '/create/project'),
    products: (name) => path(ROOTS_DASHBOARD, `/create/project/${name}/products`), // New selfservice creation route
    loadouts: (name) => path(ROOTS_DASHBOARD, `/create/project/${name}/loadouts`),
    complete: (name) => path(ROOTS_DASHBOARD, `/create/project/${name}/complete`),
    review: (name) => path(ROOTS_DASHBOARD, `/create/project/${name}/review`), 
    cart: (name) => path(ROOTS_DASHBOARD, `/create/project/${name}/cart`), // Invoice route
    ecommerce: path(ROOTS_DASHBOARD, '/create/project'),
  },

  // Ecommerce
  ecommerce: {
    root: path(ROOTS_DASHBOARD, '/ecommerce'),
    library: path(ROOTS_DASHBOARD, '/ecommerce/library'),
    view: (product) => path(ROOTS_DASHBOARD, `/ecommerce/${product}`),
  },

  // ALL MODELS PAGE
  modelLibrary: {
    root: path(ROOTS_DASHBOARD, '/models'),
    all: path(ROOTS_DASHBOARD, '/models/all'),
    view: (name) => path(ROOTS_DASHBOARD, `/models/${name}`),
  },

  // CHAT WINDOW
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },

  // ACCOUNT SETTINGS
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    general: path(ROOTS_DASHBOARD, '/account/general'),
    billing: path(ROOTS_DASHBOARD, '/account/billing'),
    notifications: path(ROOTS_DASHBOARD, '/account/notifications'),
    social: path(ROOTS_DASHBOARD, '/account/social'),
    password: path(ROOTS_DASHBOARD, '/account/password'),
    new: path(ROOTS_DASHBOARD, '/account/new'),
    list: path(ROOTS_DASHBOARD, '/account/list'),
    cards: path(ROOTS_DASHBOARD, '/account/cards'),
    profile: path(ROOTS_DASHBOARD, '/account/profile'),
    account: path(ROOTS_DASHBOARD, '/account/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/account/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/account/reece-chung/edit`),
  },

  // STORE SETTINGS
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    overview: path(ROOTS_DASHBOARD, '/store/overview'),
  },

  // ANALYTICS ROUTES
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    overview: path(ROOTS_DASHBOARD, '/analytics/overview')
  },

  // Store Admin ROUTES
  admin: {
    root: path(ROOTS_DASHBOARD, '/store-admin/micro-site-overview'),
    micro_overview: path(ROOTS_DASHBOARD, '/store-admin/micro-site-overview'),
    micro_builder: path(ROOTS_DASHBOARD, '/store-admin/micro-site-builder'),
    micro_view: (micrositeid) => path(ROOTS_DASHBOARD, `/store-admin/micro-site-builder/${micrositeid}`),
  },
};

// ADMIN ROUTES
export const PATH_ACCOUNT_OWNER = {
  root: ROOTS_ACCOUNT_OWNER,
  general: {
    accountStores: path(ROOTS_ACCOUNT_OWNER, '/account-stores'),
  },
}

// ADMIN ROUTES
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,

  // Shallow routes
  adminOverview: path(ROOTS_ADMIN, '/admin-overview'),
  adminForbiddenRoom: path(ROOTS_ADMIN, '/forbidden-room'),
  stores: {
    root: path(ROOTS_ADMIN, '/stores'),
    all: path(ROOTS_ADMIN, '/stores/all'),
    active: path(ROOTS_ADMIN, '/stores/active'),
    projects: path(ROOTS_ADMIN, '/stores/projects'),
    loadouts: path(ROOTS_ADMIN, '/stores/loadouts')
  }
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';


// JUNK ROUTES ??
// calendar: path(ROOTS_DASHBOARD, '/calendar'),
// kanban: path(ROOTS_DASHBOARD, '/kanban'),
// permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
// overview: path(ROOTS_DASHBOARD, '/overview'),
// ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
// analytics: path(ROOTS_DASHBOARD, '/analytics'),
// banking: path(ROOTS_DASHBOARD, '/banking'),
// booking: path(ROOTS_DASHBOARD, '/booking'),
  // post: {
  //   root: `${ROOTS.DASHBOARD}/post`,
  //   new: `${ROOTS.DASHBOARD}/post/new`,
  //   details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
  //   edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
  //   demo: {
  //     details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
  //     edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
  //   },
  // },

  // // PIPELINE 
  // modelCreation: {
  //   root: path(ROOTS_DASHBOARD, '/products'),
  //   allProducts: path(ROOTS_DASHBOARD, '/products/all'),
  //   loadout: path(ROOTS_DASHBOARD, '/products/loadout'),
  //   payment: path(ROOTS_DASHBOARD, '/products/payment'),
  //   progress: path(ROOTS_DASHBOARD, '/products/progress'),
  //   review: path(ROOTS_DASHBOARD, '/products/review'),
  //   approved: path(ROOTS_DASHBOARD, '/products/approved'),
  // },
