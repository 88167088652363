import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_ACCOUNT_OWNER, PATH_ADMIN } from '../../../routes/paths';
// Redux
import { useSelector, useDispatch } from '../../../redux/store';
import { setDashboardLogout, setReturnFromImpersonation } from '../../../redux/thunks/loginThunks';
import { getDialog } from '../../../redux/slices/dialog';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: PATH_DASHBOARD.general.overview,
    icon: 'material-symbols:home'
  },
  {
    label: 'Account',
    linkTo: PATH_DASHBOARD.account.general,
    icon: 'material-symbols:manage-accounts-rounded'
  },
  {
    label: 'Store',
    linkTo: PATH_DASHBOARD.store.general,
    icon: 'material-symbols:store-rounded'
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout } = useAuth();
  const { user: { setup_wizard_state: userSetupWizardState }, store: { platform: storePlatform } } = useSelector((state) => state.account)

  const isMountedRef = useIsMountedRef();

  const [open, setOpen] = useState(null);

  // console.log('storePlatform', storePlatform)

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await dispatch(setDashboardLogout());
      await logout();
      
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleNav = (path) => {
    navigate(path, { replace: true });
    handleClose()
  };

  const handleNavToHigherAuthView = (path) => {
    // Reset the imperonated redux state
    dispatch(setReturnFromImpersonation());
    navigate(path, { replace: true });
    handleClose();
  };

  const handleOpenProductCSVUploadDialog = async () => {
    await dispatch(getDialog({
      dialogStatus: true,
      dialogType: 'productCSVUploadDialog',
      dialogData: {},
    }))
    handleClose();
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        { (user.role === 'admin' ? <Avatar src="" sx={{ bgcolor: 'rgba(24, 144, 255, 0.16)' }} alt="LEVAR Admin" >A</Avatar> :  <Avatar src="" alt="LEVAR User" />) }
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            LEVAR User
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        
          {/* Account owner routes for LSA */}
          { user.role === 'account_owner' &&
            <Stack direction="column">
                <Stack sx={{ p: 1 }}>
                  <MenuItem key={"storeSelect"} to={PATH_ACCOUNT_OWNER.general.accountStores} onClick={() => handleNavToHigherAuthView(PATH_ACCOUNT_OWNER.general.accountStores)}>
                    <Iconify icon="mdi:administrator-outline" sx={{ mr: 2 }} />
                    <Typography variant="body2">Owner Store Select</Typography>
                  </MenuItem>
                  </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Stack>
          }

          {/* Admin routes when impersonating and not impersonating */}
          { user.role === 'admin' &&
            <Stack direction="column">
                <Stack sx={{ p: 1 }}>
                  <MenuItem key={"adminStoreSelect"} to={PATH_ADMIN.stores.all} onClick={() => handleNavToHigherAuthView(PATH_ADMIN.stores.all)}>
                    <Iconify icon="mdi:administrator" sx={{ mr: 2 }} />
                    <Typography variant="body2">All Stores</Typography>
                  </MenuItem>
                  <MenuItem key={"activeStoreSelect"} to={PATH_ADMIN.stores.active} onClick={() => handleNavToHigherAuthView(PATH_ADMIN.stores.active)}>
                    <Iconify icon="mdi:administrator" sx={{ mr: 2 }} />
                    <Typography variant="body2">Active Stores</Typography>
                  </MenuItem>
                  <MenuItem key={"adminProjectSelect"} to={PATH_ADMIN.stores.projects} onClick={() => handleNavToHigherAuthView(PATH_ADMIN.stores.projects)}>
                    <Iconify icon="mdi:administrator" sx={{ mr: 2 }} />
                    <Typography variant="body2">All Projects</Typography>
                  </MenuItem>
                  <MenuItem key={"adminLoadoutSelect"} to={PATH_ADMIN.stores.loadouts} onClick={() => handleNavToHigherAuthView(PATH_ADMIN.stores.loadouts)}>
                    <Iconify icon="mdi:administrator" sx={{ mr: 2 }} />
                    <Typography variant="body2">All Loadouts</Typography>
                  </MenuItem>
                </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Stack>
          }

          { /* if user is greater than stage 5 give them access to other routes in dashboard */ }
          { userSetupWizardState > 5 && 
            <Stack direction="column">
                <Stack sx={{ p: 1 }}>
                  {
                    MENU_OPTIONS.map((option) => (
                      <MenuItem key={option.label} to={option.linkTo} onClick={() => handleNav(option.linkTo)}>
                        <Iconify icon={option.icon} sx={{ mr: 2 }} />
                        <Typography variant="body2">{option.label}</Typography>
                      </MenuItem>
                    ))
                  }
                </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Stack>
          }

        { (storePlatform === 'levar')  &&
        <MenuItem onClick={handleOpenProductCSVUploadDialog}>
          <Iconify icon="material-symbols:upload" sx={{ mr: 2 }} />
          <Typography variant="body2">Upload Product Catalog</Typography>
        </MenuItem>
        }

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Iconify icon="material-symbols:logout" sx={{ mr: 2 }} />
          <Typography variant="body2">Logout</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
};