import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// Redux
import { useSelector } from '../../redux/store';
// Components
import {
  RHFCheckbox,
} from '../hook-form';
import Iconify from '../Iconify';

// ------------------------------------------------------------------------

const LabelStyle = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

const MainCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({ 
    border: borderActive ? `solid 2px ${theme.palette.gold.light}` : 'solid 2px #ffffff00',
    maxHeight: 230, 
    minHeight: 230
}));

// ------------------------------------------------------------------------

FormTodoChecklistCard.propTypes = {
  ready: PropTypes.bool,
  // methods: PropTypes.any,
  fieldsTodo: PropTypes.any,
  appendTodo: PropTypes.func,
  removeTodo: PropTypes.func,
};

export default function FormTodoChecklistCard({ 
  ready, 
  // methods,
  fieldsTodo,
  appendTodo,
  removeTodo,
}) {
  // Redux
  const { admin: { adminStatus } } = useSelector(state => state);
  // Form
  // const { watch } = methods;

  // const values = watch();

  const [newField, setNewField] = useState('');

  const addToList = () => {
    appendTodo({ name: `${fieldsTodo.length - 1}`, label: newField, value: false });
    setNewField('')
  };

  const removeFromList = (index) => {
    removeTodo(index);
  };

  if(!ready) {
    return (
      <Skeleton 
        animation="wave" 
        variant="rectangular" 
        width={'100%'} 
        height={290}
        sx={{ borderRadius: 2 }} 
      />
    )
  };
  
  return (
    <MainCardStyle borderActive={"true"}>

      <LabelStyle sx={{ pt: 2, pr: 2, pl: 2 }}>
        <Stack direction="row" alignItems="center">
        <Iconify icon="svg-spinners:pulse-2" width={22} height={22} color="orange" sx={{ mr: 1 }}/>
        {fieldsTodo.length === 0 ? '' : fieldsTodo.length} Total To-Do's
        </Stack>

      </LabelStyle>

      <Typography variant="body2" sx={{ pt: 1, pr: 2, pl: 2 }}>
        (Confirm you have addressed the feedback to resubmit)
      </Typography>

      <Box
        sx={{ 
          display: 'flex', 
          flexDirection: "column", 
          height: '100%',
          mx: 2.5, 
          my: 2,
          maxHeight: 145,
          minHeight: 50,
          overflowY: 'auto',
          
        }}
      >
        <Stack 
          sx={{ 
            py: .5,
            px: 2,
            background: '#f8f8f854',
            border: 'solid 1px #cccccc',
            borderRadius: .5,
            boxShadow: 'inset 0 0 3px 0px #231f2078'
          }}>

          {adminStatus && 
          <Stack sx={{ py: 1 }}>
            <TextField 
              label="Input Client Action Required" 
              id="new-checkbox-text-field"
              variant="outlined" 
              type="text"
              size="small"
              value={newField}
              onChange={(event) => setNewField(event.target.value)} 
              InputProps={{
                endAdornment: ( 
                  <InputAdornment position="end">
                    <IconButton onClick={addToList} size="small">
                      <Iconify icon="material-symbols:add-circle-outline" width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
          }
          {fieldsTodo.map((todo, key) => (
          <Stack key={todo.id} direction="row" justifyContent="space-between" alignItems="center">
            <RHFCheckbox
              key={todo.id}
              name={`loadoutTodos[${key}].value`}
              label={todo.label}
            />
            { adminStatus &&
              <IconButton onClick={() => removeFromList(key)}>
                <Iconify icon="material-symbols:delete-forever-outline-rounded" width={22} height={22} />
              </IconButton>

            }
          </Stack>
          ))}
        </Stack>

      </Box>

    </MainCardStyle>
  )
};