import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// Forms
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// Material UI
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
// Components
import Iconify from '../../Iconify';
import { fetchAllProjects, fetchCreateLoadout, fetchDeleteLoadout } from '../../../utils/fetchData';
import { FormProvider, RHFTextField, RHFSelect, RHFMultiSelect } from '../../hook-form';
import { useSelector } from '../../../redux/store';
import { getJWTstorage } from '../../../utils/jwt';
import { delay } from "../../../utils/levarHelpers";
import useAuth from '../../../hooks/useAuth';
import { fDateTimeLevar } from '../../../utils/formatTime';

LoadoutCombiner.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
}

const defaultState = {
  active: false, 
  loading: true,
  projects: [],
  loadouts: [],
  loadoutsForMenu: []
}

export default function LoadoutCombiner({ onClose, dialogData }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const admin = useSelector((state) => state.admin);
  const [state, setState] = useState(defaultState);

  const defaultValues = {
    storeID: dialogData.store.store_id ?? "",
    selectedProjectID: "",
    loadoutIDsToCombine: [],
  };

  const LoadoutCombinerSchema = Yup.object().shape({
    storeID: Yup.string(),
    projectID: Yup.string(),
    loadoutIDsToCombine: Yup.array(),
  });

  const methods = useForm({
    resolver: yupResolver(LoadoutCombinerSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onResetSelection = () => {
    setValue("selectedProjectID", "")
    setValue("loadoutIDsToCombine", [])
  };

  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && state.active) {
      const getResource = async () => {
        const jwt = getJWTstorage(admin, user.sub);
        const projectsResponse = await fetchAllProjects(dialogData.store.store_id, jwt);
        const projects = projectsResponse.project_search_response.sort((a, b) => b.created_at.localeCompare(a.created_at));
        const loadouts = projectsResponse.loadout_search_response.sort((a, b) => b.created_at.localeCompare(a.created_at));
        const loadoutsForMenu = loadouts.map((loadout) => ({
          label: `${loadout.loadout_title} -- ${fDateTimeLevar(loadout.created_at)} -- ${loadout?.linked_products[0]?.variants.length} Variants`,
          value: loadout.loadout_id
        }));
        setState((prevState) => ({ ...prevState, active: true, loading: false, loadouts, projects, loadoutsForMenu }));
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [state.active, dialogData, admin, user.sub])

  const onSubmit = async () => {
    try {
      const jwt = getJWTstorage(admin, user.sub);
      // Loadouts selected will be deleted after combination
      const loadoutsIDsToDelete = values.loadoutIDsToCombine;
      const loadoutsToCombine = state.loadouts.filter((loadout) => loadoutsIDsToDelete.includes(loadout.loadout_id));

      const reformedLoadouts = loadoutsToCombine.reduce((acc, loadoutObj) => { // Update level2 list with new postions
        if(loadoutObj.additional_product_photos.length > 0) {
          acc.additional_product_photos.push(...loadoutObj.additional_product_photos);
        }
        if(loadoutObj.linked_products.length > 0) {
          acc.linked_products.push(...loadoutObj.linked_products);
        }
        if(loadoutObj.product_details.length > 0) {
          acc.product_details.push(loadoutObj.product_details);
        }
        return acc;
      }, { additional_product_photos: [], linked_products: [], product_details: [] });

      const combinedLoadout = {
        project_id: values.selectedProjectID,
        loadout_title: `${loadoutsIDsToDelete.length} Loadouts Combined for ${dialogData.store.store_title}`,
        linked_products: reformedLoadouts.linked_products,
        additional_product_photos: reformedLoadouts.additional_product_photos,
        product_details: reformedLoadouts.product_details.join(", "),
        loadout_type: 1,
        loadout_wizard_stage: 3,
        client_loadout_stage: 7,
        store_id: dialogData.store.store_id,
        store_title: dialogData.store.store_title,
      };

      const combinedLoadoutResponse = await fetchCreateLoadout(combinedLoadout, jwt);
      if(combinedLoadoutResponse.statusCode > 399) {
        enqueueSnackbar("Combine Failed!", { variant: 'error' });
        return;
      }

      await Promise.all(loadoutsIDsToDelete.map(async (loadoutID) => {
        await fetchDeleteLoadout(loadoutID, jwt);
      }));

      enqueueSnackbar("Loadout Combine Success & Deleted Old Loadouts!", { variant: 'success' });
    } catch(error) {
      console.error("error in loadout combine form", error);
      enqueueSnackbar("Loadout Combine Error!", { variant: 'error' });
    } finally {
      await delay(300)
      setState(defaultState);
      onClose();
    }
  };

  if(!state.active) {
    return (
      <Card sx={{ p: 2, height: 300 }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%' }}>
          <Typography variant="h6">Loadout Combiner</Typography>
          <Button size="medium" variant="contained" onClick={() => setState((prev) => ({ ...prev, active: true }))}>
            Activate
          </Button>
        </Stack>
      </Card>
    )
  }

  if(state.active && state.loading) {
    return (
      <Card sx={{ p: 2, height: 300 }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%' }}>
          <Typography variant="h6">Loadout Combiner</Typography>
          <CircularProgress />
        </Stack>
      </Card>
    )
  }

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} direction="column" alignItems="left" sx={{ pb: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Typography variant="h5">Loadout Combiner</Typography>
            <IconButton size="medium" onClick={() => setState((prev) => ({ ...prev, active: false }))}>
              <Iconify icon={'mdi:close'} />
            </IconButton>
          </Stack>
        </Stack>

        <Divider />

        <Grid container direction="row" spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <RHFTextField name="storeID" label="CurrentStore ID" type="text" size="medium"  />
          </Grid>
          <Grid item xs={9}>
            <RHFSelect name="selectedProjectID" label="Select a Project" size="medium"  disabled={!values.storeID}>
              {state.projects.map((option) => (
                <MenuItem key={option.project_id} value={option.project_id}>
                  {option.project_title} - {option.updated_at && fDateTimeLevar(option.updated_at)}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sx={{ my: 2}}>
            <RHFMultiSelect 
              chip
              checkbox
              name="loadoutIDsToCombine" 
              label="Select Loadouts to Combine" 
              size="medium" 
              options={!values.selectedProjectID ? [{ label: "No Project Selected", value: "" }] : state.loadoutsForMenu} 
              disabled={!values.selectedProjectID} 
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>

        <Divider />

        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mt: 3 }}>
          <Button 
            size="medium"
            variant="outlined"
            sx={{ mr: 1, minWidth: 50 }}
            onClick={onResetSelection}
            endIcon={<Iconify icon={'mdi:trash'} />}
            disableElevation
          >
            Clear Selection
          </Button>
          <LoadingButton 
            size="medium"
            variant="contained"
            endIcon={<Iconify icon={'ph:copy'} />}
            type="submit"
            loading={isSubmitting}
            disableElevation
          >
            Combine Loadouts
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  )
}