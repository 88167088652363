import { useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// FORM
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
// Hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// Redux
import { useDispatch } from '../../redux/store';
// Thunks
import { setConnectStoreLater } from '../../redux/thunks/loginThunks'
// Componenets
import Iconify from '../Iconify';
import { FormProvider, RHFTextField } from '../hook-form';
// Helpers
import { VerifyStoreUrl } from '../../utils/signupHelpers';
// ENV
const { REACT_APP_SHOPIFY_APP_URL } = process.env;

AfterTheFactSignupDialogForm.propTypes = {
  onClose: PropTypes.func,
}

export default function AfterTheFactSignupDialogForm({ onClose }){
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();

  const IntegrationSchema = Yup.object().shape({
    storeURL: Yup.string().required('Store URL is required'),
    storeType: Yup.string()
  });

  const defaultValues={
    storeURL: '',
    storeType: ''
  };

  const methods = useForm({
    resolver: yupResolver(IntegrationSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch()

  useEffect(() => {
    if(values.storeURL) {
      const lowered = values.storeURL.toLowerCase()
      if(lowered.includes("mybigcommerce")) {
        setValue('storeType', 'bco')
      }
      if(lowered.includes("myshopify")){
        setValue('storeType', 'shp')
      } 
    }
  }, [values.storeURL, setValue]);

  const onSubmit = async (data) => {
    try {
      // Check url
      const { finalURL, status } = VerifyStoreUrl(data.storeURL, data.storeType);
      if(!status) setError('afterSubmit', { message: 'Store URL Error' });

      // Write data to RDS
      await dispatch(setConnectStoreLater({ storeURL: finalURL, storeType: data.storeType }));

      // Redirect to shopify app install
      setTimeout(() => {
        window.location.assign(`${REACT_APP_SHOPIFY_APP_URL}?shop=${finalURL}`)
      }, 200);

    } catch(error) {
      console.log(error)
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    } finally {
      onClose()
    }
  }


  const logoDecision = () => {
    switch(values.storeType){
      case 'shp':
        return (
          <Stack spacing={1}>
            <Iconify icon={'logos:shopify'} sx={{ height: 50, width: 50}} />
            <Typography variant="subtitle1">Shopify</Typography>
          </Stack>
        );
      case 'bco':
        return (
          <Stack spacing={1}>
          <Iconify icon={'simple-icons:bigcommerce'} sx={{ height: 50, width: 50}} />
          <Typography variant="subtitle1">BigCommerce</Typography>
        </Stack>
        )
      default:
        return(
          <Stack spacing={1}>
            <Iconify icon={'logos:shopify'} sx={{ height: 50, width: 50}} />
            <Typography variant="subtitle1">Shopify</Typography>
          </Stack>
        )
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      
      <Stack direction="row" >
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      <div style={{flex: '1 0 0'}} />
        <IconButton onClick={onClose}>
          <Iconify icon={'ic:round-close'} width="20" height="20" />
        </IconButton>
        
      </Stack>
      <Stack spacing={3} direction="column" alignItems="center" sx={{ m: 2}}>
      
        {logoDecision()}
        
        <Typography variant="title1">Integrate your shop</Typography>
        
        <RHFTextField name="storeURL" label="Ecommerce Store URL" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Continue
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}