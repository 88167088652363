import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import Drawer from '@mui/material/Drawer';
// Redux
import { useDispatch } from '../../../../redux/store';
// thunks
import {
  setEcommerceProductVariantMulti
} from '../../../../redux/thunks/ecommProductThunks';
// components
import VariantImageDrawer from './VariantImageDrawer'

// ----------------------------------------------------------------------

VariantImageDrawerController.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onAddEcomImages: PropTypes.func,
  methods: PropTypes.any,
};

export default function VariantImageDrawerController({ 
  onClose, 
  open,
  methods,
  ...other 
}) {
  
  // redux
  const dispatch = useDispatch();

  // state
  const [ready, setReady] = useState(false);
  const [state, setState] = useState({
    allLinkedProductData: [],
    productImages: [],
    productTitle: '',
    variantCount: 0,
  });

  const { getValues } = methods;

  // ----------------------
  // API Data Fetch (Single Product Information)
  // ----------------------
  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && open) {
      const getResource = async () => {
        setReady(false);

        // Temp ARR is used to check duplicate image URLs
        const tempArr = [];

        const linkedProducts = getValues('linked_products');

        const productIDs = linkedProducts.map(item => item.product_id);

        const allProductVariants = await dispatch(setEcommerceProductVariantMulti({ productIDs }));

        const filteredProductVariants = allProductVariants.reduce((acc, row) => {
          const tempProductID = Object.keys(row)[0];

          const linkedProduct = linkedProducts.find(item => item.product_id === tempProductID)

          const linkedVariants = row[tempProductID].variants.filter(variant => {
            // Filtering for included variants only                                          &&   Filtering out duplicate image urls
            if(linkedProduct.variants.includes(variant.variant_id) && !tempArr.includes(variant?.image_url)) {
              
              // Adding alt images if they exist 
              if(variant?.alt_images?.length > 0) {
                tempArr.push(variant.alt_images)
              }

              // Handling variants with no images. Just reassiging the variant image to the value of product image
              if(!variant?.image_url && linkedProduct.product_image) {
                variant.image_url = linkedProduct.product_image
                tempArr.push(linkedProduct.product_image)
              } else {
                // Normal route because usually variant_image_url exists
                tempArr.push(variant.image_url)
              }
              
              return variant;
            }
            return null;
          });

          acc.push(linkedVariants);
          return acc
        }, []);


        setState((prev) => ({ 
          ...prev, 
          allLinkedProductData: [...filteredProductVariants]
        }));

        setReady(true);
      };
      getResource();
    }
    return function cleanup() {
      isComponentMounted = false;
    }
  }, [dispatch, getValues, open]);
  

  return (
    <Drawer
      variant="persistent" 
      hideBackdrop
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          height: open ? '100%' : 0,
          position: 'absolute',
          p: open ? 3 : 0,
        },
      }}
      SlideProps={{
        unmountOnExit: true
      }}
      {...other}
    > 

      {/* Variant Drawer Selector */}
      <VariantImageDrawer 
        dataReady={ready}
        onClose={onClose}
        allLinkedProductData={state.allLinkedProductData}
        productImages={state.productImages} 
        methods={methods}
      />
      
    </Drawer>
  );
}