// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// assets
import { MaintenanceIllustration } from '../assets/index';

// ----------------------------------------------------------------------

export default function Maintenance() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Stack sx={{ alignItems: 'center' }}>
        <Typography variant="h3" sx={{ mb: 2 }} aria-label="The Levar Dashboard is currently under planned maintenance">
          The Levar Dashboard is currently under planned maintenance
        </Typography>

        <Typography variant="title1" sx={{ color: 'text.secondary' }} aria-label="We are working hard on implementing new features for the Levar Dashboard!">
          We are working hard on implementing new features check back in a little bit!
        </Typography>

        <MaintenanceIllustration sx={{ my: 10, height: 240 }} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', mb: 4 }} aria-label="If you are still seeing this message, please refresh your page or contact support">
              If you are still seeing this message after waiting for a while, please refresh your page or contact support
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button href="mailto:support@levar.io" rel="noreferrer" target="_blank" size="large" variant="outlined" aria-label="Send an Email to our Support Team">
              Contact Support
            </Button>
            <Button onClick={() => { window.location.href = 'https://levar.io' }} size="large" variant="contained" aria-label="Navigate to our website">
              Go to our website
            </Button>
          </Stack>
        </Box>

    </Stack>
    </Box>

  );
}
