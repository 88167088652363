// @mui
// import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD, PATH_ACCOUNT_OWNER, PATH_ADMIN, PATH_AUTH } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = '';

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  clientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

export const PATH_BEFORE_LOGIN = PATH_AUTH.login; // as '/auth/'
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.overview; // as '/dashboard/'
export const PATH_AFTER_LOGIN_ACCOUNT_OWNER = PATH_ACCOUNT_OWNER.general.accountStores; // as '/account/account-stores'
export const PATH_AFTER_LOGIN_ADMIN = PATH_ADMIN.adminOverview;  // as '/admin/admin-overview'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  // MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 56,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 56 - 6,
};

// export const HEADER = {
//   MOBILE_HEIGHT: 64,
//   MAIN_DESKTOP_HEIGHT: 88,
//   DASHBOARD_DESKTOP_HEIGHT: 92,
//   DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
// };

export const NAVBAR = {
  BASE_WIDTH: 250,
  DASHBOARD_WIDTH: 250,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false,
};


