import { format, getTime, formatDistanceToNow, parseISO } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeUnix(date) {
  return format(new Date(date * 1000), 'dd/MMM/yyyy');
}

export function fDateLevar(date) {
  return format(parseISO(date), 'dd/MM/yyyy')
}

export function fDateLevarAmerica(date) {
  return format(parseISO(date), 'MM/dd/yyyy')
}

export function fDateTimeLevar(date) {
  
  // Parse the date / convert to work for date formats from Shp or Custom stores
  const newData = new Date(date.replace(/(\d{2})\/(\w{3})\/(\d{4})/, "$2 $1, $3"));
  
  // Convert to ISO and remove 'Z' at the end
  const isoDate = newData.toISOString().slice(0,-1);
  
  return format(parseISO(isoDate), 'dd MMM yyy')
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
