import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoIdentityProviderClient, AdminInitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
// import { useNavigate } from 'react-router-dom';
import { axiosInstance, axiosInstanceEcom, axiosInstanceRDS } from '../utils/axios';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import { COGNITO_API } from '../config';


// ----------------------------------------------------------------------

const cognitoClient = new CognitoIdentityProviderClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_COGNITO_KEY_ID, // Replace with your IAM user's access key ID
    secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY, // Replace with your IAM user's secret access key
  },
});

// CAUTION: User Cognito is slily difference from firebase, so be sure to read the doc carefully.

export const UserPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.userPoolId,
  ClientId: COGNITO_API.clientId,
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userAttr: null,
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const errorHandler = (err) => {
  let alertMessage = '';
  switch (err.message) {
    case 'Username should be either an email or a phone number.':
        alertMessage = `${err.message}`;
        break;
    case 'Password did not conform with policy: Password not long enough':
        alertMessage = `${err.message}`;
        break;
    case 'User is not confirmed.':
        // await Auth.resendSignUp(email);
        alertMessage = `${err.message} please check your email for a verification code after you close this alert.`;
        break;
    case 'Incorrect username or password.':
        alertMessage = `${err.message}`;
        break;
    case 'User does not exist.':
        alertMessage = `${err.message} check your credentials and try again.`;
        break;
    case 'User Integration does not exists':
        alertMessage = `${err.message}`;
        break;
    default:
        alertMessage = `${err.message}`;
        break;
  }
  return alertMessage;
}

const errorHandlerGetSession = (err) => {
  let alertMessage = '';
  switch (err.message) {
    // Failed token refresh
    case 'Invalid Refresh Token':
        alertMessage = `${err.message}`;
        break;
    default:
        alertMessage = `${err.message}`;
        break;
  }
  return alertMessage;
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'cognito',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  confirmCode: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  changeEmail: () => Promise.resolve(),
  forgotPasswordCode: () => Promise.resolve(),
  forgotPasswordReset: () => Promise.resolve(),
  forceChangePassword: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback((currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            // GET HUNG UP HERE MISSING TOKEN
            console.log('error in getAttr AuthProvider', err)
            currentUser.signOut();
            // logout();
            // navigate(PATH_AUTH.login, { replace: true });
            reject(err);
            window.location = PATH_AUTH.login
          } else {
            const results = {};
            
            attributes.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  // function for getting users role
  const getRole = (attributes) => {
    // custom:is_mfr: "1"
    // custom:is_accountowner: "1"
    // custom:is_admin: "1"
    let role = "default";
    if(Object.prototype.hasOwnProperty.call(attributes, "custom:is_admin")) {
      role = "admin"
    } else if(Object.prototype.hasOwnProperty.call(attributes, "custom:is_accountowner") && attributes["custom:is_accountowner"] === "1") {
      role = "account_owner"
    } 
    // else if(Object.prototype.hasOwnProperty.call(attributes, "custom:is_mfr")) {
    //   role = "mfr_user"
    // }

    return role;
  }

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              const alertMessage = errorHandlerGetSession(err);
              console.log('alertMessage', alertMessage)
              user.signOut();
              reject(err);
              // Use navigate to redirect to the login page
              // navigate(PATH_AUTH.login, { replace: true });
              window.location = PATH_AUTH.login
            } else {
              const attributes = await getUserAttributes(user);
              const role = getRole(attributes);
              const token = session.getIdToken().getJwtToken();
              // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
              axiosInstance.defaults.headers.common.Authorization = token;
              axiosInstanceRDS.defaults.headers.common.Authorization = token;
              axiosInstanceEcom.defaults.headers.common.Authorization = token;
              dispatch({
                type: 'AUTHENTICATE',
                // payload: { isAuthenticated: true, user: attributes },
                payload: { isAuthenticated: true, user: {...attributes, role } },
              });
              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          // This doesnt work so i made a catch in the onFailure
          // newPasswordRequired: (userAttributes, requiredAttributes) => {
          //   window.location.href = `${PATH_AUTH.resetPassword}?email=${email}`;
          //   resolve({ newPasswordRequired: true, requiredAttributes });
          // },
          onFailure: (err) => {
            // Force change password flow
            if(err.message === 'Password reset required for the user') {
              window.location.href = `${PATH_AUTH.resetPassword}?email=${email}`;
              resolve({ newPasswordRequired: true });
              return
            }
            const alertMessage = errorHandler(err);
            console.log("Error on login Cognito Context", alertMessage)
            reject(err);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            window.location.href = PATH_AUTH.resetPassword;
            sessionStorage.setItem('email-force', email)
            sessionStorage.setItem('uuid-force', user.Username)
            // resolve()
          },
        });
      }),
    [getSession]
  );

  // This is for users whos get added as team memebrs and have to change password 
  // User is created by a "COGNITO ADMIN"
  const forceChangePassword = useCallback(
    (email, oldPassword, newPassword) =>
      new Promise((resolve, reject) => {

        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: oldPassword,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            // This shouldnt get hit
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            // This should get hit
            const alertMessage = errorHandler(err);
            console.log("Error on login Cognito Context", alertMessage)
            reject(err);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            delete userAttributes.email_verified;
            delete userAttributes.email;
            user.completeNewPasswordChallenge(newPassword, userAttributes, {
              onSuccess(data) {
                console.log('Password confirmed!');
                window.location.href = PATH_DASHBOARD.general.overview
                resolve(data)
              },
              onFailure(err) {
                console.log('Password not confirmed!', err);
                reject(err)
              },
            });

          },
        });
      }),
    []
  );
  // const forceChangePassword = (newPassword) => {
  //   const email = sessionStorage.getItem('temp-email')
  //   const userAttrs = sessionStorage.getItem('temp-att')
  //   const user = new CognitoUser({
  //     Username: email,
  //     Pool: UserPool,
  //   });
  //   const params = {
  //     ChallengeName: 'NEW_PASSWORD_REQUIRED', 
  //     ClientId: COGNITO_API.clientId,
  //     ChallengeResponses: {
  //       USERNAME: user.username,
  //       NEW_PASSWORD: newPassword
  //     },
  //     Session: sessionStorage.getItem('temp-sesh')
  //   };
  //   return new Promise((resolve, reject) => {
  //     const callback = (err, result) => {
  //      if (err) {
  //       reject(err);
  //       return;
  //      }
  //      console.log("result", result)
  //      resolve(result);
  //     };
    
  //     user.completeNewPasswordChallenge(newPassword, userAttrs, callback);
  //   });
  // };


  const changePassword = async(uuid, email, oldPassword, newPassword) => {
    getSession().then(({ user }) => {
      login(email, oldPassword).then(() =>
        user.changePassword(oldPassword, newPassword, (err, result) => {
          if(err) console.error(err)
          console.log("result", result)
        })
      )
    });
  };

  const changeEmail = async (uuid, newEmail, currentPassword) => {
    const user = UserPool.getCurrentUser();
    if (!user) {
      console.error("No user is currently logged in.");
      throw new Error("No user is currently logged in.");
    }
  
    const sessionPromise = new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          console.error("Failed to get user session:", err);
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

    return sessionPromise.then(session => {
      const authDetails = new AuthenticationDetails({
        Username: user.getUsername(),
        Password: currentPassword,
      });

      return new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: () => {
            const attributeList = [];
            const emailAttribute = {
              Name: 'email',
              Value: newEmail
            };
            attributeList.push(new CognitoUserAttribute(emailAttribute));

            user.updateAttributes(attributeList, (err, result) => {
              if (err) {
                console.error("Failed to update user attributes:", err);
                reject(err);
              } else {
                console.log("Email updated successfully:", result);
                resolve(result);
              }
            });
          },
          onFailure: (err) => {
            console.error("Authentication failed:", err);
            reject(err);
          }
        });
      });
    });
  };

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: 'LOGOUT' });
    }
  };

  const register = (email, password) =>
    new Promise((resolve, reject) =>
      UserPool.signUp(
        email,
        password,
        [
          { Name: 'email', Value: email },
        ],
        null,
        async (err) => {
          if (err) {
            reject(err);
            const alertMessage = errorHandler(err);
            console.log("Error on register Cognito Context", alertMessage)
            return;
          }
          resolve();
          window.location.href = PATH_AUTH.verify;
        }
      )
    );


  // const verify = (email, code) => {
  //   const user = new CognitoUser({
  //     Username: email,
  //     Pool: UserPool,
  //   });

  //   return new Promise((resolve, reject) => {
  //     const callback = (err, result) => {
  //      if (err) {
  //       reject(err);
  //       return;
  //      }
  //      console.log("result", result)
  //      resolve(result);
  //     };
    
  //     user.confirmRegistration(code, true, callback);
  //   });
  // };

  const verify = (email, authphrase, code) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
  
    return new Promise((resolve, reject) => {
      const callback = (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        console.log("result", result);
        // Use AdminInitiateAuthCommand to get the JWT token
        const params = {
          AuthFlow: 'ADMIN_USER_PASSWORD_AUTH',
          ClientId: COGNITO_API.clientId,
          UserPoolId: COGNITO_API.userPoolId,
          AuthParameters: {
            USERNAME: email,
            PASSWORD: authphrase, // Replace with the actual password
          },
        };
  
        const command = new AdminInitiateAuthCommand(params);
  
        cognitoClient.send(command)
          .then(authResult => {
            const jwtToken = authResult.AuthenticationResult.IdToken;
            resolve({ result, jwtToken });
          })
          .catch(authErr => {
            reject(authErr);
          });
      };
  
      user.confirmRegistration(code, true, callback);
    });
  };

  const forgotPasswordCode = useCallback(
    (email) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        // const authDetails = new AuthenticationDetails({
        //   Username: email,
        //   Password: password,
        // });

        user.forgotPassword({
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            const alertMessage = errorHandler(err);
            console.log("Error on login Cognito Context", alertMessage)
            reject(err);
          },
        });
      }),
    [getSession]
  );

  const forgotPasswordReset = useCallback(
    (email, verificationCode, newPassword) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        user.confirmPassword(verificationCode, newPassword, {
          onSuccess(data) {
            console.log('Password confirmed!');
            resolve(data)
          },
          onFailure(err) {
            console.log('Password not confirmed!', err);
            reject(err)
          },
        });
      }),
    []
  );

  // const verify = async(email, code) => {
    
  //   const user = new CognitoUser({
  //     Username: email,
  //     Pool: UserPool,
  //   });
  //   let res;
  //   await user.confirmRegistration(code, true, (err, result) => {
  //     if(err){
  //       const alertMessage = errorHandler(err);
  //       console.log("Error on verify Cognito Context", alertMessage)
  //       res = "error"
  //       return res;
  //     }
  //     res = result
  //     console.log("returning now good to start thunk")
  //     return res;
  //   });
    
  // };



  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          // displayName: state?.user?.name || 'Minimals',
          // role: 'admin',
          ...state.user,
        },
        login,
        register,
        verify,
        logout,
        changePassword,
        changeEmail,
        forgotPasswordCode,
        forgotPasswordReset,
        forceChangePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );

  
}

export { AuthContext, AuthProvider };