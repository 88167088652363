// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Components
import Image from '../Image'
// Images
import CustomerCaseStudyVisual from '../../assets/images/sections/_appFeatured/CustomerCaseStudyModal_Visual.jpg';

// ----------------------------------------------------------------------

export default function CaseStudyDialog() {

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h3" align="center" color="custom.light" gutterBottom sx={{ mx: 4}}>
        Ruggish Makes Stylish Rugs More Functional With Augmented Reality
      </Typography>
      <Typography variant="body1" sx={{ px: 2 }}>
        Ruggish is a growing eCommerce brand that designs and manufactures gorgeous, functional and family-friendly playmats and rugs. 
        They chose to add AR and 3D to their website to boost customer engagement, improve product experience and reduce expensive returns. 
      </Typography>
      <Stack direction="column" sx={{ px: 2 }}>
        <Typography variant="h6">The Ruggish Store</Typography>
        <Stack direction="row" alignItems="center" sx={{ mt: -3}}>
          <Typography variant="body1" sx={{ px: 2}}>
            <i>"As an interior designer and new mom living in a small city apartment, my son's nursery was also our home office and guest room, so we had to be selective 
            with every item we chose. I assumed we would have to use a bright, busy play mat or worse…foam tiles. But we found that those products don't actually provide 
            much cushion, are easy to pull apart by little ones, and collect crumbs, dirt and liquid in the cracks (ewww)."</i>
          </Typography>
          <Image 
            alt='ruggishCaseStudyGraphic' 
            src={CustomerCaseStudyVisual} 
            sx={{ height: 200, width: '100%', p: 2 }}
          />
        </Stack>
        <Typography variant="body1" sx={{ px: 2}}>
        <i>"I ended the search and decided to create my own product, launching a Kickstarter campaign for Ruggish Play Rugs in 2017. We were fully funded and I officially started 
        my career as an entrepreneur. Since then, we have expanded our product line to include Runners, a multifunctional nursing pillow, and accessories. We can now be found 
        in many retailers throughout the US."</i>
        </Typography>
        <Typography variant="body2" sx={{ px: 4, pt: 1}}><b> -Liza Savary, Ruggish Founder</b></Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">How  AR works for Ruggish products and customers</Typography>
        <Typography variant="body1" sx={{ px: 2}}>
          Ruggish products are fairly large (up to 5'x7') and shipping can be expensive so having the ability to “View In Room” enables customers to see exactly how the products look 
          and fit in their own homes before purchasing. (All levAR's AR products are exactly to-scale, so there's no second guessing sizes or proportions.) Providing this deeper level 
          of detail, both functionally and aesthetically is a huge advantage for large scale products like rugs, furniture, fitness equipment, and appliances. 
        </Typography>
        <Typography variant="body1" sx={{ px: 2}}>And Ruggish customers are loving this option, too!</Typography>
        <Typography variant="body2" sx={{ px: 2}}>
          <i>"We recently had a customer reach out to let us know that thanks to the AR feature, they were able to decide on a pattern and confidently make a purchase!"</i>
        </Typography>
      </Stack>
      <Typography variant="title1" sx={{ px: 2, pb: 2 }}>
        <b>
          Read more case studies on the <Link target="_blank" rel="noopener" underline="always" color="primary" href="https://levar.io/blog">LEVAR Blog</Link>.
        </b>
      </Typography>
    </Stack>
  )
}