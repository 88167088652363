import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
// redux
import { useSelector } from '../../../redux/store';
// sections
import ProjectLoadoutView from './ProjectLoadoutView';
// Utils
import { s3Connector } from '../../../utils/s3Connector';

// ----------------------------------------------------------------------

const allEqual = arr => arr.every(val => val.value === true);

// ----------------------------------------------------------------------

ProjectLoadoutController.propTypes = {
  loadout: PropTypes.object,
  handleLoadout: PropTypes.func,
  
};

export default function ProjectLoadoutController({ 
  loadout, 
  handleLoadout,
}) {

  const { store } = useSelector(state => state.account);

  const [state, setState] = useState({
    ready: false,
    validatingLoadout: false,
  });
  
  const currentLoadout = loadout

  // --------------------------
  // Normal Loadout Form
  // --------------------------
  const defaultValues = {
    clientLoadoutStage: 0,
    loadout_id: currentLoadout.loadout_id || '',  
    loadout_type: currentLoadout.loadout_type || 1,
    minValueProductPhotos: 1,
    minValueProductDetails: 50,  
    additional_product_photos: [],
    product_details: '',
    productDetailsCheck: false, // checkbox 1
    dimensionsCheck: false, // checkbox 2
    additionalProductPhotosCheck: false, // checkbox 3
    loadoutTodos: [],
  };

  const todoObjSchema = {
    name: Yup.string(),
    label: Yup.string(),
    value: Yup.bool()
  };

  // /* eslint-disable camelcase */
  const LoadoutSchema = Yup.object().shape({
    clientLoadoutStage: Yup.number(),
    loadout_id: Yup.string(),
    loadout_type: Yup.number(),
    minValueProductPhotos: Yup.number(),
    minValueProductDetails: Yup.number(),
    additional_product_photos: Yup.array().when('step', {
      is: 3,
      then: Yup.array().min(defaultValues.minValueProductPhotos, "At least 1 product photo is required").of(Yup.mixed()),
      otherwise: Yup.array(),
    }),
    product_details: Yup.string().when('step', { 
      is: 3,
      then: (schema) => schema.min(defaultValues.minValueProductDetails, "Please include a detailed specifics of your product and variants"), // NOTE: length of string here is deceiving since the string contains markdown
      otherwise: (schema) => schema,
    }),
    productDetailsCheck: Yup.bool().when('clientLoadoutStage', { // checkbox 1
      is: (val) => (val === 3 || val === 4),
      then: (schema) => schema.required('Details must be checked').oneOf([true], 'Details must be checked'),
      otherwise: (schema) => schema.notRequired(),
    }),
    dimensionsCheck: Yup.bool().when('clientLoadoutStage', { // checkbox 2
      is: (val) => (val === 3 || val === 4),
      then: (schema) => schema.required('You must check your dimmensions').oneOf([true], 'You must be check your dimmensions'),
      otherwise: (schema) => schema.notRequired(),
    }),
    additionalProductPhotosCheck: Yup.bool().when('clientLoadoutStage', { // checkbox 3
      is: (val) => (val === 3 || val === 4),
      then: (schema) => schema.required('Photos must be checked').oneOf([true], 'Photos must be checked'),
      otherwise: (schema) => schema.notRequired(),
    }),
    loadoutTodos: Yup.array().of(Yup.object().shape(todoObjSchema)).when('clientLoadoutStage', {
      is: 6,
      then: (schema) => schema.test('is-todos-complete', "Please Complete the Checklist", (value) => allEqual(value) !== false),
      otherwise: (schema) => schema.notRequired(),
    })
  });

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LoadoutSchema),
    defaultValues,
  });
  
  const {
    control,
    clearErrors,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = methods;
  
  const values = watch();

  // Possible field array methods
  // { fields, append, prepend, remove, swap, move, insert }
  const { fields: fieldsTodo, append: appendTodo, remove: removeTodo } = useFieldArray(
    {
      control,
      name: "loadoutTodos"
    }
  );
  // ----------------------
  // Setting Loadout Wizard Stage Modal
  // ----------------------
  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted) {
      const getResource = async () => {
        if(currentLoadout?.loadout_wizard_stage) {
          setValue('clientLoadoutStage', currentLoadout.client_loadout_stage);
          
          if(currentLoadout.client_loadout_stage >= 5){
            const todosArr = currentLoadout.loadout_todos.map((item, index) => ({ name: `todo${index}`, label: item, value: false }));
            setValue('loadoutTodos', todosArr); // This sets form state which intern sets useFieldArray state
          }

          setState((prev) => ({ ...prev, ready: true }));
        }
      }
      getResource();
    }

    return function cleanup() {
      isComponentMounted = false;
    }
  }, [
    currentLoadout.loadout_wizard_stage, 
    currentLoadout.client_loadout_stage, 
    currentLoadout.loadout_todos, 
    setValue
  ]);


  // ----------------------------------
  // Step 3: Save || Submit || Accept => Loadout
  // ----------------------------------
  const handleValidateLoadout = async (buttonType) => {
    setState((prev) => ({ ...prev, validatingLoadout: buttonType }));
    let valid;

    let clientLoadoutStage;
    switch(buttonType) {
      case 'save':
        valid = await trigger(['product_details']);
        clientLoadoutStage = 4
        break;
      case 'submit':
        valid = await trigger(['additional_product_photos', 'product_details']);
        clientLoadoutStage = 5
        break;
      case 'submitBack':
        valid = await trigger(['additional_product_photos', 'product_details']);
        clientLoadoutStage = 6
        break;
      case 'accepted':
        valid = await trigger(['additional_product_photos', 'product_details']);
        clientLoadoutStage = 7
        break;                
      default:
        break;
    }

    if(errors.length) clearErrors(Object.keys(errors));

    if(valid) {
      const additionalProductPhotos = await s3Connector(values.additional_product_photos, 'feedback', `${store.store_id}/additional_product_photos`);
      await handleLoadout(additionalProductPhotos, values.product_details, clientLoadoutStage, values.loadoutTodos, currentLoadout.loadout_id);
    }

    setState((prev) => ({ ...prev, validatingLoadout: '' }));
  };
  


  return (
    <Box sx={{ width: '100%' }}>
      <ProjectLoadoutView
        handleValidateLoadout={handleValidateLoadout}
        methods={methods}
        fieldsTodo={fieldsTodo}
        appendTodo={appendTodo}
        removeTodo={removeTodo}
        isSubmitting={state.validatingLoadout}
      />
    </Box>
  );
}

