import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  ecommerceConnectOff: false,
};

const slice = createSlice({
  name: 'memorizedSettings',
  initialState,
  reducers: {

    // SET MEMORIZED SETTING
    setMemorizedSetting(state, action) {
      state[action.payload.name] = action.payload.value;
    },

    // GET STATE
    getMemorizedSettings(state, payload) {
      return state
    },

    // RESET A SINGLE MEMORIZED SETTING
    resetMemorizedSetting(state, action) {
      delete state[action.payload.name]
    },

    // RESET MEMORIZED SETTING
    resetAllMemorizedSettings: () => initialState,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMemorizedSetting,
  getMemorizedSettings,
  resetMemorizedSetting,
  resetAllMemorizedSettings
} = slice.actions;
