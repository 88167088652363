import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { arraysEqual, delay } from '../../utils/levarHelpers';

// components
import { 
  FormProvider, 
  RHFSelect, 
  RHFTextField, 
} from '../hook-form';
import Iconify from '../Iconify';
import { useDispatch } from '../../redux/store';
import { setUpdateModel } from '../../redux/thunks/modelThunks';
// ----------------------------------------------------------------------

ModelImplementationsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
};

// const orbitControlValues = [
//   null,                       // None
//   [0, 3.14, -1, -1],          // Unlimited  
//   [0.62, 1.74, -1, -1],       // Floor & Ceiling *
//   [0.4, 1.5, -1, -1],         // Floor
// ];

const options = {
  asset_environment: [
    { label: '1 - Photo Studio Default', value: '1' },
    { label: '2 - Auto Shop', value: '2' },
    { label: '3 - Drama Gold', value: '3' },
    { label: '4 - Drama White', value: '4' },
    { label: '5 - Paint', value: '5' },
    { label: '6 - Photo Studio 180', value: '6' },
    { label: '7 - Snow', value: '7' },
    { label: '8 - Two Ring', value: '8' },
    { label: '9 - Alive Time', value: '9' },
  ],
  model_status: [
    { label: 'On', value: 'on' },
    { label: 'Off', value: 'off' },
  ],
  orbit_controls_angles: [
    { label: 'None', value: 'null' },
    { label: 'Unlimited', value: '0,3.14,-1,-1' },
    { label: 'Floor & Ceiling', value: '0.62,1.74,-1,-1' },
    { label: 'Floor', value: '0.4,1.5,-1,-1' },
  ],
  viewer_variant_switch: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));


export default function ModelImplementationsDialog({ onClose, dialogData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  
  const UpdateModelSchema = Yup.object().shape({
    asset_background: Yup.string(),
    asset_environment: Yup.string(),
    custom_spawn: Yup.string().nullable(),
    model_status: Yup.string(),
    orbit_controls_angles: Yup.string(),
    viewer_variant_switch: Yup.string(),
  });

  const defaultValues = {
    asset_background: '',
    asset_environment: '',
    custom_spawn: '',
    model_status: '',
    orbit_controls_angles: '',
    viewer_variant_switch: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateModelSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && dialogData.model) {
      const getResource = async () => {
        setValue("asset_background", dialogData.model.asset_background)
        setValue("asset_environment", dialogData.model.asset_environment)
        setValue("custom_spawn", dialogData.model.custom_spawn ? dialogData.model.custom_spawn.toString() : "null")
        setValue("model_status", dialogData.model.model_status)
        setValue("orbit_controls_angles", dialogData.model.orbit_controls_angles ? dialogData.model.orbit_controls_angles.toString() : "null")
        setValue("viewer_variant_switch", dialogData.model.viewer_variant_switch)
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [dialogData, dispatch, setValue]);

  const onSubmit = async () => {
    try {
      const payload = Object.entries(values).reduce((acc, [key, value]) => { // Filter out empty stuff
          let attemptedSplit;
          switch(key) {
            case "orbit_controls_angles":
            case "custom_spawn":
              if(value === "MISSING" || value === undefined || dialogData.model[key] === value) return acc; // No nead to write missing again
              attemptedSplit = value?.split(",")?.map(Number) // Attempt to split the value then check it in the next if statement
              if(!Array.isArray(attemptedSplit)) return acc;
              if(arraysEqual(attemptedSplit, dialogData.model[key])) return acc; // If its the same value that we started with get rid of the field
              acc[key] = attemptedSplit; // Otherwise, parse & write the new value
              break;
            default:
              if(value === "MISSING" || value === undefined || dialogData.model[key] === value) return acc; // No nead to write missing again or if the val hasnt changed
              if (value === "true" || value === "false") acc[key] = JSON.parse(value); // If its a boolean, write the new value
              if (value === "null") acc[key] = null; // If its null, write the new value
              if(value !== "" || value !== undefined) acc[key] = value; // Default to value setter
              break;
          }
          return acc;
      }, {});

      if(Object.keys(payload)?.length === 0 || Object.keys(payload)?.length === null) {
        enqueueSnackbar("Nothing Changed", { variant: 'warning' });
      } else {
        await dispatch(setUpdateModel({ model3dId: dialogData.model.model_3d_id, fields: payload }));
        enqueueSnackbar("Update Model Success!", { variant: 'success' });
      }
    } catch (error) {
      console.error("onSubmit model update error", error);
    } finally {
      await delay(500)
      reset() // reset form
      onClose();
    }
  };

  return (
    <Box >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
          <Typography variant="h4" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
            Model Implementation Settings
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 20, top: 1 }}>
            <Iconify icon={'ic:round-close'} width="20" height="20" />
          </IconButton>

        <Divider />

        <Box sx={{ mt: 1, px: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="asset_background" size="small" label="Asset Background" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="asset_environment" size="small" label="Asset Enviorment" >
                  {options.asset_environment.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="custom_spawn" size="small" label="Custom Spawn" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="model_status" size="small" label="Model Status" >
                  {options.model_status.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="orbit_controls_angles" size="small" label="Orbit Controls Angles" >
                  {options.orbit_controls_angles.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="viewer_variant_switch" size="small" label="Viewer Variant Switch" >
                  {options.viewer_variant_switch.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 1, px: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button
              variant="outlined" 
              size="medium" 
              onClick={() => reset()}
            >
              Reset
            </Button>
            <LoadingButton 
              variant="contained" 
              size="medium" 
              type="submit"
              loading={isSubmitting}
            >
              Write
            </LoadingButton>
          </Stack>
        </Box>

      </Stack>
      </FormProvider>
    </Box>
  )
};