import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { DialogAnimate } from '../../animate';
// sections
import Start from './Start';
import ConfirmForm from './ConfirmForm';

// ----------------------------------------------------------------------

ProjectWizardDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onHandleSubmitForm: PropTypes.func,
};

export default function ProjectWizardDialog({ 
  onClose, 
  open, 
  onHandleSubmitForm, 
  ...other 
}) {
  const [step, setStep] = useState(2);

  const onContinue = () => {
    setStep(step + 1)
  };

  const onBack = () => {
    setStep(step - 1)
  };

  const resetOnClose = () => {
    onClose();
    // setStep(1);
  };

  return (
    <DialogAnimate 
      fullWidth 
      maxWidth={step === 1 ? 'md' : 'sm'}
      open={open}
      onClose={resetOnClose} 
      {...other}
    >
      { step === 1 && <Start onContinue={onContinue} /> }

      { step === 2 && 
        <ConfirmForm
          onBack={onBack} 
          onHandleSubmitForm={onHandleSubmitForm} 
        /> 
      }
    </DialogAnimate>
  );
}
