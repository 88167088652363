import React from 'react';
// icon
import CircularProgress from '@mui/material/CircularProgress';
// styles
import { styled } from '@mui/material/styles';
// redux
import { useSelector } from '../redux/store';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  alignSelf: 'center',
  position: 'fixed',
  background: '#f2f0edd1',
  zIndex: 1400,
  top: 0,
  left: 0,
  '& > * + *': {
      marginLeft: theme.spacing(2),
  },
  '& h5': {
      fontSize: 18,
      marginBottom: 15,
  }
}));

export const SectionLoading = () => {
  const { mainLoadingScreen, secondaryMainLoading } = useSelector((state) => state.loading);

  if(!mainLoadingScreen.status && !secondaryMainLoading.status) return null

  return (
    <RootStyle>
      { mainLoadingScreen.text && <h5>{mainLoadingScreen.text}</h5>}
      { secondaryMainLoading.text && <h5>{secondaryMainLoading.text}</h5>}
      <CircularProgress />
    </RootStyle>
  );
}