import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iconify from '../../Iconify';
import projectExample from '../../../assets/images/sections/projects/project_example.jpg';

Start.propTypes = {
  onContinue: PropTypes.func,
};

export default function Start({ onContinue }) {

  return (
    <Box sx={{ p: 3, mx: 'auto' }}>

      <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} align="center" gutterBottom>
        Create a New Project
      </Typography>

      <Typography variant="body1" align="center" sx={{ mx: 'auto', maxWidth: 600 }}>
        LEVAR projects can contain one or many 3D models or experiences. To request a new 3D model or experience
        please provide some basic information.
      </Typography>

      <Box>
        <img src={projectExample} alt="LEVAR Project Example" style={{ maxWidth: 650 }} />
      </Box>

      <div style={{flex: '1 0 0'}} />

      <Stack 
        direction="row"
        justifyContent="center"
        sx={{ mt: 0 }}
      >
        <Button
          variant="contained"
          endIcon={<Iconify icon={'material-symbols:arrow-forward-rounded'} width={20} height={20} />}
          onClick={() => onContinue()}
        >
          Get Started
        </Button>
      </Stack>

    </Box>
  );
}