
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import { useResponsive } from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
// logos
import { LevarIconStack } from '../../assets/logos/levarIconStack';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: '#F2F0ED'
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  // alignItems: 'baseline',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const smUp = useResponsive('up', 'sm');

  return (
    <Page title="Login">
      <RootStyle>
        {smUp && (
        <HeaderStyle>
          <div> </div>
          <Typography variant="body2" sx={{ mt: { md: -2 } }}>
            Don’t have an account? {' '}
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              Register
            </Link>
          </Typography>
        </HeaderStyle>
        )}

        <Container>
          <ContentStyle>

            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
              <LevarIconStack color="primary" sx={{ fontSize: 200 }} />
            </Grid>

            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>

              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                </Typography>
              </Box>

            </Stack>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
