import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useAuth from '../../../hooks/useAuth';
// redux
// import { useSelector } from '../../../redux/store';
// assets
import levarLogo from '../../../assets/logos/levAR_logo_black.svg'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  // const { user } = useSelector((state) => state.account);
  const { user } = useAuth();
  
  const handleName = () => {
    let adminUser = user.email.split('@');
    adminUser = adminUser[0]?.split('+')[0]
    adminUser = adminUser.replace('.', ' ')
    return adminUser
  }


  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={levarLogo} alt="LEVAR User" />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
            maxWidth: 140
          }}
        >
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize', maxWidth: 125 }}> {handleName()} </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary', maxWidth: 120 }}>{user.email}</Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
