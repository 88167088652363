import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  adminStatus: null,
  accountOwnerStatus: null,
  impersonatedUUID: null,
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminStatus(state, action) {
      state.adminStatus = action.payload
    },

    setAccountOwnerStatus(state, action) {
      state.accountOwnerStatus = action.payload
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    setImpersonationUUID(state, action) {
      state.impersonatedUUID = action.payload;
    },
    resetAdmin: () => initialState,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setAdminStatus,
  setAccountOwnerStatus,
  hasError,
  setImpersonationUUID,
  resetAdmin
} = slice.actions;
