import { 
  fetchProjectLoadouts,
  fetchDeleteProject,
  fetchCreateProject,
  fetchUpdateProject,
  fetchCreateLoadout,
  fetchUpdateLoadout,
  fetchDeleteLoadout,
  fetchAllProjects,
  fetchProductWithVariants,
  fetchInvoiceHistoryData,
  fetchShopByStoreId,
  fetchUserByUUID,
  fetchSingleProduct,
  fetchSendSlackProjectPaid,
} from '../../utils/fetchData';
// helpers
import { getJWTstorage } from '../../utils/jwt';
import { resetCart } from '../slices/cart';
import { delay, buildTemplateSimple } from "../../utils/levarHelpers";
import { getSecondaryMainLoading } from '../slices/loading';



// ----------------------------------------------------------------------


// **************************************************************************
// WHERE: used in admin/Proects
// DESCRIPTION: Grab all projects and associated laodouts for every exisiting store
// INPUT: N/A
// **************************************************************************
// export function setAllProjectsData() {
//   return async (dispatch, getState) => {
//     try {
//       const { account: { user }, admin } = getState();
//       const jwt = getJWTstorage(admin, user.uuid);
//       const projects = await fetchEveryProject(jwt);

//       // await Promise.all(
//       //   projects.search_response.map(async (item) => {
//       //     const res = await fetchProjectLoadouts(item.project_id, jwt)
//       //     item.loadouts = res.loadout_response
//       //     item.artist_action = res.loadout_response.some(load => load.client_loadout_stage === 5) ? "needs review" : "none"
//       //     item.loadout_count = res.loadout_response.length
//       //     projectLoadouts.push(item)
//       //   })
//       // );

//       const projectLoadouts = projects.search_response.map((item) => {
//           item.artist_action = item.project_stage === 1 ? "needs review" : "none"
//           item.loadout_count = 0
//           return item

//       })
      
//       return projectLoadouts;
//     } catch(error) {
//       console.log("setAllProjectsData error", error)
//       return []; // return an empty array or any default value
//     }
//   }
// } 


// **************************************************************************
// WHERE: used in 3dModel/creation/Proects
// DESCRIPTION: Create a new project
// INPUT: projectInfo(obj) - project_title, project_description, contact_name, contact_email
// **************************************************************************
export function setCreateProject({ projectInfo }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      projectInfo.store_title = store.store_title;
      projectInfo.store_id = store.store_id;
      projectInfo.levar_user_account_id = store.levar_user_account_id;

      const project = await fetchCreateProject(projectInfo, jwt);
      return project
    } catch(error) {
      console.log("setCreateProject error", error);
      return []; // return an empty array or any default value
    }
  }
};


// **************************************************************************
// WHERE: used in 3dModel/creation/Proects
// DESCRIPTION: Create a new project
// INPUT: projectInfo(obj) - project_title, project_description, contact_name, contact_email
// **************************************************************************
export function setUpdateProject({ projectInfo }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      projectInfo.store_id = store.store_id;
      const project = await fetchUpdateProject(projectInfo, jwt);

      return project
    } catch(error) {
      console.log("setUpdateProject error", error)
      return []; // return an empty array or any default value
    }
  }
};


// **************************************************************************
// WHERE: used in create/models
// DESCRIPTION: Verifies that the one time payment was actually paid after reidrect from shopify
// INPUT: projectID - a uuid for the project generated by sina && chargeId - 2331213869 - provided by shopify
// **************************************************************************
export function setVerifyUpdateProjectPaid({ projectId, chargeId }) {
  return async (dispatch, getState) => {
    dispatch(getSecondaryMainLoading({ status: true, text: "Confirming Payment..." }));
    try {

      const { account: { user, store, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // NOTE: Delay by 4 seconds so ES has time to get data
      await delay(10000);
      const chargeHistory = await fetchInvoiceHistoryData(store, jwt, integration.integration_id, false);
      const chargeToCheck = chargeHistory.one_time_charges.find(charge => charge.charge_id === Number(chargeId));

      if(chargeToCheck.status !== 'ACTIVE') return false; // If the charge is not paid do nothing

      dispatch(resetCart()); // Reset redux cart
      const slackMessage = `ONE TIME CHARGE: ${chargeToCheck.charge_name} \n Status: ${chargeToCheck.status} \n Store ID: ${store.store_id} \n Price: ${chargeToCheck.price}`
      await fetchSendSlackProjectPaid(slackMessage);

      const projectInfo = { project_id: projectId, project_stage: 1, invoice_id: chargeToCheck.charge_id }; // Set project to in-progress
      const updatedProjectRes = await fetchUpdateProject(projectInfo, jwt); 
      return updatedProjectRes.update_response; // Return updated project for state update
    } catch(error) {
      console.log("setVerifyUpdateProjectPaid error", error)
      return []; // return an empty array or any default value
    } finally {
      dispatch(getSecondaryMainLoading({ status: false, text: "" }));
    }
  }
};


// **************************************************************************
// WHERE: used in 3dModel/creation/Proects
// DESCRIPTION: Delete a project
// INPUT: projectID - a uuid for the project generated by sina
// **************************************************************************
export function setDeleteProject({ projectID }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      await fetchDeleteProject(projectID, jwt);

    } catch(error) {
      console.log("setDeleteProject error", error)
      
    }
  }
};

// Self Service CREATE
export function setSelfServiceCreateProject() {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // NOTE: Check if they already have a self service project / loadout
      const allProjectsByStore = await fetchAllProjects(store.store_id, jwt);

      // Grab exisiting self service project and loadout 
      if(allProjectsByStore?.project_search_response && allProjectsByStore.loadout_search_response) {
        const existingSSProject = allProjectsByStore.project_search_response.find(item => item?.project_type === 'create');
        
        let existingSSLoadoout;
        if(existingSSProject) {
          existingSSLoadoout = allProjectsByStore.loadout_search_response.find(item => item?.project_id === existingSSProject.project_id);
        }

        if(existingSSProject && existingSSLoadoout) {
          return existingSSLoadoout
        }
      }

      const projectInfo = {
        store_title: store.store_title,
        store_id: store.store_id,
        levar_user_account_id: store.levar_user_account_id,
        project_title: `${store.store_title}'s Self Service Create Project`,
        project_description: 'Self Service Create Project',
        contact_name: `${user.first_name} ${user.last_name}`,
        contact_email: `${user.email}`,
        project_type: 'create',
      };

      // Create self service project
      const selfServiceProject = await fetchCreateProject(projectInfo, jwt);

      return selfServiceProject;

    } catch(error) {
      console.log("setSelfServiceCreateProject error", error)
    }
  }
};


// **************************************************************************
// WHERE: used in self service upload flow 
// DESCRIPTION: Creates a project and creates a loadout on that project
// INPUT: projectInfo(obj) - project_title, project_description, contact_name, contact_email
// **************************************************************************
export function setInitSelfServiceProjectLoadoutUpload() {
  return async (dispatch, getState) => {
    try {
      dispatch(getSecondaryMainLoading({ status: true, text: "Getting Project Ready..." }));
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // NOTE: Check if they already have a self service project / loadout
      const allProjectsByStore = await fetchAllProjects(store.store_id, jwt);

      // Grab exisiting self service project and loadout 
      if(allProjectsByStore?.project_search_response && allProjectsByStore.loadout_search_response) {
        const existingSSProject = allProjectsByStore.project_search_response.find(item => item?.project_type === 'upload');
        console.log('existingSSProject', existingSSProject)
        let existingSSLoadoout;
        if(existingSSProject) {
          existingSSLoadoout = allProjectsByStore.loadout_search_response.find(item => item?.project_id === existingSSProject.project_id);
        }
        
        if(existingSSProject && existingSSLoadoout) {
          return existingSSLoadoout
        }
      }

      const projectInfo = {
        store_title: store.store_title,
        store_id: store.store_id,
        levar_user_account_id: store.levar_user_account_id,
        project_title: `${store.store_title}'s Self Service Project`,
        project_description: 'Self Service Porject',
        contact_name: `${user.first_name} ${user.last_name}`,
        contact_email: `${user.email}`,
        project_type: 'upload',
        self_service: true
      };

      // Create self service project
      const selfServiceProject = await fetchCreateProject(projectInfo, jwt);

      const loadoutPayload = {
        project_id: selfServiceProject._id,
        store_id: store.store_id,
        store_title: store.store_title,
        loadout_wizard_stage: 2, // Add Products
        client_loadout_stage: 2, // Add producs
        loadout_type: 1, // Ecom
        loadout_title: `${store.store_title} Self Service Loadout`,
        product_details: '',
        additional_product_photos: '',
        linked_products: [],
        artist_name: 'self_service',
        self_service: true
      };

      // Create 1 loadout and use it for everything
      const selfServiceLoadout = await fetchCreateLoadout(loadoutPayload, jwt);
      

      return selfServiceLoadout;

    } catch(error) {
      console.log("setInitSelfServiceProjectLoadoutUpload error", error)
    } finally {
      dispatch(getSecondaryMainLoading({ status: false, text: "" }));
    }
  }
};


// **************************************************************************
// WHERE: used in 3dModel/creation/Loaoduts
// DESCRIPTION: Creates a new loadout for a given project ID
// BODY: project_id, store_id, product_details, additional_product_photos, 
//        loadout_type, loadout_title, linked_products
// **************************************************************************
export function setCreateLoadout({ 
  projectID,
  loadoutWizardStage, 
  clientLoadoutStage, 
  loadoutTitle, 
  loadoutType 
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // NOTE: Temporary fix
      let findStoreName;
      if(store.name){
        findStoreName = store.name;
      } else if(store.store_name){
        findStoreName = store.store_name;
      } else if(store.store_title) {
        findStoreName = store.store_title;
      } else {
        findStoreName = store.store_domain;
      }

      const loadoutPayload = {
        project_id: projectID,
        store_id: store.store_id,
        store_title: findStoreName,
        loadout_wizard_stage: loadoutWizardStage,
        client_loadout_stage: clientLoadoutStage,
        loadout_type: loadoutType,
        loadout_title: loadoutTitle,
        product_details: '',
        additional_product_photos: '',
        linked_products: [],
      };

      const loadout = await fetchCreateLoadout(loadoutPayload, jwt);

      return loadout;
    } catch(error) {
      console.log("setCreateLoadout error", error)
    }
  }
};


export function setCreateLoadoutsForProducts({ 
  projectID,
  cartProducts,
  loadoutWizardStage, 
  clientLoadoutStage, 
  loadoutType 
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);


      const loadouts = []
      await Promise.all(
        cartProducts.map(async (cartItem) => {
          const ecommerceVariants = await fetchProductWithVariants(cartItem.product_id, store.store_id, jwt, admin.adminStatus);

          const linkedProducts = [
            { 
              product_image: ecommerceVariants.search_response.image_url,
              product_id: cartItem.product_id,
              variants: ecommerceVariants.search_response.variants.map(entry => entry.variant_id)
            }
          ];
          const generatedDescription = await buildTemplateSimple(linkedProducts, admin, user);

          const loadoutPayload = {
            project_id: projectID,
            store_id: store.store_id,
            store_title: store?.store_name  ? store.store_name : store.store_title,
            loadout_wizard_stage: loadoutWizardStage,
            client_loadout_stage: clientLoadoutStage,
            loadout_type: loadoutType,
            loadout_title: cartItem.product_title,
            product_details: generatedDescription,
            additional_product_photos: ecommerceVariants.search_response.variants.map(entry => entry?.image_url).filter(Boolean),
            linked_products: linkedProducts
          };
    
          const loadout = await fetchCreateLoadout(loadoutPayload, jwt);
          loadouts.push(loadout)
        })
      )
      return loadouts;
    } catch(error) {
      console.log("setCreateLoadoutsForProducts error", error)
    }
  }
};


// **************************************************************************
// WHERE: used in 3dModel/creation/Loaoduts
// DESCRIPTION: Updates a loadout for a given loadout ID
// BODY: project_id, store_id, product_details, additional_product_photos, 
//        loadout_type, loadout_title, linked_products
// **************************************************************************
export function setUpdateLoadout({ loadoutID, fieldsToUpdate }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const loadoutResponses = [];
      if(Array.isArray(loadoutID)) {
        await Promise.all(
          loadoutID.map(async(item) => {
            const loadoutPayload = {
              loadout_id: item,
              ...fieldsToUpdate
            };
            if(loadoutPayload?.linked_products && typeof(loadoutPayload?.linked_products[0].product_id) === 'number') {        
              const stringed = loadoutPayload.linked_products.map(item => {
                item.product_id = `${item.product_id}`
                item.variants = item.variants.map(variant => `${variant}`)
                return item
              });
              loadoutPayload.linked_products = stringed
            }
            const loadoutRes = await fetchUpdateLoadout(loadoutPayload, jwt);
            loadoutResponses.push(loadoutRes)
          })
        );
        return loadoutResponses;
      }

      const loadoutPayload = { loadout_id: loadoutID, ...fieldsToUpdate };

      if(loadoutPayload?.linked_products && typeof(loadoutPayload?.linked_products[0].product_id) === 'number') {        
        const stringed = loadoutPayload.linked_products.map(item => {
          item.product_id = `${item.product_id}`
          item.variants = item.variants.map(variant => `${variant}`)
          return item
        });
        loadoutPayload.linked_products = stringed
      }
      const loadout = await fetchUpdateLoadout(loadoutPayload, jwt);
      return loadout;
    } catch(error) {
      console.log("setUpdateLoadout error", error)
    }
  }
};


// **************************************************************************
// WHERE: used in KlaviyoEmailList.js in the user account settings general
// DESCRIPTION: Add or remove an email from a stores email list
// INPUT: emails(array) - also includes previous emails
// **************************************************************************
export function setDeleteLoadout({ loadoutID }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      
      await fetchDeleteLoadout(loadoutID, jwt);

    } catch(error) {
      console.log("setDeleteLoadout error", error)
    }
  }
};


export function setDeleteAllProjectLoadouts({ projectID }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      const res = await fetchProjectLoadouts(projectID, jwt);
      
      if(res.loadout_response.length > 0) {
        await Promise.all(
          res.loadout_response.map( async (loadout) => { 
            await fetchDeleteLoadout(loadout.loadout_id, jwt);
          })
        )
      }

    } catch(error) {
      console.log("setDeleteAllProjectLoadouts error", error)
    }
  }
};



// **************************************************************************
// WHERE: forbiden tools admin
// DESCRIPTION: Create a new project, and create a new loadout with all data entered
// INPUT: storeID, variants[list of ecom ids],
// **************************************************************************
export function setCreateAdminBatchRequestProjectLoadout({ storeID, variants }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const { search_response: storeInfo } = await fetchShopByStoreId(storeID, jwt, admin.adminStatus);
      const { foundUser: userInfo} = await fetchUserByUUID(storeInfo.levar_user_account_id);

      const projectInfo = {
        store_title: storeInfo.store_title,
        store_id: storeInfo.store_id,
        levar_user_account_id: storeInfo.levar_user_account_id,
        project_title: `${storeInfo.store_title}'s Batch Request`,
        project_description: 'Batch Reqest Variants',
        contact_name: `${userInfo.first_name} ${userInfo.last_name}`,
        contact_email: `${userInfo.email}`,
        project_type: 'create',
      };

      // Create project
      const newProject = await fetchCreateProject(projectInfo, jwt);

      await delay(2000);
      
      // Get all unique products
      const productIdSet = new Set();
      variants.map(variant => productIdSet.add(`${storeID}_${variant.split('_')[2]}`))
      const uniqueProductIDs = [...productIdSet];

      // Setup linkedProductsObject list
      await Promise.all(
        uniqueProductIDs.map(async(productId) => {
          // Get product info mostly for additionalImages 
          const response = await fetchSingleProduct(productId, jwt, admin.adminStatus);

          // Filter from data passed in dont just grab variants from the product response
          const variantsForProduct = variants.filter(ecomIds => ecomIds.startsWith(productId));

          // Setup linked product structure
          const linkedProduct = [{ product_image: response?.search_response?.image_url, product_id: productId, variants: variantsForProduct }];
          // Generate description
          const generatedDescription = await buildTemplateSimple(linkedProduct, admin, user);

          // Pull out additional product photos
          const additionalPhotos = response?.search_response?.images.map(image =>  image.src);

          const loadoutPayload = {
            project_id: newProject._id,
            store_id: storeID,
            store_title: storeInfo.store_title,
            loadout_wizard_stage: 3, // Add Products
            client_loadout_stage: 5, // Add producs
            loadout_type: 1, // Ecom
            loadout_title: `${storeInfo.store_title} Batch Request Loadout`,
            product_details: generatedDescription,
            additional_product_photos: additionalPhotos,
            linked_products: linkedProduct,
          };
          await fetchCreateLoadout(loadoutPayload, jwt);
          return true;
        })
      );

      // Update project stage to 1
      const projectUpdateInfo = { project_id: newProject._id, project_stage: 1 }
      await fetchUpdateProject(projectUpdateInfo, jwt);


    } catch(error) {
      console.log("setCreateAdminBatchRequestProjectLoadout error", error)
    }
  }
};
