// @mui
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// assets
import { DocIllustration } from '../../../assets';
// redux
import { useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useSelector((state) => state.account);

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hi, {user.first_name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our docs
        </Typography>
      </div>

      <Button variant="contained" onClick={() => window.open('https://levar.zendesk.com/hc/en-us')}>Documentation</Button>
    </Stack>
  );
}
