import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import AdminLayout from '../layouts/admin';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AccountLayout from '../layouts/accounts'
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { 
  PATH_AFTER_LOGIN, 
  PATH_AFTER_LOGIN_ACCOUNT_OWNER, 
  PATH_AFTER_LOGIN_ADMIN,
  PATH_BEFORE_LOGIN
} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([

    /*
    * Auth Routes
    */
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <Verify />
            </GuestGuard>
          ),
        },
        {
          path: 'wizard',
          element: (
            <GuestGuard>
              <Wizard />
            </GuestGuard>
          ),
        },
        { element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true },
      ],
    },

    /*
    * Dashboard Routes
    */
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [

        // GENERAL
        // ----------------------------------------------------------------------
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'overview', element: <GeneralApp /> },
        
        // OLD CREATION PROJECT NOW REDRIECTS
        { path: 'creation',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.creation.projects} replace />, index: true },
            { path: 'projects',
              children: [
                { element: <GeneralApp />, index: true },
                { path: ':name', element: <GeneralApp /> }
              ],
            },
            { path: 'progress', element: <GeneralApp /> },
            { path: 'review', element: <GeneralApp /> },
          ]
        },

        // // CREATION
        // { path: 'creation',
        //   children: [
        //     { element: <Navigate to={PATH_DASHBOARD.creation.projects} replace />, index: true },
        //     { path: 'projects',
        //       children: [ 
        //         // { element: <Navigate to="/creation/projects" replace />, index: true },
        //         { element: <Projects />, index: true },
        //         { path: ':name', element: <Loadouts portable={false} /> }
        //       ],
        //     },
        //     { path: 'progress', element: <ModelCreation type={"Loadouts In Progress"} /> },
        //     { path: 'review', element: <ModelCreation type={"Pending My Review"} /> },
        //   ]
        // },

        // UPLOAD MODELS
        { path: 'upload',
          // element: <Upload3D />,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.upload.ecommerce} replace />, index: true },
            { path: 'ecommerce',
              children: [ 
                { element: <Upload3D type={"By Ecommerce"} />, index: true },
                { path: ':product', element: <ByVariant /> }
              ],
            },
            { path: 'model', element: <Upload3D type={"By Model"} /> }
          ]
        },

        // Create 3D MODELS
        { path: 'create',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.create.threeD} replace />, index: true },
            { path: 'project',
              children: [ 
                { element: <Create3D />, index: true },
                { path: ':project', children: [
                    { element: <Create3D />, index: true },
                    { path: 'products', element: <Create3D />},
                    { path: 'loadouts', element: <Create3D />},
                    { path: 'complete', element: <Create3D />},
                    { path: 'review', element: <Create3D />}
                  ] 
                },
              ],
            },
          ]
        },
        
        // ECOMMERCE
        {
          path: 'ecommerce',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.ecommerce.library} replace />, index: true }, // NOTE: Using something besides root in index path skirts around issue of not using path children
            { path: 'library', element: <EcommerceProducts /> },
            { path: ':name', element: <EcommerceProductDetails /> },
          ],
        },

        // MODEL LIBRARY
        {
          path: 'models',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.modelLibrary.all} replace />, index: true }, // NOTE: Using something besides root in index path skirts around issue of not using path children
            { path: 'all', element: <ModelsAll /> },
            { path: ':name', element: <ModelDetials /> },
          ],
        },
        
        // ANALYTICS
        {
          path: 'analytics',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.analytics.root} replace />, index: true },
            { path: 'overview', element: <AnalyticsOverview /> },
          ],
        },

        // MANAGMENT
        {
          path: 'account',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.account.general} replace />, index: true },
            { path: 'general', element: <AccountSettings type={"General"} /> },
            { path: 'billing', element: <AccountSettings type={"Billing"} /> },
            { path: 'notifications', element: <AccountSettings type={"Notifications"} /> },
            { path: 'social', element: <AccountSettings type={"Social Links"} /> },
            { path: 'password', element: <AccountSettings type={"Change Password"} /> },
          ],
        },
        {
          path: 'store',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.store.overview} replace />, index: true },
            { path: 'overview', element: <StoreSettings type={"Overview"} /> },
          ]
        },
        {
          path: 'store-admin',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.admin.root} replace />, index: true },
            { path: 'micro-site-overview', element: <StoreAdmin type={'Micro Site Overview'} /> },
            { path: 'micro-site-builder', children: [
                { element: <StoreAdmin type={'Micro Site Builder'} />, index: true },
                { path: ':micrositeid', element: <StoreAdmin type={'Micro Site Builder'} /> }
              ],
            },
          ],
        },
      ],
    },

    /*
    * Account Routes
    */
    {
      path: 'account',
      element: (
        <AuthGuard>
          <AccountLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_ACCOUNT_OWNER} replace />, index: true },
        { path: 'account-stores', element: <AccountStores /> },
      ],
    },
    
    /*
    * Admin Routes
    */
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_ADMIN} replace />, index: true },
        { path: 'admin-overview', element: <AdminOverview /> },
        { path: 'forbidden-room', element: <AdminForbiddenRoom /> },
        { path: 'stores',
            children: [
              { element: <Navigate to="/admin/stores/all" replace />, index: true },
              { path: 'all', element: <AdminStoresController type={"All Stores"} /> },
              { path: 'active', element: <AdminStoresController type={"Active Stores"} /> },
              { path: 'projects', element: <AdminStoresController type={"All Projects"} /> },
              { path: 'loadouts', element: <AdminStoresController type={"All Loadouts"} /> },
            ]
        },
      ],
    },

    /*
    * Wild Card Routes
    */
    {
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
      ],
    },

    // ** When it is only app.levar.io / login / signup or blank **
    { path: '/', element: <Navigate to="/auth/login" replace /> },
    { path: '/login', element: <Navigate to="/auth/login" replace /> },
    { path: '/signup', element: <Navigate to="/auth/register" replace /> },
    
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// ---- AUTHENTICATION ---- //
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Verify = Loadable(lazy(() => import('../pages/auth/Verify')));
const Wizard = Loadable(lazy(() => import('../pages/auth/Wizard')));


// ---- DASHBOARD ---- //
// General
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/General/Overview')));

// Models (Library)
const ModelsAll = Loadable(lazy(() => import('../pages/dashboard/ModelLibrary/ModelsAll')));
const ModelDetials = Loadable(lazy(() => import('../pages/dashboard/ModelLibrary/ModelDetails')));

// Upload 3D
const Upload3D = Loadable(lazy(() => import('../pages/dashboard/Upload3D/_index')));
const ByVariant = Loadable(lazy(() => import('../pages/dashboard/Upload3D/ByVariant')));
// const ByModel = Loadable(lazy(() => import('../pages/dashboard/Upload3D/ByModel')));

// Create 3D
const Create3D = Loadable(lazy(() => import('../pages/dashboard/Create3D/_index')));

// Ecommerce
const EcommerceProducts = Loadable(lazy(() => import('../pages/dashboard/EcommerceLibrary/_index')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceLibrary/EcommerceProductDetails')));

// Analytics
const AnalyticsOverview = Loadable(lazy(() => import('../pages/dashboard/Analytics/AnalyticsOverview')));

// User & Store settings
const AccountSettings = Loadable(lazy(() => import('../pages/dashboard/AccountSettings/_index')));
const StoreSettings = Loadable(lazy(() => import('../pages/dashboard/StoreSettings/_index')));

const StoreAdmin = Loadable(lazy(() => import('../pages/dashboard/StoreAdmin/_index')));

// ---- ACCOUNT OWNER DASHBOARD ---- //
// Store Selector
const AccountStores = Loadable(lazy(() => import('../pages/accounts/Stores')));

// ---- ADMIN DASHBOARD ---- //
// Overview
const AdminOverview = Loadable(lazy(() => import('../pages/admin/AdminOverview')));

// Store Selector
const AdminStoresController = Loadable(lazy(() => import('../pages/admin/AdminStoresController/_index')));

// Hidden Tools
const AdminForbiddenRoom = Loadable(lazy(() => import('../pages/admin/AdminForbiddenRoom')));

// ---- GLOBAL ---- //
// FALLBACKS
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
