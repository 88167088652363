import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
// fetch
import { fetchLoadout } from '../../../utils/fetchData';
import { getJWTstorage } from '../../../utils/jwt';
// component
import {
  FormProvider
} from '../../hook-form';
import FormImageUploadCard from '../../Forms/FormImageUploadCard';
import FormTextEditorCard from '../../Forms/FormTextEditorCard';
import FormTodoChecklistCard from '../../Forms/FormTodoChecklistCard';
import LoadoutBestPracticesDrawer from '../../drawer/LoadoutBestPracticesDrawer';
// hooks
import useToggle from '../../../hooks/useToggle';
// helpers
import { 
  buildTemplateStatic,
  buildTemplateSimple,
  buildTemplateAI
} from './_helpers';
// Sections
import LoadoutChecklistCard from './LoadoutChecklistCard';
import VariantImageDrawerController from './VariantImageDrawer/VariantImageDrawerController'
// ------------------------------------------------------------------------


ProjectLoadoutView.propTypes = {
  methods: PropTypes.any,
  isSubmitting: PropTypes.any,
  handleValidateLoadout: PropTypes.func,
  fieldsTodo: PropTypes.any,
  appendTodo: PropTypes.func,
  removeTodo: PropTypes.func,
};

export default function ProjectLoadoutView({ 
  methods, 
  isSubmitting, 
  handleValidateLoadout,
  fieldsTodo,
  appendTodo,
  removeTodo,
}) {
  const dispatch = useDispatch();

  // Redux
  const { account: { user }, admin } = useSelector(state => state);
  
  // state 
  const [state, setState] = useState({
    ready: false,
    loadoutData: {},
  });

  const [blockedUI, setBlockedUI] = useState({
    one: true,
    two: true,
    three: true,
    four: false // eventually show
  });

  // Form Hooks / Functions
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isValid },
  } = methods;
  
  // Open Image Select Form
  const { toggle: openForm, onOpen: onOpenForm, onClose: onCloseForm } = useToggle();
  
  // Used for opening example loadout drawer
  const { toggle: openBestPractices, onOpen: onOpenBestPractices, onClose: onCloseBestPractices } = useToggle();
  
  const values = watch();


  // ----------------------
  // API Data Fetch For Single Loadout and Feedback
  // ----------------------
  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted) {
      const getResource = async () => {

        const jwt = getJWTstorage(admin, user.uuid);
        const loadoutResponse = await fetchLoadout(values.loadout_id, jwt);
        // TODO: This doesnt look like the correct way to handle failed fetch
        if(loadoutResponse.statusCode !== 200) { return; }

        setValue('linked_products', [...loadoutResponse.search_response.linked_products]);
        
        // STEP: 1
        if(loadoutResponse.search_response?.product_details) {
          setValue('product_details', loadoutResponse.search_response.product_details);
          setBlockedUI(prev => ({ ...prev, one: !prev.one }))
        }
        
        // STEP: 2
        if(loadoutResponse.search_response?.additional_product_photos) {
          setValue('additional_product_photos', [...loadoutResponse.search_response.additional_product_photos]);
          setBlockedUI(prev => ({ ...prev, two: !prev.two }))
        }
        
        // (Only Ecomm Loadout)
        // if(loadoutResponse.search_response.loadout_type === 1) {
        //   setSelectedProduct(loadoutResponse.search_response.linked_products[0].product_id);
        // }

        // STEP: 4 To Show Chat Card instead of Checkbox Card
        if(loadoutResponse.search_response.client_loadout_stage >= 5) {
          setBlockedUI(prev => ({ ...prev, three: !prev.three, four: !prev.four }))
        }

        setState((prev) => ({ 
          ...prev,
          loadoutData: { ...loadoutResponse.search_response },
          ready: true,
        }));

      }
      getResource();
    }
    
    return function cleanup() {
      isComponentMounted = false;
    }
  }, [values.loadout_id, admin, setValue, user.uuid, dispatch]);


  // ----------------------------------
  // Action: Called upon image or file being dropped 
  // ----------------------------------
  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const images = values.additional_product_photos || [];
      setValue('additional_product_photos', [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);

      // Need to manually trigger validation on this since its type file
      await trigger('additional_product_photos');
    },
    [setValue, values.additional_product_photos, trigger]
  );

  
  // ----------------------------------
  // Removes single photo
  // ----------------------------------
  const handleRemove = async (file) => {
    const filteredItems = values.additional_product_photos && values.additional_product_photos?.filter((_file) => _file !== file);
    setValue('additional_product_photos', filteredItems);
    // Need to manually trigger validation to show requirement error message to user
    await trigger('additional_product_photos');
  };


  // ----------------------------------
  // Removes single photo
  // ----------------------------------
  const passButtonType = async (buttonType) => {
    await handleValidateLoadout(buttonType)
  };


  // ----------------------------------
  // Step 1: Generate AI Loadout ChatGPT
  // ----------------------------------
  const handleTemplateAI = async () => {
    const template = await buildTemplateAI(values?.linked_products, admin, user);

    setValue('product_details', template)
  };


  // ----------------------------------
  // Step 1: Simple Template Loadout 
  // ----------------------------------
  const handleTemplateSimple = async () => {
    const template = await buildTemplateSimple(values?.linked_products, admin, user);

    setValue('product_details', template);
  };


  // ----------------------------------
  // Step 1: Static Template Loadout 
  // ----------------------------------
  const handleTemplateStatic = () => {
    const template = buildTemplateStatic();

    setValue('product_details', template);
  };

  const copyVariantsForChris = () => {
    const toCopy = []
    values.linked_products.forEach(item => item.variants.forEach(variant => toCopy.push(variant.split('_')[3])));
    navigator.clipboard.writeText(toCopy)
  }


  return (
    <Box sx={{ p: 3, mx: 'auto' }}>

      {/* Title */}
      {/* <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} align="center" gutterBottom>
        {values.loadout_title}: Loadout Info
      </Typography> */}
      
      {/* Copy */}
      <Typography variant="body1" align="center" sx={{ pb: { xs: 3, xl: 3 }, mx: 'auto' }}>
        Follow the Steps below to create a 3D Loadout. 
        <Link 
          href="#" 
          underline="none" 
          component="button"
          color="gold.main" 
          sx={{ ml: 0.6, '& .MuiLink-button': { textColor: 'blue' }}}
          onClick={() => onOpenBestPractices()}
        >Learn The Best Practices.</Link>
      </Typography>      

      <FormProvider methods={methods} onSubmit={handleSubmit(passButtonType)}>
        <Grid container direction="row" spacing={2}>

          {/* Product Carousel (Ecomm Only) */}
          {/* { values.loadout_type === 1 &&
          <Grid item xs={12} sx={{ mb: 1 }}>
            <ProductsCarousel
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              products={values?.linked_products}
            />
          </Grid>
          } */}

          {/* STEP 1: Text Editor */}
          <Grid container item xs={7} alignItems="stretch" direction="row" sx={{ position: 'relative' }}>
            <FormTextEditorCard
              ready={state.ready}
              methods={methods}
              handleTemplateAI={handleTemplateAI}
              handleTemplateSimple={handleTemplateSimple}
              handleTemplateStatic={handleTemplateStatic}
              blockedUI={blockedUI.one}
              setBlockedUI={() => setBlockedUI(prev => ({ ...prev, one: !prev.one }))}
            />
          </Grid>

          <Grid container item xs={5} direction="column" justifyContent="space-between" spacing={2}>

            {/* STEP 2: Product Image Upload */}
            <Grid item sx={{ position: 'relative' }}>
              <FormImageUploadCard 
                ready={state.ready}
                methods={methods}
                handleRemove={handleRemove}
                handleDrop={handleDrop}
                openVariantImageDrawer={onOpenForm}
                blockedUI={blockedUI.two}
                setBlockedUI={() => setBlockedUI(prev => ({ ...prev, two: !prev.two }))}
              /> 
            </Grid>
            
            {/* STEP 3: Loadout Checklist || Admin - Client Chat Window */}
            <Grid item sx={{ position: 'relative' }}>
              { state.loadoutData?.client_loadout_stage >= 5 ? 
              <FormTodoChecklistCard 
                ready={state.ready}
                methods={methods}
                fieldsTodo={fieldsTodo}
                appendTodo={appendTodo}
                removeTodo={removeTodo}
                blockedUI={blockedUI.three}
                setBlockedUI={() => setBlockedUI(prev => ({ ...prev, three: !prev.three }))}
              />
              :
              <LoadoutChecklistCard 
                ready={state.ready}
                methods={methods}
                blockedUI={blockedUI.three}
                setBlockedUI={() => setBlockedUI(prev => ({ ...prev, three: !prev.three }))}
              />
              }
            </Grid>
            
          </Grid>


          {/* Action Buttons */}
          <Grid item xs={12}>
            <Stack direction="row">
            { admin.adminStatus && 
              <Button 
                onClick={copyVariantsForChris}
                variant="contained"
                size="small"
              >
                Copy Variants
              </Button>
            }
              <div style={{ flex: '1 0 0' }} />

              {/* ADMIN Button Group */}
              { admin.adminStatus && 
              <ButtonGroup 
                variant="contained" 
                aria-label="split button" 
                disableElevation 
                sx={{ boxShadow: 'none' }}
              >
                
                {/* Save Loadout */}
                <LoadingButton 
                  // color="gold"
                  disabled={
                    !state.ready || 
                    values.product_details.length <= 50 || 
                    state.loadoutData?.client_loadout_stage >= 5
                  }
                  variant="outlined" 
                  onClick={() => passButtonType('save')}
                  loading={isSubmitting === 'save' }
                >
                  Save (4)
                </LoadingButton>

                {/* Submit for Artist Review Loadout */}
                <LoadingButton
                  // color="gold"
                  disabled={
                    !state.ready || 
                    !isValid || 
                    state.loadoutData?.client_loadout_stage === 5 ||
                    state.loadoutData?.client_loadout_stage === 7
                  }
                  variant="outlined" 
                  onClick={() => passButtonType('submit')}
                  loading={isSubmitting === 'submit' }
                >
                  { state.loadoutData?.client_loadout_stage !== 6 && 'Submit For Review (5)' }
                  { state.loadoutData?.client_loadout_stage === 6 && 'Submit Revisions (5)' }
                </LoadingButton>
                
                {/* Artist Return to Client Loadout */}
                <LoadingButton
                  // color="error"
                  disabled={
                    !state.ready
                  }
                  variant="outlined" 
                  onClick={() => passButtonType('submitBack')}
                  loading={isSubmitting === 'submitBack' }
                >
                  Needs Revisions (6)
                </LoadingButton>

                {/* Artist Accept Loadout */}
                <LoadingButton
                  // color="error"
                  disabled={
                    !state.ready || 
                    state.loadoutData?.client_loadout_stage === 7
                  }
                  variant="outlined" 
                  onClick={() => passButtonType('accepted')}
                  loading={isSubmitting === 'accepted' }
                >
                  Accept (7)
                </LoadingButton>

              </ButtonGroup>
              }
              
              {/* CLIENT Button Group */}
              { !admin.adminStatus && 
              <ButtonGroup 
                variant="contained" 
                aria-label="split button" 
                disabled={ state.loadoutData?.client_loadout_stage === 5 || state.loadoutData?.client_loadout_stage === 7}
                disableElevation 
                sx={{ boxShadow: 'none' }}
              >

                { state.loadoutData?.client_loadout_stage !== 6 &&
                <LoadingButton 
                  disabled={!state.ready || values.product_details.length <= 50}
                  variant="outlined" 
                  onClick={() => passButtonType('save')}
                  loading={isSubmitting === 'save' }
                >
                  Save
                </LoadingButton>
                }

                <LoadingButton
                  color="gold"
                  disabled={!state.ready || !isValid}
                  variant="contained" 
                  onClick={() => passButtonType('submit')}
                  loading={isSubmitting === 'submit' }
                >
                  { state.loadoutData?.client_loadout_stage !== 6 && 'Submit For Review' }
                  { state.loadoutData?.client_loadout_stage === 6 && 'Submit Revisions' }
                </LoadingButton>

              </ButtonGroup>
              }

            </Stack>
          </Grid>

        </Grid>
      </FormProvider>

      {/* Artist / Client Feedback */}
      {/* { (state.loadoutData?.client_loadout_stage >= 5 || admin.adminStatus) && 
        <FeedbackPopup 
          loadoutId={values.loadout_id}
        />
      } */}

      {/* Product (Variant) Image Selector Drawer */}
      <VariantImageDrawerController
        onClose={onCloseForm} 
        open={openForm}
        methods={methods}
      />

      {/* Drawer: Best Practices Helper */}
      <LoadoutBestPracticesDrawer 
        onClose={onCloseBestPractices}
        open={openBestPractices}
      />

    </Box>
  );
}