import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
// components
import Iconify from '../../Iconify';
import { useDispatch } from '../../../redux/store';
import GhostReasignment from './GhostReasignment';
import LoadoutCombiner from './LoadoutCombiner';
import { getStoreSuccess, resetAccountStore } from '../../../redux/slices/account';


// ----------------------------------------------------------------------

AdminArtToolsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
};

export default function AdminArtToolsDialog({ onClose, dialogData }) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && dialogData.store) {
      const getResource = async () => {
        dispatch(getStoreSuccess(dialogData.store));
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [dialogData, dispatch]);

  const handleClose = () => {
    dispatch(resetAccountStore());
    onClose();
  }

  return (
    <Box>
      <Stack spacing={2} direction="column">
          <Typography variant="h4" align="center" color="primary" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
            Art Tools
          </Typography>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 20, top: 1 }}>
            <Iconify icon={'ic:round-close'} width="20" height="20" />
          </IconButton>

        <Divider />
        <Stack spacing={2} direction="column">
          <Typography variant="subtitle1" align="center" color="primary" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
            {dialogData.store.store_title} - {dialogData.store.store_id} - {dialogData.store.store_domain}
          </Typography>
          <Box sx={{ mt: 1, px: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GhostReasignment onClose={onClose} dialogData={dialogData} />
              </Grid>
              <Grid item xs={12}>
                <LoadoutCombiner onClose={onClose} dialogData={dialogData} />
              </Grid>
            </Grid>
          </Box>
        </Stack>


      </Stack>

    </Box>
  )
};