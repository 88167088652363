import PropTypes from 'prop-types';
import { DialogAnimate } from '../../animate';
import ProjectLoadoutController from './ProjectLoadoutController'

// ----------------------------------------------------------------------

LoadoutEditDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loadout: PropTypes.object,
  handleLoadout: PropTypes.func,
};

export default function LoadoutEditDialog({ 
  open,
  onClose,
  loadout, 
  handleLoadout,
  ...other 
}) {
  return (
    <DialogAnimate 
      fullWidth 
      maxWidth={'lg'}
      open={open}
      onClose={() => onClose()} 
      {...other}
    >
      <ProjectLoadoutController 
        loadout={loadout}
        handleLoadout={handleLoadout}
      />
    </DialogAnimate>
  );
}
