// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { Icon3D } from '../../assets';
// components
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

export default function CustomBlockContent() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >

      <Icon3D sx={{ width: 80 }} />

      <Box sx={{ p: 2 }}>
        <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
          <Iconify color="primary" icon={'bi:badge-3d-fill'} width={25} height={25} sx={{ mr: 1 }} />  
          Drop or Select 3D File
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
          &nbsp;through your machine
        </Typography>
      </Box>
    </Stack>
  );
}
