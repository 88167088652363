import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import { useResponsive } from '../../../hooks/useResponsive';
// utils
import css from '../../../theme/css';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
// import Searchbar from './Searchbar';
import AdminAccountPopover from './AdminAccountPopover';
import { getJWTstorage } from '../../../utils/jwt';
import { useSelector } from '../../../redux/store';

// import NotificationsPopover from './NotificationsPopover';

// import ContactsPopover from './ContactsPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...css(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

AdminDashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function AdminDashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const { enqueueSnackbar } = useSnackbar();
  const ReudxUser = useSelector(state => state.account.user);
  const ReduxAdmin = useSelector(state => state.admin);
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const copyJWT = () => {
    const jwt = getJWTstorage(ReduxAdmin, ReudxUser.uuid);
    navigator.clipboard.writeText(jwt);
    enqueueSnackbar('JWT copied to clipboard', { variant: 'success' });
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}

          <Tooltip title="Copy JWT">
            <IconButton onClick={copyJWT} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="oui:token-key" color="gold.main"/>
            </IconButton>
          </Tooltip>

          <AdminAccountPopover />
        </Stack>

      </Toolbar>
    </RootStyle>
  );
}
