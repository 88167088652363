import PropTypes from 'prop-types';
// @mui
import { Box, Tooltip } from '@mui/material';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  depth: PropTypes.number,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    specialChar: PropTypes.string
  }),
};

export default function NavItem({ item, depth, active, open, isCollapse, ...other }) {
  const { title, icon, info, children, disabled, caption, specialChar } = item;

  const renderContent = (
    <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
      {specialChar && <Iconify icon={specialChar} sx={{ width: 22, height: 22, mr: 2 }} />}

      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      {depth !== 1 && <DotIcon active={active && depth !== 1} specialChar={specialChar} />}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={title}
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
            />
          )}
        </>
      )}
    </ListItemStyle>
  );

  return renderContent;
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
  specialChar: PropTypes.string
};

export function DotIcon({ active, specialChar }) {
  
  return (
    <ListItemIconStyle>
      { specialChar ?
        <Iconify
          icon={specialChar}
          sx={{ width: 12, height: 12 }}
        />
        :
        <Box
          component="span"
          sx={{
            width: 4,
            height: 4,
            borderRadius: '50%',
            bgcolor: 'text.disabled',
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(active && {
              transform: 'scale(2)',
              bgcolor: 'primary.main',
            }),
          }}
        />
      }
    </ListItemIconStyle>
  );
}
