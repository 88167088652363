import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
// components
import Image from '../../../Image'

// ----------------------------------------------------------------------

VariantCard.propTypes = {
  image: PropTypes.string,
  onItemSelect: PropTypes.func,
  itemsSelected: PropTypes.array,
};

export default function VariantCard({ 
  image, 
  onItemSelect, 
  itemsSelected, 
}) {

  return (
    <Card>
      <Box>
        <Image 
          alt={`${image}`} 
          src={image} 
          sx={{ backgroundColor: 'white', height: 175, width: 175 }}
        />
        <Checkbox
          size="small" 
          aria-label="add-product-image-from-ecommerce-store"
          checked={itemsSelected.includes(image)}
          onChange={() => onItemSelect(image)} 
          sx={{ position: 'absolute', top: 5, right: 5 }}
        />
      </Box>
    </Card>
  );
}