// Routes
import { PATH_ADMIN } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const getIconify = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  startedItem: getIconify('material-symbols:star')
};

// Admin Dashboard
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Admin Overview', path: '/admin/admin-overview', icon: ICONS.user },
    ],
  },
  {
    subheader: 'Stores',
    items: [
      { title: 'All Stores', path: PATH_ADMIN.stores.all, icon: ICONS.banking },
      { title: 'Active Stores', path: PATH_ADMIN.stores.active, icon: ICONS.startedItem },
      { title: 'All Projects', path: PATH_ADMIN.stores.projects, icon: ICONS.kanban },
      { title: 'All Loadouts', path: PATH_ADMIN.stores.loadouts, icon: ICONS.booking },
    ],
  },


];

export default navConfig;
