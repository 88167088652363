import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Link, Box, Card, Table, Stack, IconButton, Divider, TableRow, TableHead, TableCell, TableBody, Grid, Typography, TableContainer, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector, useDispatch, shallowEqual } from '../../redux/store'
import { setActivateAndPayInvoice, setSetupInvoice } from '../../redux/thunks/billingThunks'
import { setDeleteAllProjectLoadouts, setUpdateProject, setCreateLoadoutsForProducts } from '../../redux/thunks/projectLoadoutsThunks'
import { updateCartContents, resetCart } from '../../redux/slices/cart';
import { fCurrency } from '../../utils/formatNumber';
import { getJWTstorage } from '../../utils/jwt';
import { fetchProjectLoadouts } from '../../utils/fetchData';
import Image from '../Image';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import levarIconHoriz from '../../assets/logos/Levar_Logo_Horz_POS_Pantone.svg';

const { REACT_APP_SHOPIFY_TEST } = process.env;

CartCheckoutDialog.propTypes = {
  onClose: PropTypes.func,
}

export default function CartCheckoutDialog({ onClose }) {
  const dispatch = useDispatch();
  const { project = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { cart, total } = useSelector(state => state.cart.checkout, shallowEqual)
  const { store, user } = useSelector(state => state.account)
  const admin = useSelector(state => state.admin)
  const [isSubmitting, setSubmitting] = useState(false);
  const [shouldCreateAdminInvoice, setShouldCreateAdminInvoice] = useState(true);
  const [localCart, setLocalCart] = useState([...cart])
  const [state, setState] = useState({
    project: null,
    loadout: null
  });

  useEffect(() => {
    let isComponentMounted = true;
  
    const getResource = async () => {
      if (!isComponentMounted) return;
  
      const jwt = getJWTstorage(admin, user.uuid);
      const projectsResponse = await fetchProjectLoadouts(project, jwt);
      setState({ project: projectsResponse.project_response, loadout: projectsResponse.loadout_response[0] })
    };
  
    getResource();
  
    return () => {
      isComponentMounted = false;
    };
  }, [project, admin, user.uuid])

  const removeItemFromCart = (row) => {
    setLocalCart((prev) => ([...prev.filter(prevProduct => prevProduct.id !== row.id)]))
    dispatch(updateCartContents(row.product_id));
    
    if(cart.length - 1 === 0) onClose()
  };

  const onRedirectForPayment = async () => {
    setSubmitting(true);

    // 1: Setup invoice (returns id)
    let createdInvoiceID
    if(!admin.adminStatus || shouldCreateAdminInvoice) { // Admin can decide if they want an invoice created
      createdInvoiceID = await dispatch(setSetupInvoice({ 
        price: total, 
        title: `3D Model Commission $${total}`, 
        status: false // IF true it sets the thing to ACTIVE which we dont want
      }));
    }

    // 2: Get rid of existing loadouts (if they go to shopify and dont pay get rid of those pre-existing loadouts)
    await dispatch(setDeleteAllProjectLoadouts({ 
      projectID: state.project.project_id 
    }));

    // 3: Create individual loadouts for each product id
    await dispatch(setCreateLoadoutsForProducts({ 
      projectID: state.project.project_id, 
      cartProducts: cart,
      loadoutWizardStage: 3, 
      clientLoadoutStage: 5, // set to Submitted 
      loadoutType: 1
    }));

    // 4: Add invoice ammount to project because we wont have access to it upon redirect
    await dispatch(setUpdateProject({ 
      projectInfo: { 
        ...state.project, 
        invoice_ammount: total, 
        ...(admin.adminStatus && { project_stage: 1 }) // Admin Skip
      }
    }));

    // Admin skip payment process
    if(admin.adminStatus) {
      setSubmitting(false);
      dispatch(resetCart());
      window.location.reload();
      return;
    }

    const invoice = {
      price: total,
      charge_name: `3D Model Commission $${total}`,
      charge_uuid: createdInvoiceID,
      test: REACT_APP_SHOPIFY_TEST === 'true' && true
    };

    // 5: Activate invoice on ES & Shopify
    const activatedInvoice = await dispatch(setActivateAndPayInvoice({ invoice }));

    // 6: Forward to shopify for payment confirmation
    if(store?.platform === 'shopify') {
      window.location.assign(`${activatedInvoice.confirmation}`);
      enqueueSnackbar(`Redirecting`, { variant: "info" });
    }
    // NOTE: Look at _index.js in create3d folder for charge_id handling useEffect
    setSubmitting(false);
  }
  // console.log('localCart', localCart)

  const renderList = (
    <TableContainer sx={{ overflow: 'unset', mt: 3 }}>
      <Scrollbar sx={{ maxHeight: 480 }}>
        <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
          <TableHead>
            <TableRow>
              {/* Item Number */}
              <TableCell width={40}> </TableCell>
              
              {/* Image */}
              <TableCell width={40}> </TableCell>

              {/* Product Title */}
              <TableCell sx={{ typography: 'subtitle2' }}>Product</TableCell>

              {/* Number of Variations */}
              <TableCell>Variations</TableCell>

              {/* <TableCell align="right">Unit price</TableCell> */}

              {/* Price Total for Product */}
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { localCart.map((row, index) => (
              <TableRow key={index}>
                {/* Column 1 */}
                <TableCell>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    {/* <Typography variant="caption">{index + 1}</Typography> */}
                    
                    <IconButton onClick={() => removeItemFromCart(row)} size="small">
                      <Iconify icon={'mdi:trash'} />
                    </IconButton>
                  </Stack>

                </TableCell>

                {/* Column 2 */}
                <TableCell>                
                  <Image 
                    disabledEffect 
                    alt="test"
                    src={row?.image_url ?? "" } 
                    sx={{ borderRadius: 1.5, width: 40, height: 40, mx: 1 }} 
                  /> 
                </TableCell>
                
                {/* Column 3 */}
                <TableCell>
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">{row.product_title}</Typography>

                    <Stack direction="row" spacing={0.5}>
                      {/* Number of variants */}
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                      Variations: { row.variant_count }
                      </Typography>
                      
                    </Stack>
   
                  </Box>
                </TableCell>
                
                {/* Column 3 */}
                <TableCell>Includes: {row.variant_count}</TableCell>

                {/* <TableCell align="right">{fCurrency(row.price * row.quantity)}</TableCell> */}
                <TableCell align="right">$ {row.invoice_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );

  return (
    <Stack>
      <Card sx={{ pt: 3, px: 2 }}>
        <Box
          rowGap={5}
          display="grid"
          alignItems="center"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <Box
            component="img"
            alt="logo"
            src={levarIconHoriz} 
            sx={{ maxWidth: 140 }}
          />
        </Box>

        {renderList}

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

        <Stack justifyContent="flex-end" alignItems="space-between" direction="row" spacing={2}>

          { admin.adminStatus &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', mr: 4}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={shouldCreateAdminInvoice}
                    onChange={() => setShouldCreateAdminInvoice(prev => !prev)}
                    color="success"
                  />
                }
                label="Create Admin Invoice"
              />
            </Box>
          }

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', pr: 2, pt: 1 }}>
            <Typography sx={{ typography: 'subtitle1' }}>Total</Typography>
            <Typography sx={{ typography: 'subtitle1' }}>
              {fCurrency(total)}
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

        <Grid container>
          <Grid item xs={12} md={6} sx={{ py: 3 }}>
            <Typography variant="subtitle2">NOTES</Typography>

            <Typography variant="body2">
              3D Model creation will be started once payment has been complete
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">Have a Question?</Typography>

            <Typography variant="body2">
              <Link underline="always" href="mailto:info@levar.io">info@levar.io</Link>
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <LoadingButton 
              variant="contained"
              size="large"
              color="gold"
              onClick={() => onRedirectForPayment()}
              endIcon={<Iconify icon={'gis:cube-3d'} width={20} height={20} />}
              loading={isSubmitting}
            >
              Commission Now
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  )
}