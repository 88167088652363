import { fetchOptions, fetchOptionsNoBody, noAuthOptions, fetchOptionsFlowCode } from "./fetchOptions";

const { 
    REACT_APP_ES_API, 
    REACT_APP_ES_API_BILLING, 
    REACT_APP_RDS_API, 
    REACT_APP_FEEDBACK_API, 
    REACT_APP_ES_API_BCO, 
    REACT_APP_STRIPE_API, 
    REACT_APP_INVOICE_API,
    REACT_APP_GOOGLE_ANALYTICS_API,
    REACT_APP_PROJECTS_API,
    REACT_APP_ECOMMERCE_API, // 2023
} = process.env;


// *******************************************************************************************************
// 2023 Dashboard User/Admin #END*************************************************************************

// // GET All levar_variants of specific product_id
// export const fetchEcommerceVariantsForProduct = async (storeID, productID, jwt, admin) => {    
//   const url = `${REACT_APP_ECOMMERCE_API}/${admin.adminStatus ? 'admin' : 'user'}/variants?store_id=${storeID}&product_id=${productID}`;
//   const options = fetchOptionsNoBody("GET", jwt);
//   const response = await fetch(url, options);
//   if(!response.ok) { throw new Error(`Unable To GET Variants for that Product ID`) };
//   const data = await response.json();
//   return data;
// };


// 2023 Dashboard User/Admin #END*************************************************************************
// *******************************************************************************************************

// *******************************************************************************************************
// Google Analytics #END**********************************************************************************

export const fetchGoogleAccessTokenCreate = async (code) => {
  const url = `${REACT_APP_GOOGLE_ANALYTICS_API}/access_token?auth_code=${code}`;
  const response = await fetch(url);
  if(!response.ok) { throw new Error(`Unable To Fetch`) };
  const data = await response.json();
  return data;
};

export const fetchGoogleRunReport = async (refreshToken, metrics, dimensions, dimensionFilter, propertyId) => {
  const url = `${REACT_APP_GOOGLE_ANALYTICS_API}/access_token?refresh_token=${refreshToken}`;
  const response = await fetch(url);
  if(!response.ok) { throw new Error(`Unable To Fetch`) };
  const data = await response.json();
  
  const getReportsURL = `${REACT_APP_GOOGLE_ANALYTICS_API}/run_report?access_token=${data.access_token}&metrics=${metrics}&dimensions=${dimensions}&dimensionFilter=${dimensionFilter}&property_id=${propertyId}`
  const responseReports = await fetch(getReportsURL);
  if(!responseReports.ok) { throw new Error(`Unable To Fetch`) };
  const reportsData = await responseReports.json();
  return reportsData;
};

// Google Analytics #END**********************************************************************************
// *******************************************************************************************************

// *******************************************************************************************************
// Users #START*******************************************************************************************

// GET Fetch levar_account_id by admin_user_id (UUID)
export const fetchLevarAccountId = async (userID) => {
  const url = `${REACT_APP_RDS_API}/api/v1/levar_account_id/${userID}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

// GET Fetch USER from RDS by email
// NOTE: has been updated by cully (if integration entry doesnt exist then return just the user table entry)
export const fetchUserByUUID = async (accountID) => {
  const url = `${REACT_APP_RDS_API}/api/v1/user_uuid/${accountID}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

// TODO: come back to this. The new version doesnt work quite yet
export const fetchImpresonateUser = async (uuid) => {
  // const options = fetchOptionsNoBody("GET", jwt);
  const url = `${REACT_APP_ES_API}/stores/get_single_user?levar_user_account_id=${uuid}`;
  const response = await fetch(url);
  if(!response.ok) { throw new Error(`Unable Fetch User`) };
  const data = await response.json();
  return data;
}

export const fetchCreateRDSCognitoTeamMember = async (payload) => {
  const url = `${REACT_APP_RDS_API}/api/v1/add_user_to_account`;
  const options = noAuthOptions("POST", payload);
  const response = await fetch(url, options);
  // if(!response.ok){throw new Error(`Unable To Create Team Member in Cognito & RDS`)}
  const data = await response.json();
  return data;
}

export const fetchDeleteRDSCognitoTeamMember = async (payload) => {
  const url = `${REACT_APP_RDS_API}/api/v1/delete_user_account`;
  const options = noAuthOptions("DELETE", payload);
  const response = await fetch(url, options);
  if(!response.ok){throw new Error(`Unable To Delete Team Member in Cognito & RDS`)}
  const data = await response.json();
  return data;
}

export const fetchSendTeamMemberWelcomEmail = async (payload, jwt) => {
  const url = `${REACT_APP_ECOMMERCE_API}/user/email`;
  const options = fetchOptions("POST", payload, jwt);
  const response = await fetch(url, options);
  if(!response.ok){throw new Error(`Unable to send that email`)}
  const data = await response.json();
  return data;
}

// Users #END*********************************************************************************************
// *******************************************************************************************************


// *******************************************************************************************************
// Stores #START******************************************************************************************

// GET Shopify access token from sinas RDS api
export const fetchActiveStores = async (jwt) => {
  // https://c0l9hb8b28.execute-api.us-east-1.amazonaws.com/dev/admin/stores/activity/dynamo
  const url = `${REACT_APP_ECOMMERCE_API}/admin/stores/activity/dynamo`
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// PUT Update store fields 
export const fetchUpdateStoreFields = async (payload, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/store/fields`;
  const options = fetchOptions("PUT", payload, jwt);
  const response = await fetch(url, options);
  if(!response.ok){throw new Error(`Unable To Update that store`)}
  const data = await response.json();
  return data;
};

// Create a levar private key for a given store id
export const fetchCreatePrivateKey = async(storeID, jwt) => {
  const url = `${REACT_APP_ECOMMERCE_API}/admin/store/private_key?store_id=${storeID}`;
  const options = fetchOptionsNoBody('GET', jwt)
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET Shopify access token from sinas RDS api
export const fetchShopifyToken = async (storeID, jwt) => {
  const cleanId = storeID.split('_')[1]
  const url = `${REACT_APP_ES_API}/stores/get_shopify_access_token?store_id=${cleanId}`
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchShopByStoreId = async(storeID, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/store?store_id=${storeID}`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

export const fetchStore = async (storeID, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/store?store_id=${storeID}`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET all stores for admin and or account owner
export const fetchAllStores = async (accountOwnerUUID, jwt) => {
  const url = `${REACT_APP_ECOMMERCE_API}/admin/stores`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const jsonData = await response.json();
  return jsonData.search_response;
};

// GET all active stores for admin and or account owner
export const fetchAllActiveStores = async (accountOwnerUUID, jwt) => {
  const url = `${REACT_APP_ECOMMERCE_API}/admin/stores?store_status=active`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const jsonData = await response.json();
  return jsonData.search_response;
};

export const fetchCreateStore = async (payload, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/store`;
  const options = fetchOptions("POST", payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// // PUT update dynamic text settings fields on the store doc in Elastic Search
// export const fetchUpdateStoreDynamicTextSettings = async (payload, store, integration, jwt, adminStatus) => {
//   const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/store/dynamic-text-settings`;
//   const options = fetchOptions('PUT', payload, jwt);
//   const response = await fetch(url, options);
//   if(!response.ok) { throw new Error(`Could Not Update`) };
//   const data = await response.json();
//   return data;
// };

// Stores #END********************************************************************************************
// *******************************************************************************************************


// *******************************************************************************************************
// Sign IN/UP #START**************************************************************************************

// step0 MAIN
// Used after the code is verified during signup
// email, setupWizardState, storeURL, storeID, storeType
export const fetchSignUpInitial = async (data) => {
  let url;
  let options;

  if(data.storeType === 'shp') {
    url = `${REACT_APP_RDS_API}/api/v1/step0/`;
    options = noAuthOptions("PUT", { email: data.email, setup_wizard_state: data.setupWizardState, shop: data.storeURL, store_id: data.storeID });
  } else if(data.storeType === 'bco') {
    url = `${REACT_APP_RDS_API}/api/v1/bigcommerce/step0/`;
    options = noAuthOptions("PUT", { email: data.email, setup_wizard_state: data.setupWizardState, shop: data.storeURL, store_id: data.storeID });
  } else if(data.storeType === 'mfr') {
    url = `${REACT_APP_RDS_API}/api/v1/manufacturer/step0/`;
    options = noAuthOptions("PUT", { email: data.email, setup_wizard_state: data.setupWizardState, account_owner_uuid: data.accountOwnerUUID });
  } else if(data.storeType === undefined || data.storeType === null) {
    // Internal A defualt to SHP since BCO is external only
    url = `${REACT_APP_RDS_API}/api/v1/step0/`;
    options = noAuthOptions("PUT", { email: data.email, setup_wizard_state: data.setupWizardState, shop: data.storeURL, store_id: data.storeID });
  }
  
  const response = await fetch(url, options);
  const jsonResp = await response.json();
  return jsonResp;
};

// Used at the last step of signup
export const setWizardData = async (email, firstName, lastName, businessName, phoneNumber, country ) => {
  const wizardState = 4;
  try {
    const options = noAuthOptions("PUT", { email, first_name: firstName, last_name: lastName, business_name: businessName, phone_number: phoneNumber, country, setup_wizard_state: wizardState });
    const response = await fetch(`${REACT_APP_RDS_API}/api/v1/wizard/`, options);
    const data = await response.json();
    return data
  } catch(error) {
    return error
  };
};

// Used at the last step of signup
export const setFinalDataInRDS = async(id, levarAccountID, storeURL, setupWizardState, integrationID) => {
  let url;

  switch(integrationID){
    case 1:
      url = `${REACT_APP_RDS_API}/api/v1/shopify-integration-final`;
      break;
    case 2:
      url = `${REACT_APP_RDS_API}/api/v1/custom-integration-final`;
      break;
    case 3:
      url = `${REACT_APP_RDS_API}/api/v1/bigcommerce-integration-final`;
      break;
    default:
      break;
  };

  const email = sessionStorage.getItem('email')
  const options = noAuthOptions("POST", { 
    id, 
    ...( integrationID === 2 && { email }),
    "store_url": storeURL, 
    "setup_wizard_state": setupWizardState, 
    "integration_id": integrationID, 
    "levar_account_id": levarAccountID 
  });
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Fetch Call Cannot Be Made`)}
  const data = await response.json();
  return data;
};

export const setFinalDataMFR = async(payload) => {
  const url = `${REACT_APP_RDS_API}/api/v1/manufacturer-integration-final`;
  const options = noAuthOptions("PUT", payload);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};


// SHOPIFY DATA SYNC External (a)
// BIGC DATA SYNC External (a)
export const elasticInitialDataSync = async (levarUserAccountID, storeUrl, storeHASHorID, storeType) => {
  const payload = {
    store_url: storeUrl,
    levar_user_account_id: levarUserAccountID,
    ...( storeType === 3 && { store_hash: storeHASHorID }), // BCO requires store_hash
  };

  const url = `${REACT_APP_ECOMMERCE_API}/${storeType === 1 ? 'shopify' : 'bigcommerce'}/sync/initial`;
  const options = noAuthOptions('PUT', payload);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};


// USED for account settings to update or write user info to RDS
// OLD NAME fetchSetUserWizardData
export const fetchSetUserRDSData = async (payload) => {
  const options = noAuthOptions("PUT", payload);
  const response = await fetch(`${REACT_APP_RDS_API}/api/v1/wizard`, options);
  const data = await response.json();
  return data;
};

// Update user to stage 6 admin dashboard
export const fetchAdminUpdateWizardState = async(optionsData) => {
    const url = `${REACT_APP_RDS_API}/api/v1/user_setup_wizard/`;
    const options = noAuthOptions("PUT", optionsData);
    const response = await fetch(url, options);
    if(!response.ok) { throw new Error(`Could not update that users setup wizard state`) };
    const data = await response.json();
    return data;
};

// Sign IN/UP #END****************************************************************************************
// *******************************************************************************************************


// *******************************************************************************************************
// PRODUCTS #START****************************************************************************************

// GET Single product by productID
export const fetchSingleProduct = async (productID, jwt, adminStatus) => {    
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/product?product_id=${productID}`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Unable To GET Single Product for that Product ID`) };
  const data = await response.json();
  return data;
};

// GET Fetch all products for a store
export const fetchAllProducts = async (storeID, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/products?store_id=${storeID}`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Unable To GET All User Products`) };
  const data = await response.json();
  return data;
};

export const fetchCreateProductsFromCSV = async (payload, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/products/queue`;
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// PRODUCTS #END******************************************************************************************
// *******************************************************************************************************



// *******************************************************************************************************
// LEVAR Ecommerce Variants #START************************************************************************

// SAME AS BELOW REFACTOR NAME
export const fetchSingleEcommerceVariant = async (ecommVariantID, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variant?variant_id=${ecommVariantID}`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Unable Fetch Single ecommVariantID ${ecommVariantID}`) };
  const data = await response.json();
  return data;
};

// GET Single Variant from ES
export const fetchSingleVariant = async (variantID, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variant?variant_id=${variantID}`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Unable Fetch Single Variant ${variantID}`) };
  const data = await response.json();
  return data;
};

// GET Variants for a given product ID (querey the variants index)
export const fetchVariantsByProductId = async (productID, storeId, jwt, adminStatus) => {    
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?store_id=${storeId}&product_id=${productID}`
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Fetch Call Variants By Product ID Cannot Be Made`) };
  const data = await response.json();
  return data;
};

// GET Variants for a given product ID (querey the variants index)
export const fetchVariantsByModelId = async (modelId, jwt, adminStatus, modelType) => {
  // static_viewer_0 => model viewer
  // static_vto_face_0 => 8th wall vto face try on
  // interactive_viewer_0 || model_3d_id => levar Fibrous 

  // const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?static_viewer_0=${modelId}`
  // const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?static_vto_face_0=${modelId}`
  
  // THESE ARE THE SAME
  // const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?interactive_viewer_0=${modelId}`
  // const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?model_3d_id=${modelId}`


  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants?${modelType}=${modelId}`
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Fetch Call Variants By Model ID Cannot Be Made`) };
  const data = await response.json();
  return data;
};

// GET Product with variants connected (querey the products index)
export const fetchProductWithVariants = async (productID, storeId, jwt, adminStatus) => {    
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/product?product_id=${productID}`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Fetch Call Products with Variants Cannot Be Made`) };
  const data = await response.json();
  return data;
};

// PUT Update variant fields 
export const fetchUpdateEcommerceVariantFields = async (dataToUpdate, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variant/fields`;
  const options = fetchOptions('PUT', dataToUpdate, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Unable To Update That Variant fetchUpdateEcommerceVariantFields`) };
  const data = await response.json();
  return data;
};

export const fetchCreateVariantsFromCSV = async (payload, jwt, adminStatus) => {
  const url = `${REACT_APP_ECOMMERCE_API}/${adminStatus ? 'admin' : 'user'}/variants/queue`;
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// LEVAR Ecommerce Variants #END************************************************************************
// *****************************************************************************************************


// *****************************************************************************************************
// Transactions & Subscriptions #START******************************************************************

export const fetchCheckStatusShopifySubscription = async (info) => {
  const url = `${REACT_APP_ES_API_BILLING}/billing/recurring_charge?store_url=${info.store_url}&recurring_application_charge_id=${info.recurring_application_charge_id}&shopify_access_token=${info.shopify_access_token}`;
  const response = await fetch(url);
  if(!response.ok) { throw new Error(`Could Not Fetch Subscription`) };
  const data = await response.json();
  return data;
}

// Returns the prorated price when a customer upgrades or downgrades a subscription
export const fetchProrateSubscriptionCharge = async (storeId, newFixedPrice, jwt) => {
  const url = `${REACT_APP_ES_API_BILLING}/billing/prorate/calculate?store_id=${storeId}&new_fixed_price=${newFixedPrice}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Prorate Subscription`) };
  const data = await response.json();
  return data;
};

// Creates a usage charge that gets autobilled when prorating a subscription upgrade
export const fetchCreateUsageCharge = async (payload, jwt) => {
  const url = `${REACT_APP_ES_API_BILLING}/billing/usage_charge`
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Create that Prorated Subscription Usage Charge`) };
  const data = await response.json();
  return data;
};



// GET calculate charge based on store_id and new_critical_assets
export const fetchCalculateUsageCharge = async (storeId, newCriticalAssets, account) => {
  let url;
  if(account.integration_id === 3) {
      url = `${REACT_APP_STRIPE_API}/calculate?store_id=${storeId}&new_critical_assets=${newCriticalAssets}`
  } else {
      url = `${REACT_APP_ES_API_BILLING}/billing/calculate/usage_charge?store_id=${storeId}&new_critical_assets=${newCriticalAssets}`;
  }   

  const response = await fetch(url);
  if(!response.ok) { throw new Error(`Fetch Call Cannot Be Made`) };
  const data = await response.json();
  return data;
};

// 1(aa) - TRIAL Create / Start Shopify Subscription
export const fetchStartShopifyTrialSubscription = async (transaction, jwt) => {        
  const url = `${REACT_APP_ES_API_BILLING}/billing/recurring_charge/trial`;
  const options = fetchOptions('POST', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Process Subscription`) };
  const data = await response.json();
  return data;
};

// 1(ab) - Create / Start Shopify Subscription
export const fetchCreateRecurringCharge = async (transaction, jwt) => {        
  // const url = `${REACT_APP_ES_API_BILLING}/billing/create/recurring_charge`;
  const url = `${REACT_APP_ES_API_BILLING}/billing/recurring_charge`;
  const options = fetchOptions('POST', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Process Subscription`) };
  const data = await response.json();
  return data;
};

// 1b - Checking/Confirming CHARGE_ID from Shopify
export const fetchGetRecurringCharge = async (transaction, jwt) => {        
    const url = `${REACT_APP_ES_API_BILLING}/billing/recurring_charge`;
    const options = fetchOptions('PUT', transaction, jwt);
    const response = await fetch(url, options);
    if(!response.ok) { throw new Error(`Could Not GET Subscription`) };
    const data = await response.json();
    return data;
};


// Cancel Shopify Subscription
export const fetchCancelRecurringCharge = async (transaction, jwt) => {        
  const url = `${REACT_APP_ES_API_BILLING}/billing/recurring_charge`;
  const options = fetchOptions('DELETE', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Cancel Recurring Charge`) };
  const data = await response.json();
  return data;
};

// // STRIPE START******
// 1a - Create / Start Stripe Subscription
export const fetchCreateStripeCustomer = async (transaction, jwt, test) => {
  const url = `${REACT_APP_STRIPE_API}/customer?test=${test}`;
  const options = fetchOptions('POST', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Create Stripe Customer`) };
  const data = await response.json();
  return data;
};

export const fetchGetStripeCustomer = async (customerId, jwt, test) => {
    const url = `${REACT_APP_STRIPE_API}/customer?customer_id=${customerId}&test=${test}`;
    const options = fetchOptionsNoBody("GET", jwt);
    const response = await fetch(url, options);
    if(!response.ok) { throw new Error(`Could Not Create Stripe Charge`) };
    const data = await response.json();
    return data;
};

export const fetchCreateStripeCard = async (transaction, jwt, test) => {
  const url = `${REACT_APP_STRIPE_API}/session/card?test=${test}`;
  const options = fetchOptions('POST', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Create Stripe Checkout Session`) };
  const data = await response.json();
  return data;
};

export const fetchCreateStripeCheckoutSession = async (transaction, jwt, test) => {
  const url = `${REACT_APP_STRIPE_API}/session?test=${test}`;
  const options = fetchOptions('POST', transaction, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Create Stripe Checkout Session`) };
  const data = await response.json();
  return data;
};

export const fetchCancelSubscriptionStripe = async (subscriptionId, storeField, transaction, jwt, test) => {
    const url = `${REACT_APP_STRIPE_API}/subscription?stripe_subscription_id=${subscriptionId}&${storeField}&test=${test}`;
    const options = fetchOptions('DELETE', transaction, jwt);
    const response = await fetch(url, options);
    if(!response.ok) { throw new Error(`Could Not Delete Stripe Subscription`) };
    const data = await response.json();
    return data;
};

// One Time Usage Charges
// GET Grab the invoices (stripe & shopify)
export const fetchInvoiceHistoryData = async (store, jwt, integrationID, test) => {
  let url;
  let cleanId;
  
  switch(integrationID){
    case 1: // Shopify
      cleanId = store.store_id.split('_')[1]
      url = `${REACT_APP_INVOICE_API}/charge/get?store_id=${cleanId}`;
      break;
    case 2: case 3: // Stripe
      url = `${REACT_APP_STRIPE_API}/invoices/?customer_id=${store.stripe_billing.stripe_customer_id}&test=${test}`;
      break;
    default:
      break;
  };

  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response) { throw new Error(`Could Not Grab One Time Charges for ${store.id} fetchInvoiceData`) };
  const data = await response.json();
  return data;
};

// POST Setup a one time usage charge
export const fetchSetupInvoice = async (optionsData, jwt, integrationId) => {
  let url;
  let options;
  switch(integrationId) {
    case 1:
      url = `${REACT_APP_INVOICE_API}/charge/setup`;
      options = noAuthOptions('POST', optionsData);
      break;
    case 2: case 3:
      url = `${REACT_APP_STRIPE_API}/charges/setup`;
      options = fetchOptions('POST', optionsData, jwt);
      break;
    default:
      break;
  };
  const response = await fetch(url, options);
  if(!response) { throw new Error("Could not setup charge fetchSetupInvoice") };
  const data = await response.json();
  return data
};

// POST Create a one time usage charge Shopify
// POST Create a One Time Charge Stipe
export const fetchCreateInvoice = async (optionsData, jwt, integrationID, test) => {
  let url;
  let options;
  switch(integrationID){
    case 1: // Shopify
      url = `${REACT_APP_INVOICE_API}/charge/create`;
      options = noAuthOptions('POST', optionsData);
      break;
    case 2: case 3: // Stripe
      url = `${REACT_APP_STRIPE_API}/charges/create?test=${test}`;
      options = fetchOptions('POST', optionsData, jwt);
      break;
    default:
      break;
  };

  const response = await fetch(url, options);
  if(!response) { throw new Error("Could not updat charge status fetchCreateInvoice") };
  const data = await response.json();
  return data;
};

// POST Activate an invoice Shopify
// POST Create an invoice Stripe
export const fetchActivateInvoice = async (optionsData, jwt, integrationID, test) => {
  let url;
  let options;
  switch(integrationID){
    case 1: // Shopify
      url = `${REACT_APP_INVOICE_API}/charge/process?test=${test}`;
      console.log("process url", url)
      options = noAuthOptions('POST', optionsData);
      break;
    case 2: case 3: // Stripe
      url = `${REACT_APP_STRIPE_API}/charges/create?test=${test}`;
      options = fetchOptions('POST', optionsData, jwt);
      break;
    default:
      break;
  };

  const response = await fetch(url, options);
  if(!response) { throw new Error("Could not activate that charge fetchActivateInvoice") };
  const data = await response.json();
  return data;
};

// POST Update an invoice shopify (This is usually autonmatic)
// POST Update a stripe status (pending, active, expired) ,receipt_url, stripe_charge_id
export const fetchUpdateInvoice = async (optionsData, jwt, integrationID, test) => {
  let url;
  let options;
  switch(integrationID){
    case 1: // Shopify
      url = `${REACT_APP_INVOICE_API}/charge/update`;
      options = noAuthOptions('POST', optionsData);
      break;
    case 2: case 3: // Stripe
      url = `${REACT_APP_STRIPE_API}/charges/status?test=${test}`;
      options = fetchOptions('POST', optionsData, jwt);
      break;
    default:
      break;
  };

  const response = await fetch(url, options);
  if(!response) { throw new Error("Could not updat charge status fetchUpdateInvoice") };
  const data = await response.json();
  return data;
};

export const fetchUsageChargeHistoryData = async (bodyData, jwt, integrationID) => {
  let url;
  let options;
  switch(integrationID){
    case 1: // Shopify
      url =  `${REACT_APP_ES_API_BILLING}/billing/receipts?store_id=${bodyData.store_id}`
      options = fetchOptionsNoBody('GET', jwt);
      break;
    case 2: case 3: // Stripe
      url = `${REACT_APP_STRIPE_API}/subscription/list?customer_id=${bodyData.customer_id}&${bodyData.store_param}&test=${bodyData.test}`;
      options = fetchOptionsNoBody('GET', jwt);
      break;
    default:
      break;
  };

  const response = await fetch(url, options);
  if(!response) { throw new Error("Could not find any usage charge data for that store") };
  const data = await response.json();
  return data;
};

// Hook to post subscription to SLACK
export const sendSubscriptionStart = async (storeUrl) => {
  const message = `${storeUrl} has STARTED a subscription`;
  const url = "https://hooks.slack.com/services/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";
  const options = {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: JSON.stringify({ text: message })
  };
  await fetch(url, options);
};


export const fetchSendSlackProjectPaid = async (message) => {
  const url = "https://hooks.slack.com/services/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";
  const options = {
    method: "POST",
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: JSON.stringify({ text: message })
  };
  await fetch(url, options);
};

// Transactions & Subscriptions #END******************************************************************
// ***************************************************************************************************


// ***************************************************************************************************
// PROJECTS & LOADOUTS #START*************************************************************************

// Get all projects for all store's (admin only)
export const fetchEveryProject = async (jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/projects`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data.search_response;
};

// Get all projects for a given store_id
export const fetchAllProjects = async (storeID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/project/store?store_id=${storeID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Get all projects & loadouts & count of loadouts for a given project_id
export const fetchProjectLoadouts = async (projectID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/project/loadouts?project_id=${projectID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Create a new project
export const fetchCreateProject = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/project/`;
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Update a project
export const fetchUpdateProject = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/project/`;
  const options = fetchOptions('PUT', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Delete a project
export const fetchDeleteProject = async (projectID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/project/`;
  const optionsData = { "project_id": projectID };
  const options = fetchOptions('DELETE', optionsData, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET a loadout by ID
export const fetchLoadout = async (loadoutID, jwt) => {
  let url;
  // Admin dashboard all loadouts table
  if(!loadoutID) url = `${REACT_APP_PROJECTS_API}/loadout/`;  
  // Step 3 edit loadout dialog
  else url = `${REACT_APP_PROJECTS_API}/loadout/?loadout_id=${loadoutID}`

  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET a loadout by ID
export const fetchLoadoutSelfService = async (storeId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/?store_id=${storeId}&self_service=true`; 
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};


// Create a new loadout
export const fetchCreateLoadout = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/`;
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Update a loadout
export const fetchUpdateLoadout = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/`;
  const options = fetchOptions('PUT', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Delete a loadout
export const fetchDeleteLoadout = async (loadoutID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/`;
  const optionsData = { loadout_id: loadoutID };
  const options = fetchOptions('DELETE', optionsData, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchLoadoutFeedback = async (loadoutID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/feedback?loadout_id=${loadoutID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchUpdateLoadoutFeedbackStatus = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/feedback/complete`
  const options = fetchOptions('PUT', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchCreateLoadoutFeedback = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/loadout/feedback`
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};


export const fetchGhostsByStore = async (storeId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghosts?store_id=${storeId}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchGhostsByStoreAndStage = async (storeId, stage, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghosts?store_id=${storeId}&ghost_stage=${stage}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchSingleGhostsByID = async (ghostID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghost?ghost_variant_id=${ghostID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchGhostsByLoadoutID = async (loadoutId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghosts?loadout_id=${loadoutId}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchGhostsByConnectedVariant = async (variantId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghost?ecommerce_variant_id=${variantId}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchCreateGhostVariant = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghost/`
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Check if server is online
export const fetchCheckUSDZselfServiceOnline = async () => {
  const url = `https://sj0l4u127i.execute-api.us-east-1.amazonaws.com/dev/ip/wrapper`;
  
  const payload = {
    ip: '3.215.93.174',
    // ip: '3.81.98.130', // test
    qs: `connect`,
    port: 5004
  };

  const options = fetchOptions('PUT', payload);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Send the server a job 
export const fetchCreateUSDZselfService = async (geo, storeID, storeTitle, bundle) => {
  const url = `https://sj0l4u127i.execute-api.us-east-1.amazonaws.com/dev/ip/wrapper`;
  
  const payload = {
    ip: '3.215.93.174',
    // ip: '3.81.98.130', // test
    qs: `action?geo=${geo}&store_id=${storeID}&store_title=${storeTitle}&bundle=${bundle}`,
    port: 5004
  };

  const options = fetchOptions('PUT', payload);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Check that server has completed job
export const fetchCreateUSDZselfServiceTaskCHECK = async (taskID) => {
  const url = `https://sj0l4u127i.execute-api.us-east-1.amazonaws.com/dev/ip/wrapper`;
  
  const payload = {
    ip: '3.215.93.174',
    // ip: '3.81.98.130', // test
    qs: `status?task_id=${taskID}`,
    port: 5004
  };

  const options = fetchOptions('PUT', payload);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchUpdateGhostVariant = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghost/`
  const options = fetchOptions('PUT', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Approve and create model entry + s3 stuff
export const fetchApproveGhostVariant = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/model/full`;
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchAllGhostsCount = async (jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ghosts/?count_only=true`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchModelsByStore = async (storeId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/models?store_id=${storeId}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET TOTAL MODEL COUNT FOR STORE
export const fetchModelCountByStore = async (storeId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/models?store_id=${storeId}&count_only=true`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// GET TOTAL LIVE (model_status='on') MODEL COUNT FOR STORE
export const fetchModelCountLiveByStore = async (storeId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/models?store_id=${storeId}&count_only=true&live_only=true`;
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchAllModelCount = async (jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/models?count_only=true`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchModelsForLoadout = async (loadoutId, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/models?loadout_id=${loadoutId}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchUpdateModelFields = async (dataToUpdate, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/model/`
  const options = fetchOptions('PUT', dataToUpdate, jwt)
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchDeleteModel = async(payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/model/`
  const options = fetchOptions('DELETE', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// Fetch Single Model By ID
export const fetchModel = async(model3dId) => {
  const url = `${REACT_APP_PROJECTS_API}/model/?model_3d_id=${model3dId}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

// Fetch Single Model By ID
export const fetchCreateModel = async(payload, jwt) => {
  const options = fetchOptions('POST', payload, jwt);
  const url = `${REACT_APP_PROJECTS_API}/model`;
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// PROJECTS & LOADOUTS #END***************************************************************************
// ***************************************************************************************************

// *************************************************************************************************
// Feedback #START**********************************************************************************

// // GET Grab all existing customer feedback for a shop / product /variant
export const fetchFeedback = async (id, jwt, type) => {
  let url = "";
  switch(type){
    case 'variant':
      url = `${REACT_APP_FEEDBACK_API}/feedback/variant?variant_id=${id}`;
      break;
    case 'product':
      url = `${REACT_APP_FEEDBACK_API}/feedback/product?product_id=${id}`;
      break;
    case 'store':
      url = `${REACT_APP_FEEDBACK_API}/feedback/store?store_id=${id}`;
      break;
    default:
      break;
  }

  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Get feedback`) };
  const data = await response.json();
  return data;
};

// // POST Send customer or levAR's feedback to ES for the variant
export const fetchAddVariantFeedback = async (feedbackData, jwt) => {
  const url = `${REACT_APP_FEEDBACK_API}/feedback/variant`;
  const options = fetchOptions('POST', feedbackData, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Post variant feedback`) };
  const data = await response.json();
  return data;
};

// // POST Set feedback as completed
export const fetchUpdateVariantFeedbackStatus = async (feedbackData, jwt) => {
  const url = `${REACT_APP_FEEDBACK_API}/feedback/complete`;
  const options = fetchOptions('POST', feedbackData, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could Not Post update variant feedback status`) };
  const data = await response.json();
  return data;
};

// Feedback #END************************************************************************************
// *************************************************************************************************

// *******************************************************************************************************
// OTHER #START*******************************************************************************************


// POST Creates a flowcode QR for a variant 
// After creation this data is stored in Elastic Search for later user
export const fetchCreateFlowCode = async (payload, jwt) => {
  const url = `${REACT_APP_ES_API}/variant/flowcode`;
  const options = fetchOptionsFlowCode("POST", payload, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could not fetch that flow code link`) };
  const res = await response.json();
  return res;
};

export const fetchCreateSinglePriceForProductAI = async (payload) => {
  // const url = `https://kroggrok.ngrok.io/dev/closestCategory`;
  // const url = `https://levarapp.ngrok.io/dev/closestCategory`
  const url = `https://wyuqv1lple.execute-api.us-east-1.amazonaws.com/dev/closestCategory`;
  const options = fetchOptions("POST", payload);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could not fetch that flow code link`) };
  const res = await response.json();
  return res;
};

export const fetchSendSlackMessage = async (payload, jwt) => {
  const url = `https://c0l9hb8b28.execute-api.us-east-1.amazonaws.com/dev/slack/send_message`;
  const options = fetchOptions("POST", payload, jwt);
  const response = await fetch(url, options);
  if(!response.ok) { throw new Error(`Could not fetch that flow code link`) };
  const res = await response.json();
  return res;
};

export const fetchLiveEC2Instances = async (jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ec2/validate`;
  const options = fetchOptionsNoBody("GET", jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchLaunchEC2Instance = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ec2/launch`;
  const options = fetchOptions("POST", payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchTerminateEC2Instance = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/ec2/delete`;
  const options = fetchOptions("DELETE", payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// OTHER #END******************************************************************************************
// ****************************************************************************************************


// *******************************************************************************************************
// MICRO SITE #START*******************************************************************************************
/**
 * POST - `${REACT_APP_PROJECTS_API}/microsite`
 * PUT - `${REACT_APP_PROJECTS_API}/microsite/`
 * DELETE - `${REACT_APP_PROJECTS_API}/microsite`
 * {
 *  "microsite_id": "e6cfadd1-3cd5-4d2b-ba59-1c09010482a9",
 *  "store_id": "shp_50833359001"
 *  }
 * GET Doc - `${REACT_APP_PROJECTS_API}/microsite/?microsite_id=f4259d3e-f2a4-4209-85ec-0624c7ee7e80`
 * GET All - `${REACT_APP_PROJECTS_API}/microsites/?store_id=shp_50833359001`
 */
export const fetchStoresMicroSites = async (storeID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/microsites/?store_id=${storeID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchMicroSiteByID = async (micrositeID, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/microsite/?microsite_id=${micrositeID}`
  const options = fetchOptionsNoBody('GET', jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchCreateMicroSite = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/microsite/`
  const options = fetchOptions('POST', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchUpdateMicroSite = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/microsite/`
  const options = fetchOptions('PUT', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

export const fetchDeleteMicroSite = async (payload, jwt) => {
  const url = `${REACT_APP_PROJECTS_API}/microsite/`
  const options = fetchOptions('DELETE', payload, jwt);
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
};

// MICRO SITE #END******************************************************************************************
// ****************************************************************************************************


