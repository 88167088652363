import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
// components
// import Scrollbar from '../../../../../../../components/Scrollbar';
// sections
import VariantCard from './VariantCard';

// ----------------------------------------------------------------------

VariantCardRow.propTypes = {
  linkedVariants: PropTypes.array,
  linkedProducts: PropTypes.array,
  productNumber: PropTypes.number,
  onItemSelect: PropTypes.func,
  itemsSelected: PropTypes.array,
  dataReady: PropTypes.bool,
  onSelectAllItems: PropTypes.func,
};

export default function VariantCardRow({
  linkedVariants, // Individual Var of Prod array
  linkedProducts, // Product Template
  productNumber, // which product array
  onItemSelect, 
  itemsSelected, // All Images Array of Selected URL
  dataReady, // Status of Fetch Call
  onSelectAllItems, // Select All Items
}) {


  const [allImagesSelected, setAllImagesSelected] = useState(false);
  const [allSelectedCount, setAllSelectedCount] = useState(0);

  const includesAll = (arr, target) => target.every(v => arr.includes(v));
  useEffect(() => {
    if(dataReady) {
      const allImages = linkedVariants.map((item) => {

        if(item?.alt_images && item?.alt_images?.length !== 0) {
          return [item.image_url, ...item.alt_images]
        } 
        if(item?.image_url){
          return item.image_url
        }
        return null
      });

      const allSelected = includesAll(itemsSelected, allImages);
      setAllImagesSelected(allSelected);
      setAllSelectedCount(allImages.length);
    }
  }, [dataReady, linkedVariants, itemsSelected, productNumber]);

  return (
    <>
    { (dataReady && linkedVariants[0]?.image_url) &&
      <Paper sx={{ p: 2, mb: 4 }} elevation={3}>

        <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
          
          <Typography variant="h5">
            Linked Product #{productNumber}: {dataReady && linkedVariants[0]?.product_title}
          </Typography>
          <Button 
            variant="outlined"
            disabled={!dataReady}
            onClick={() => onSelectAllItems(productNumber - 1)}
          >
            { allImagesSelected ? `Deselect ${allSelectedCount}` : 'Select All' }

          </Button>
        </Stack>
        <Box
          sx={{
            // display: 'flex',
            display: 'grid',
            width: '100%',
            // maxHeight: 200,
            overflow: 'auto',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
              lg: 'repeat(5, 1fr)',
            },
          }}
        >

          {( !dataReady ? [...Array(linkedProducts.length)] : linkedVariants).map((item, index) => {
            if(item?.image_url){
              return (
                <Stack direction="row" spacing={1} key={`variant_card_div_${index}`}>
                <VariantCard 
                  key={index} 
                  image={item.image_url} 
                  onItemSelect={onItemSelect} 
                  itemsSelected={itemsSelected} 
                /> 
                {( item?.alt_images && item?.alt_images?.length !== 0) && item.alt_images.map((altItem, altIndex) => 
                  <VariantCard 
                    key={`alt_card_${altIndex}`} 
                    image={altItem} 
                    onItemSelect={onItemSelect} 
                    itemsSelected={itemsSelected} 
                  /> 
                )}
              </Stack>
              )
            } 
            return(
              <Skeleton 
                key={`skeleton_${index}`} 
                variant="rectangular" 
                sx={{ width: 175, height: 175 }} 
              />
            )
          }
          )}
        </Box>
      </Paper>
    }
   </>
  );
}
