import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
// component
import {
  RHFUploadMultiFile,
} from '../hook-form';

// ----------------------------- Styles --------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

const BlockCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  background: '#fefefef5',
  position: 'absolute',
  top: 0,
  left: 0,
  marginLeft: '16px',
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: borderActive ? `solid 2px ${theme.palette.gold.main}` : 'none',
  padding: 24
}));

const MainCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({
    padding: 16, 
    width: '100%', 
    border: borderActive ? `solid 2px ${theme.palette.gold.light}` : 'solid 2px #ffffff00',
}));

// ------------------------------------------------------------------------

FormImageUploadCard.propTypes = {
  ready: PropTypes.bool,
  methods: PropTypes.any,
  handleRemove: PropTypes.func,
  handleDrop: PropTypes.func,
  openVariantImageDrawer: PropTypes.func,
  blockedUI: PropTypes.bool,
  setBlockedUI: PropTypes.func,
};

export default function FormImageUploadCard({
  ready,
  methods,
  handleRemove,
  handleDrop,
  openVariantImageDrawer,
  blockedUI,
  setBlockedUI
}) {

  // Form
  const { watch } = methods;

  const values = watch();
  

  if(!ready) {
    return (
      <Skeleton
        sx={{ bgcolor: 'grey.200', borderRadius: 1, margin: '0 auto' }}
        variant="rectangular"
        height={208}
        animation="wave"
        width={'100%'}
      />
    )
  }

  return (
    <>
      <MainCardStyle borderActive={values.additional_product_photos.length === 0}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <LabelStyle>
            Images & Files
          </LabelStyle>
          { values.loadout_type === 1 && 
          <Button 
            size="small" 
            color="primary" 
            variant="outlined"
            onClick={() => openVariantImageDrawer()}
          >
            Select From Store
          </Button>
          }
        </Stack>
        
        <RHFUploadMultiFile
          showPreview
          name="additional_product_photos"
          buttonsActive={false}
          maxSize={200000000} // 200 MB
          onDrop={handleDrop}
          onRemove={handleRemove}
          acceptedFileTypes={{
            'image/*': [], 
            'text/*': [],
            'video/*': [],
            'application/octet-stream': ['.obj','.fbx','.stl'], 
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc', '.dot'],
            'application/rtf': ['.rtf'],
            'application/x-3ds': ['.3ds'],
            'model/gltf-binary': ['.glb','.gltf'],
            'model/vnd.usdz+zip': ['.usdz'],
          }}
          onUpload={() => console.log('ON UPLOAD')}
        />
      </MainCardStyle>

      { (blockedUI && values.additional_product_photos.length === 0) &&
      <BlockCardStyle borderActive={!(values.product_details.length <= values.minValueProductDetails)}>
        
        <CardContent sx={{ 
          maxWidth: 500, 
          padding: 0, 
          mb: 2, 
          color: values.product_details.length <= values.minValueProductDetails ? '#aeaeae' : 'inherit' 
        }}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Step 2:
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Upload Product Photos
          </Typography>
          <Typography variant="body1">
            Your Loadout needs photos, select from product or upload your own
          </Typography>
        </CardContent>

        <CardActions sx={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            size="small" 
            color="primary" 
            variant="outlined"
            onClick={() => setBlockedUI()}
            disabled={values.product_details.length <= values.minValueProductDetails}
          >
            Upload Your Own Images
          </Button>
          { values.loadout_type === 1 && 
          <Button 
            size="small" 
            color="primary" 
            variant="contained"
            onClick={() => openVariantImageDrawer()}
            disabled={values.product_details.length <= values.minValueProductDetails}
          >
            Select From Store
          </Button>
          }
        </CardActions>

      </BlockCardStyle>
      }
    </>
  );
}