// api
import { 
  fetchShopifyToken,
  fetchUsageChargeHistoryData,
  fetchInvoiceHistoryData,
  fetchActivateInvoice,
  fetchUpdateInvoice,
  // fetchCreateInvoice,
  fetchSetupInvoice,
  // fetchGetStripeCustomer,
} from '../../utils/fetchData';
// Actions
import { 
  getStripeSuccess,
  hasError 
} from '../slices/account';
import { getMainLoading } from '../slices/loading';
// helpers
import { getJWTstorage } from '../../utils/jwt';
import { fDateTimeUnix } from '../../utils/formatTime';
import { delay } from "../../utils/levarHelpers";
import { getComparator } from '../../hooks/useTable';
// import { PATH_DASHBOARD } from '../../routes/paths';

const { REACT_APP_SHOPIFY_TEST } = process.env;

// ----------------------------------------------------------------------

// TODO: remove all eslint bullshit and throw this stuff into redux

// **************************************************************************
// WHERE: used in the account billing section of the user dashboard (shopify only)
// DESCRIPTION: Grabs all usage charges (subscriptions)
// QUEREY: shop_name, shopify_access_token, recurring_application_charge_id
// **************************************************************************
export function setUsageChargeHistory() {
  return async(dispatch, getState) => {
    try {
      const { account: { store, user, integration }, admin} = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      if(!store?.store_id) return

      const { shopify_access_token: shpToken } = await fetchShopifyToken(store.id, jwt);

      const bodyData = {
        shop_name: store.store_domain,
        shopify_access_token: shpToken,
        recurring_application_charge_id: store?.shopify_billing?.recurring_application_charge_id,
        store_id: store.store_id
        // test: REACT_APP_SHOPIFY_TEST
      };

      // NOTE: this is temporary
      const chargeHistory = await fetchUsageChargeHistoryData(bodyData, jwt, integration.integration_id);

      const comparator = getComparator("desc", "created_at");

      let stabilizedUsageCharges;

      if(integration.integration_id === 1 && chargeHistory?.response_sources) {
        stabilizedUsageCharges = chargeHistory.response_sources.map((el, index) => [el.usage_charge, index]);
        stabilizedUsageCharges.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        // Remap into readable by table
        stabilizedUsageCharges = stabilizedUsageCharges.map((el) => el[0]);
        // eslint-disable-next-line consistent-return
        return stabilizedUsageCharges;
      }
      if(integration.integration_id !== 1 && chargeHistory?.response?.usage_charges) {
        stabilizedUsageCharges = chargeHistory.response.usage_charges.map((el, index) => [el, index]);
        stabilizedUsageCharges.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        // Remap into readable by table
        stabilizedUsageCharges = stabilizedUsageCharges.map((el) => el[0]);
        // eslint-disable-next-line consistent-return
        return stabilizedUsageCharges;
      }


      // eslint-disable-next-line consistent-return
      return [];

    } catch(error) {
      console.log('setUsageChargeHistory error', error)
      dispatch(hasError(error));
    }
  }
};

// Broke this off into its own thunk. The combined thunk was really messy
export function setStripeInvoicesAndSubscriptions() {
  return async (dispatch, getState) => {
    try {
      const { account: { store, user, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      if(!store?.store_id) return;

      const chargeHistory = await fetchInvoiceHistoryData(store, jwt, integration.integration_id, false);
      const comparator = getComparator("desc", "sort_created_at");

      let stabilizedCharges = [];
      let stabalizedSubscriptions = [];

      if(chargeHistory.invoice_list) {
        chargeHistory.invoice_list.data.forEach((item) => {

          if(item.amount_due === 0 || item.status === 'void') return
          
          item.lines.data.forEach((lineItem) => {
            if((lineItem?.period.end !== lineItem?.period.start) && lineItem.subscription !== null) {
              stabalizedSubscriptions.push({
                id: lineItem.unique_id,
                billing_on: fDateTimeUnix(item.due_date),
                created_at: fDateTimeUnix(item.created),
                sort_created_at: item.created,
                plan: lineItem.amount / 100,
                price: lineItem.amount / 100,
                charge_name: lineItem.description,
                description: lineItem.description,
                receipt_url: item.hosted_invoice_url
              })
            } else {
              stabilizedCharges.push({
                id: lineItem.unique_id,
                charge_id: lineItem.id,
                created_at: fDateTimeUnix(item.created),
                sort_created_at: lineItem.created,
                charge_name: lineItem.description,
                description: lineItem.description,
                price: lineItem.amount / 100,
                amount: lineItem.amount / 100,
                receipt_url: item.invoice_pdf,
                confirmation_url: item.hosted_invoice_url,
                status: item.paid ? 'active' : item.status
              })
            }
          })
        });

        if(stabilizedCharges.length > 1) {
          // Sort them
          stabilizedCharges = stabilizedCharges.map((el, index) => [el, index]);
          stabilizedCharges.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          stabilizedCharges = stabilizedCharges.map((el) => el[0]);
        }
  
        if(stabalizedSubscriptions.length > 1) {
          // Sort them
          stabalizedSubscriptions = stabalizedSubscriptions.map((el, index) => [el, index]);
          stabalizedSubscriptions.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          stabalizedSubscriptions = stabalizedSubscriptions.map((el) => el[0]);
          dispatch(getStripeSuccess({
            stripe_customer_id: store?.stripe_billing?.stripe_customer_id,
            model_limit: store?.model_limit,
            fixed_price: stabalizedSubscriptions[0].price,
            billing_on: stabalizedSubscriptions[0].billing_on,
            stripe_card_status: true,
          }))
        }
      }

      // eslint-disable-next-line consistent-return
      return { charges: stabilizedCharges, subscriptions: stabalizedSubscriptions};

    } catch(error) {
      console.log('setStripeInvoicesAndSubscriptions error', error)
      dispatch(hasError(error));
    }
  }
}


// **************************************************************************
// WHERE: used in the account billing section of the user dashboard (shopify only)
// DESCRIPTION: Grabs all invoices (one-time-charges)
// QUEREY: store_id
// **************************************************************************
export function setInvoiceHistory() {
  return async (dispatch, getState) => {
    try {
      const { account: { store, user, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      if(!store?.store_id) return;

      const chargeHistory = await fetchInvoiceHistoryData(store, jwt, integration.integration_id, false);
      const comparator = getComparator("desc", "created_at");
      
      let stabilizedCharges;
      // NOTE: Check length (the comparator will add an extra empty if its length 1)
      if(chargeHistory?.one_time_charges && chargeHistory.one_time_charges.length > 1) {
        stabilizedCharges = chargeHistory.one_time_charges.map((el, index) => [el, index]);
      } else {
        stabilizedCharges = chargeHistory.one_time_charges
      }

      // TODO: come back to this and split it up a different way
      if(stabilizedCharges?.length > 1) {
        // Sort them
        stabilizedCharges.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        stabilizedCharges = stabilizedCharges.map((el) => el[0]);
      }
      // eslint-disable-next-line consistent-return
      return stabilizedCharges;

    } catch(error) {
      console.log('setInvoiceHistory error', error)
      dispatch(hasError(error));
    }
  }
};


// **************************************************************************
// WHERE: used in the account billing section of the user dashboard (shopify only)
// DESCRIPTION: Creates a shopify invoice then returns the url to direct them to in order to pay
// INPUT: invoice(the entrie single invoice object that neeeds to be activated)
// **************************************************************************
export function setActivateAndPayInvoice({ invoice }) {
  return async (dispatch, getState) => {
    try {
      dispatch(getMainLoading({ status: true, text: "Redirecting For Payment..." }));
      const { account: { store, user, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      let data;
      switch(integration.integration_id) {
        case 1:
          data = {
            price: invoice.price,
            charge_name: invoice.charge_name,
            return_url: `${window.location.href}?invoice=true`,
            shopify_url: store.store_url,
            charge_uuid: invoice.charge_uuid,
            store_id: store.store_id,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true
          };
          break;
        case 2: case 3:
          data = {
            customer_id: invoice.customer_id,
            amount: invoice.price * 100,
            currency: "usd",
            description: invoice.charge_name,
            receipt_email: invoice.receipt_email,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true
          };
          break;
        default:
          break;
      };
      const activatedInvoice = await fetchActivateInvoice(data, jwt, integration.integration_id, REACT_APP_SHOPIFY_TEST);
      
      if(integration.integration_id !== 1 && activatedInvoice.charge.paid) {
        const body = { 
          charge_uuid: invoice.charge_uuid, 
          stripe_charge_id: activatedInvoice.charge.id,
          receipt_url: activatedInvoice.charge.receipt_url,
          status: activatedInvoice.charge.paid && 'active'
        };
        await fetchUpdateInvoice(body, jwt, integration.integration_id, REACT_APP_SHOPIFY_TEST);
      }

      await delay(4000);
      return activatedInvoice;

    } catch(error) {
      console.log("setActivateAndPayInvoice", error);
      dispatch(hasError(error));
    } finally {
      dispatch(getMainLoading({ status: false, text: "" }));
    }
  }
};

// **************************************************************************
// WHERE: Loadout Table (project info card) [admin]
// DESCRIPTION: Setup an invoice in ES (does not create actual charge until the customer clicks it)
// INPUT: price, title, status(paid, pending etc...)
// **************************************************************************
export function setSetupInvoice({ price, title, status }) {
  return async (dispatch, getState) => {
    
    try {
      const { account: { store, user, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      let chargeBody;

      switch(integration.integration_id) {
        case 1:
          chargeBody = {
            price,
            charge_name: title,
            shopify_url: store.store_domain,
            store_id: store.store_id,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true,
            ...( status && { status: 'ACTIVE' }),
          };
          break;
        case 2: case 3:
          chargeBody = {
            customer_id: store.stripe_customer_id,
            amount: price * 100,
            currency: "usd",
            description: title,
            receipt_email: user.email,
            store_id: store.store_id,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true,
            ...( status && { status: 'active' }),
          };
          break;
        default:
          break;
      };
      
      const chargeResponse = await fetchSetupInvoice(chargeBody, jwt, integration.integration_id);
      let createdChargeID;

      if(integration.integration_id === 1) {
        // Shopify
        createdChargeID = chargeResponse.confirmation.charge_id;
      } else {
        // Stripe
        createdChargeID = chargeResponse.index_response._id
      }
      
      return createdChargeID;

    } catch(error) {
      console.log("setSetupInvoice", error);
      dispatch(hasError(error));
    } finally {
      dispatch(getMainLoading({ status: false, text: "" }));
    }
  }
};


// **************************************************************************
// WHERE: Loadout Table (project info card)
// DESCRIPTION: Grabs all invoices and creates an outstanding and paid balance 
// INPUT: invoiceIds - list of invoice IDs
// **************************************************************************
export function setProjectInvoiceInfo({ invoiceIds }) {
  return async (dispatch, getState) => {
    try {
      const { account: { store, user, integration }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const chargeHistory = await fetchInvoiceHistoryData(store, jwt, integration.integration_id, REACT_APP_SHOPIFY_TEST);

      const infoObj = {
        paidBalance: 0,
        paidCount: 0,
        outstandingBalance: 0,
        outstandingCount: 0,
        allInvoicesPaid: false,
      };

      // Filter already paid charges and calculate price 
      switch(integration.integration_id) {
        case 1:
          chargeHistory?.one_time_charges.filter((fetchedCharge) => {
            // NOTE: always going to be charge UUID since we dont update the invoice_list when its paid
            const foundChargeUUID = invoiceIds.includes(fetchedCharge.charge_uuid);

            if(foundChargeUUID && fetchedCharge?.status === 'pending') {
              infoObj.outstandingCount += 1;
              infoObj.outstandingBalance += parseInt(fetchedCharge.price, 10);

            } else if(foundChargeUUID && fetchedCharge?.status === 'ACTIVE') {
              infoObj.paidCount += 1;
              infoObj.paidBalance += parseInt(fetchedCharge.price, 10);
            }
            return null;
          });
          break;
        case 2: case 3: 
          chargeHistory?.one_time_charges.filter((fetchedCharge) => {
            const foundCharge = invoiceIds.includes(fetchedCharge.charge_uuid);

            if(foundCharge === true && fetchedCharge?.status === 'pending') {
              infoObj.outstandingCount += 1;
              infoObj.outstandingBalance += parseInt(fetchedCharge.amount / 100, 10);

            } else if(foundCharge === true && fetchedCharge?.status === 'active') {
              infoObj.paidCount += 1;
              infoObj.paidBalance += parseInt(fetchedCharge.amount / 100, 10);
            }
            return null;
          });
          break;
        default:
          break;
      };

      if(infoObj.outstandingCount === 0 && infoObj.paidCount > 0) {
        infoObj.allInvoicesPaid = true;
      }
      
      return infoObj;

    } catch(error) {
      console.log("setProjectInvoiceInfo", error);
      dispatch(hasError(error));
    } finally {
      dispatch(getMainLoading({ status: false, text: "" }));
    }
  }
};




// **************************************************************************
// WHERE: CURRENTLY NOT USING
// DESCRIPTION: 
// INPUT: 
// **************************************************************************
// export function setCreateInvoice({ price, title }) {
//   return async (dispatch, getState) => {
//     try {
//       const { account: { store, user, integration }, admin } = getState();
//       const jwt = getJWTstorage(admin, user.uuid);

//       let chargeBody;
      
//       switch(integration.integration_id) {
//         case 1:
//           chargeBody = {
//             price,
//             charge_name: title,
//             shopify_url: store.store_domain,
//             test: REACT_APP_SHOPIFY_TEST === 'true' && true,
//           };

//           break;
//         case 2: case 3:
//           chargeBody = {
//             customer_id: store.stripe_customer_id,
//             amount: price * 100,
//             currency: "usd",
//             description: title,
//             receipt_email: user.email,
//             store_id: store.store_id,
//             test: REACT_APP_SHOPIFY_TEST === 'true' && true,
//           };
//           break;
//         default:
//           break;
//       };

//       const chargeResponse = await fetchCreateInvoice(chargeBody, jwt, integration.integration_id, REACT_APP_SHOPIFY_TEST);
//       return chargeResponse;

//     } catch(error) {
//       console.log("setCreateInvoice", error);
//       dispatch(hasError(error));
//     } finally {
//       dispatch(getMainLoading({ status: false, text: "" }));
//     }
//   }
// };