import { 
  fetchStore,
  fetchUpdateStoreFields, // 2023
} from '../../utils/fetchData';
// Actions
import { 
  getStoreUpdate,
} from '../slices/account';
// helpers
import { getJWTstorage } from '../../utils/jwt'
// import { 
//   IMPLEMENTATION_TEMPLATE
// } from '../../utils/levar_data';


// **************************************************************************
// Connects Updates implementation on levar_ecommerce_stores
// Input: 
//    implementationDataToUpdate => implementation data, 
// **************************************************************************
export function setUpdateEcommStoreImplementation({ 
  implementationDataToUpdate 
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      if (implementationDataToUpdate.asset_theme === true) {
        implementationDataToUpdate.asset_theme = 'levar_player';
      } else if (implementationDataToUpdate.asset_theme === false) {
        implementationDataToUpdate.asset_theme = '';
      }

      // Stripping out ar_experience_type from saving implementation
      // eslint-disable-next-line camelcase
      const { ar_experience_type, ...implementationDataToKeep } = implementationDataToUpdate;
      console.log('ar_experience_type', ar_experience_type)

      const dataToUpdate = {
        store_id: store.store_id,
        implementations: {
          ...implementationDataToKeep
        }
      };
      await fetchUpdateStoreFields(dataToUpdate, jwt, admin.adminStatus);

      const updatedStore = await fetchStore(store.store_id, jwt, false);
      dispatch(getStoreUpdate(updatedStore.search_response));
      
    } catch(error) {
      console.log('error on setUpdateEcommStoreImplementation thunk', error);
    }
  }
};


export function setUpdateEcommStoreFields({ fieldsToUpdate, storeID }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const storeIdentifier = storeID ?? store.store_id
      const dataToUpdate = {
        store_id: storeIdentifier,
        ...(fieldsToUpdate.billing_on && { shopify_billing_on: fieldsToUpdate.billing_on.toISOString() }),
        ...fieldsToUpdate
      };

      await fetchUpdateStoreFields(dataToUpdate, jwt, admin.adminStatus);

      // UPDATE STORE REDUX
      const updatedStore = await fetchStore(storeIdentifier, jwt, false);

      if(storeID) return // When in admin dashboard dont update redux
      dispatch(getStoreUpdate(updatedStore.search_response));
      
    } catch(error) {
      console.log('error on setUpdateEcommStoreFields thunk', error);
    }
  }
};
