import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
// Redux
import { useSelector, useDispatch } from '../../redux/store';
import { getDialog } from '../../redux/slices/dialog'
// Hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// Config
import { HEADER, NAVBAR } from '../../config';
// Layout Components
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
// Components
import { SectionLoading } from '../../components/SectionLoading';
import { DialogController } from '../../components/dialog';
import { delay } from '../../utils/levarHelpers';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  position: 'relative'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  
  const dispatch = useDispatch();

  const { dialogStatus, dialogType, dialogData } = useSelector((state) => state.dialog);
  const { user: { setup_wizard_state: userSetupWizardState } } = useSelector((state) => state.account)
  const { checkout } = useSelector((state) => state.cart)
  const AccountStore = useSelector((state) => state.account.store);
  const { adminStatus } = useSelector((state) => state.admin);

  const shopType = sessionStorage.getItem('shopType')

  // console.log('ReduxStore.store_id', AccountStore)

  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  // ----------------------
  // Close Dialog Status in Redux
  // ----------------------
  const dialogClose = () => {
    dispatch(getDialog({ dialogStatus: !dialogStatus, dialogType:'', dialogData: '' }));
  };

  const openCart = () => {
    dispatch(getDialog({ dialogStatus: true, dialogType:'cartCheckoutDialog', dialogData: '' }));
  }

  // console.log('AccountStore.store_id', AccountStore.store_id)
  // console.log('adminStatus', adminStatus)
  useEffect(() => {
    const handleDialog = async () => {
      if (shopType === 'mfr') {
        await delay(2000);
        dispatch(getDialog({ dialogStatus: true, dialogType: 'productCSVUploadDialog', dialogData: '' }));
      } 
      // else if(!AccountStore.store_id && !adminStatus) {
      //   await delay(1000);
      //   console.log('HERERE')
      //   dispatch(getDialog({ dialogStatus: true, dialogType: 'customStoreCreationDialog', dialogData: '' }));
      // }
    };

    handleDialog();
  }, [shopType, dispatch, AccountStore]);


  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {/* Main Loading Screen */}
      <SectionLoading />

      {/* Top Right Account */}
      <DashboardHeader 
        isCollapse={isCollapse}
        usersetupwizardstate={userSetupWizardState}
        onOpenSidebar={() => setOpen(true)} 
        openCart={openCart}
        cartTotal={checkout.cart.length}
      />

      {/* Left Navigation */}
      <NavbarVertical 
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)} 
      />
      
      {/* Main Content */}
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>

      {/* Main Modal */}
      <DialogController 
        open={dialogStatus} 
        onClose={dialogClose} 
        dialogType={dialogType}
        dialogData={dialogData}
      />

    </Box>
  );
}
