import PropTypes from 'prop-types';
import * as Yup from 'yup';
// Form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../Iconify';
// Form Componenets
import {
  FormProvider,
  RHFTextField,
} from '../../hook-form';

// ----------------------------------------------------------------------

ConfirmForm.propTypes = {
  onBack: PropTypes.func,
  onHandleSubmitForm: PropTypes.func,
};

export default function ConfirmForm({ onBack, onHandleSubmitForm }) {
  
  const ProjectFormSchema = Yup.object().shape({
    project_type: Yup.string().notRequired(),
    project_title: Yup.string().required('Project Title is required'),
    project_description: Yup.string().required('Project Description is required'),
    contact_name: Yup.string().required('Project Owner Name is required'),
    contact_email: Yup.string().required('Project Owner Email is required'),
  });

  const defaultValues = {
    project_type: 'create',
    project_title: '',
    project_description: '',
    contact_name: '',
    contact_email: ''
  };

  const methods = useForm({
    resolver: yupResolver(ProjectFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  
  const values = watch();

  const onSubmit = async () => {
    await onHandleSubmitForm(values)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3, minWidth: 480, mx: 'auto' }}>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} align="center" gutterBottom>
            New Project Info
          </Typography>
          <RHFTextField
            placeholder="Name this Project"
            name="project_title" 
            label="Project Title" 
          />
          <RHFTextField
            placeholder="Project types, scope, timeline, etc"
            name="project_description" 
            label="Project Description" 
            multiline
            rows={3}
          />
          <RHFTextField
            name="contact_name"
            label="Project Owner Name"
            placeholder="First and Last Name"
          />
          <RHFTextField 
            name="contact_email" 
            label="Project Owner Email" 
            placeholder="name@example.com"
          />
        </Stack>

        <Stack direction="row" sx={{ mt: 4}}>
          {/* <Button 
            variant="outlined"
            onClick={() => onBack()}
          >
            Back
          </Button> */}

          <div style={{flex: '1 0 0'}} />

          <LoadingButton 
            variant="contained" 
            type="submit"
            endIcon={<Iconify icon={'material-symbols:arrow-forward-rounded'} width={20} height={20} />}
            loading={isSubmitting}
          >
            Create Project
          </LoadingButton>
        </Stack>

      </Box>
    </FormProvider>
  );
}