import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DialogAnimate } from '../animate';
import { fDateLevar, fDateTimeLevar } from '../../utils/formatTime';

ProratedSubscriptionDialog.propTypes = {
  dialogData: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

// Used for making the customer accept the prorated usage charge balance 
export default function ProratedSubscriptionDialog({ dialogData, onClose, open }) {

  return (
    <DialogAnimate open={open} fullWidth={false} onClose={() => onClose('rejected')}>
      <Box sx={{ p: 2, height: 400, margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Card sx={{ p: 3 }}>
          <Stack direction="column" alignItems="center" justifyContent="space-evenly" spacing={3}>
            <Typography variant="h3">Attention</Typography>
            <Typography variant="subtitle1">
              You are updating your subscription.
            </Typography>
            <Typography variant="subtitle1">
              A prorated cost of ${dialogData?.proratedCharge} will be charged today
            </Typography>
            <Typography variant="subtitle1">
              Your will be billed ${dialogData?.fixedPrice} on the {dialogData?.billingOn && fDateTimeLevar(dialogData?.billingOn)}
            </Typography>
          </Stack>
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-evenly">
            <Button variant="outlined" onClick={() => onClose('rejected')} size="large">
              Decline
            </Button>
            <Button variant="contained" onClick={() => onClose('accepted')} size="large">
              Accept
            </Button>
          </Stack>
      </Box>
    </DialogAnimate>

  )
};