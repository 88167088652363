export const VerifyStoreUrl = (storeURL, shopType) => {
  let cleanURL;
  let checkURL;
  let finalURL;
  let status;
  
  const lowerCase = storeURL.toLowerCase();

  switch(shopType) {
      case "bco":
          checkURL = lowerCase.includes(".mybigcommerce.com");
          if(!checkURL){
            finalURL = storeURL
            status = checkURL
          }
          cleanURL = lowerCase.match(/\b(?!(?:https?|ftps?):\/\/)(?!www\.)(?!ww\.)(?!w\.)(?!\.)[\w.-]+(?:\.[\w.-]+)/)
          finalURL = cleanURL ? cleanURL[0] : storeURL
          status = checkURL
          break;
      case "shp":
          checkURL = lowerCase.includes(".myshopify.com");
          if(!checkURL){
            finalURL = storeURL
            status = checkURL
          }
          cleanURL = lowerCase.match(/\b(?!(?:https?|ftps?):\/\/)(?!www\.)(?!ww\.)(?!w\.)(?!\.)[\w.-]+(?:\.[\w.-]+)/)
          finalURL =  cleanURL ? cleanURL[0] : storeURL
          status = checkURL
          break;
      case "custom":
        finalURL = cleanURL[0]
        status = true
        break;
      default:
        break;
  }

  return { finalURL, status };
};