import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// Redux
import { getDialog } from '../../redux/slices/dialog'
import { useSelector, useDispatch } from '../../redux/store';
// components
import AdminDashboardHeader from './header/index';
import AdminNavbar from './navbar';
import { SectionLoading } from '../../components/SectionLoading';
import { DialogController } from '../../components/dialog';
// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function AdminLayout() {
  const dispatch = useDispatch();
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { dialogStatus, dialogType, dialogData } = useSelector((state) => state.dialog);
  const [open, setOpen] = useState(false);

  const dialogClose = () => {
    dispatch(getDialog({ dialogStatus: !dialogStatus, dialogType:'', dialogData: '' }));
  };

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <SectionLoading />

      <AdminDashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <AdminNavbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      
      {/* Dispatched Modal */}
      <DialogController 
        open={dialogStatus} 
        onClose={dialogClose} 
        dialogType={dialogType}
        dialogData={dialogData}
      />
    </Box>
  );
}
