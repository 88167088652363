export const fetchOptions = (type, body, code) => ({
  method: type,
  body: JSON.stringify(body),
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `${code}`
  }
});

export const fetchOptionsNoBody = (type, code) => ({
  method: type,
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `${code}`
  }
});


export const noAuthOptions = (type, body) => ({
  method: type,
  body: JSON.stringify(body),
  headers: {
      'Content-Type': 'application/json'
  }
});

export const fetchOptionsFlowCode = (type, body, code) => ({
  method: type,
  body: JSON.stringify(body),
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `${code}`,
      'Accept': '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Connection': 'keep-alive'
  }
});