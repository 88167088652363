import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { LoadingButton } from '@mui/lab';

import Iconify from '../../Iconify';
import { FormProvider, RHFTextField, RHFMultiSelect, RHFSelect, RHFSwitch } from '../../hook-form';
import { useSelector, useDispatch } from '../../../redux/store';
import { getJWTstorage } from '../../../utils/jwt';
import { setUpdateLoadout, setCreateProject } from '../../../redux/thunks/projectLoadoutsThunks';
import { setUpdateGhostVariant } from '../../../redux/thunks/ghostThunks';

import { fetchGhostsByStoreAndStage, fetchAllProjects } from '../../../utils/fetchData';
import { delay } from "../../../utils/levarHelpers";
import { s3DataCopier } from '../../../utils/s3Connector';
import useAuth from '../../../hooks/useAuth';


GhostReasignment.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
}

const DevArtStoreID = "shp_79393784098"

const mapItemsForMenu = (items, labelKey = 'name', valueKey = 'id', secondValueKey = 'title') => items.map(item => ({
  value: item[valueKey],
  label: item[labelKey],
  secondaryLabel: item[secondValueKey],
}));

const defaultState = {
  active: false,
  loading: false,
  store: null,
  ghosts: [],
  ghostsForMenu: [],
  projects: [],
  loadouts: [],
  projectsForMenu: []
}

export default function GhostReasignment({ onClose, dialogData }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const admin = useSelector((state) => state.admin);

  const [state, setState] = useState(defaultState)

  const defaultValues = {
    createNewProject: false,
    oldStoreID: DevArtStoreID,
    newStoreID: dialogData.store.store_id || "",
    selectedGhosts: [],
    projectToTransferTo: ""
  };

  const GhostReasignmentSchema = Yup.object().shape({
    createNewProject: Yup.boolean(),
    oldStoreID: Yup.string(),
    newStoreID: Yup.string(),
    selectedGhosts: Yup.array().min(1, "At least one ghost is required"),
    projectToTransferTo: Yup.string().when("createNewProject", {
      is: false,
      then: Yup.string().required("Project is required"),
      otherwise: Yup.string()
    }),
  });

  const methods = useForm({
    resolver: yupResolver(GhostReasignmentSchema),
    defaultValues,
  });

  const {
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const values = watch()


  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && state.active) {
      const getResource = async () => {
        const jwt = getJWTstorage(admin, user.sub);
        // NOTE: Fetch all ghosts from the dev art store
        const ghostResponseStage0 = await fetchGhostsByStoreAndStage(DevArtStoreID, 0, jwt);
        const ghostsStage0 = Object.entries(ghostResponseStage0.ghost_search_response_hits_source).map(([key, value]) => ({
          ...value,
          ghost_id: key
        }));

        const ghostResponseStage1 = await fetchGhostsByStoreAndStage(DevArtStoreID, 1, jwt);
        const ghostsStage1 = Object.entries(ghostResponseStage1.ghost_search_response_hits_source).map(([key, value]) => ({
          ...value,
          ghost_id: key
        }));

        let ghosts = [...ghostsStage0, ...ghostsStage1];
        ghosts = ghosts.sort((a, b) => a.ghost_title.localeCompare(b.ghost_title)); // Alphabetically sort ghosts

        // NOTE: Fetch all projects from selected store
        const projectsResponse = await fetchAllProjects(dialogData.store.store_id, jwt);

        setState((prevState) => ({
          ...prevState,
          active: true,
          loading: false,
          store: dialogData.store,
          ghosts,
          ghostsForMenu: mapItemsForMenu(ghosts, 'ghost_client_title', 'ghost_id', 'ghost_stage'),
          projectsForMenu: mapItemsForMenu(projectsResponse.project_search_response, 'project_title', 'project_id', 'updated_at')
        }))
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [state.active, dialogData, admin, user.sub, dispatch]);

  const onResetGhostEcom = () => {
    setValue("selectedGhosts", [])
    setValue("projectToTransferTo", "")
  };

  const handleClose = () => {
    reset();
    setState(defaultState)
    onClose();
  };

  const onSubmitGhostTransfer = async () => {
    try {
      const selectedGhosts = getValues("selectedGhosts")
      const filteredGhosts = state.ghosts.filter((ghost) => selectedGhosts.includes(ghost.ghost_id))
      const allLoadoutIds = [...new Set(filteredGhosts.map(ghost => ghost.loadout_id))]; // Get all loadouts from filtered ghosts
      const allBundleIds = [...new Set(filteredGhosts.map(ghost => ghost.bundle))];

      let projectID = values.projectToTransferTo;
      if(values.createNewProject) {
        // Create new project
        const projectInfo = {
          project_title: `${state.store.store_title}'s First 3D Project`,
          project_description: `${state.store.store_title}'s First 3D Self Service Project`,
          contact_name: `${state.store.levar_email} @ ${state.store.store_url}`,
          contact_email: state.store.levar_email
        }
        projectID = await dispatch(setCreateProject({ projectInfo }));
      }

      await dispatch(setUpdateLoadout({ 
        loadoutID: allLoadoutIds, 
        fieldsToUpdate: {
          store_id: values.newStoreID, // Update store id to selected store
          project_id: projectID // Update project id to selected project or created project
        }
      }));

      // Update ghosts with new store id
      await dispatch(setUpdateGhostVariant({
        ghostID: selectedGhosts,
        fieldsToUpdate: {
          store_id: values.newStoreID,
          ghost_stage: 2
        }
      }));

      // Move s3 data from old store_id to new store_id in s3 
      allBundleIds.forEach(async (bundleId) => {
        await s3DataCopier(`ghosts/${values.oldStoreID}/${bundleId}`, `ghosts/${values.newStoreID}/${bundleId}`, 'ghost-model', false)
      });

      enqueueSnackbar("Transfer Complete!", { variant: 'success' });
    } catch (error) {
      console.error("onSubmit error", error);
    } finally {
      await delay(500);
      handleClose();
    }
  }



  if(!state.active) {
    return (
      <Card sx={{ p: 2, height: 300 }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%' }}>
          <Typography variant="h6">Ghost Reasignment Tool</Typography>
          <Button 
            size="medium"
            variant="contained"
            onClick={() => setState((prev) => ({ ...prev, active: true, loading: true }))}
          >
            Activate
          </Button>
        </Stack>
      </Card>
    )
  }

  if(state.loading) {
    return (
      <Card sx={{ p: 2, height: 300 }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%' }}>
          <Typography variant="h6">Ghost Reasignment Tool</Typography>
          <CircularProgress />
        </Stack>
      </Card>
    )
  }

  return (
    <Card sx={{ p: 2, height: '100%' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitGhostTransfer)} sx={{ height: '100%' }}>

        <Stack spacing={1} direction="column" alignItems="left" sx={{ pb: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Typography variant="h6">Ghost Resignment Tool</Typography>

            <RHFSwitch name="createNewProject" label="Create New Project" size="medium"  color="info" />

            <IconButton size="medium" onClick={handleClose}>
              <Iconify icon={'mdi:close'} />
            </IconButton>
          </Stack>
        </Stack>

        <Divider />

        <Grid container direction="row" spacing={2} sx={{ my: 2 }}>
          <Grid item xs={6} >
            <Stack direction="column" spacing={2}>
              <RHFTextField name="oldStoreID" label="From Dev Art Store ID*" type="text" size="medium"  disabled />
              <RHFTextField name="newStoreID" label="Transfer to Store ID*" type="text" size="medium"  disabled />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="column" spacing={2}>
              <RHFMultiSelect 
                name="selectedGhosts" 
                label="Select Ghosts to Transfer" 
                size="medium" 
                chip
                checkbox
                options={state.ghostsForMenu} 
                sx={{ width: '100%' }}
              />

              <RHFSelect name="projectToTransferTo" size="medium"  label="Project to Transfer To" disabled={values.createNewProject}>
                {state.projectsForMenu.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label} - {option.secondaryLabel}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mt: 3 }}>
          <Button 
            size="medium"
            variant="outlined"
            sx={{ mr: 1, minWidth: 50 }}
            onClick={onResetGhostEcom}
            endIcon={<Iconify icon={'mdi:trash'} />}
            disableElevation
          >
            Clear Selection
          </Button>

          <LoadingButton 
            size="medium"
            variant="contained"
            endIcon={<Iconify icon={'ph:copy'} />}
            type="submit"
            loading={isSubmitting}
            disableElevation
          >
            Copy Ghost
          </LoadingButton>
        </Stack>

      </FormProvider>
    </Card>
  )
}