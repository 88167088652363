// import * as AWS from "@aws-sdk/client-s3";
import { S3Client, ListObjectsV2Command, PutObjectCommand, CopyObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";

const { 
  REACT_APP_S3_KEY, 
  REACT_APP_S3_SECRET, 
  REACT_APP_FEEDBACK_PHOTOS_BUCKET,
  REACT_APP_CSV_BUCKET,
  REACT_APP_VIEWER_SETTINGS_BUCKET,
  REACT_APP_GHOST_MODEL_BUCKET,
} = process.env;

// ----------------------------- Clients -----------------------------------



// ----------------------------- Uploader -----------------------------------

export const s3Connector = async (filesToUpload, bucket, path) => {
  const s3client = new S3Client({ 
    region: "us-east-1",
     credentials: { 
      accessKeyId: REACT_APP_S3_KEY,
      secretAccessKey: REACT_APP_S3_SECRET 
    } 
  });
  let s3Bucket;
  switch(bucket){
    case 'feedback':
      s3Bucket = REACT_APP_FEEDBACK_PHOTOS_BUCKET;
      break;
    case 'csv':
      s3Bucket = REACT_APP_CSV_BUCKET;
      break;
    case 'viewer-settings':
      s3Bucket = REACT_APP_VIEWER_SETTINGS_BUCKET;
      break;
    case 'ghost-model':
      s3Bucket = REACT_APP_GHOST_MODEL_BUCKET;
      break;
    default:
      break;
  };
  
  const s3Links = [];
  console.log("filesToUpload", filesToUpload)
  await Promise.all(
    filesToUpload.map(async (item) => {
      // Should be false in order to upload
      if(typeof item !== 'object') {
        s3Links.push(item)
        return new Error('already uploaded');
      }
      
      // NOTE: The code below was for apple device screenshots that have space in the file name. This seemed to cause more problems than it was worth.
      // const cleanName = item.name.replace(/\s/g, '+');
      // const cleanDirKey = `${path}/${cleanName}`;
      console.log("item", item)
      const dirKey = `${path}/${item.name}`;
      const link = `https://${s3Bucket}.s3.amazonaws.com/${dirKey}`;

      const uploadParams = { Bucket: s3Bucket, Key: dirKey, Body: item };

      // NOTE: SVGs get uploaded as octet streams if you dont enfore content type
      if(item.type === 'image/svg+xml') {
        // uploadParams.ACL = 'public-read'
        uploadParams.ContentType = item.type
      }

      const command = new PutObjectCommand(uploadParams);
      await s3client.send(command);
      s3Links.push(link)
    })
  );

  return s3Links;
};


export const s3DataCopier = async (ogDir, newDir, bucket, deleteOnCopy) => {

  let s3Bucket;
  switch(bucket){
    case 'feedback':
      s3Bucket = REACT_APP_FEEDBACK_PHOTOS_BUCKET;
      break;
    case 'csv':
      s3Bucket = REACT_APP_CSV_BUCKET;
      break;
    case 'viewer-settings':
      s3Bucket = REACT_APP_VIEWER_SETTINGS_BUCKET;
      break;
    case 'ghost-model':
      s3Bucket = REACT_APP_GHOST_MODEL_BUCKET;
      break;
    default:
      break;
  };
  
  const s3client = new S3Client({ 
    region: "us-east-1",
     credentials: { 
      accessKeyId: REACT_APP_S3_KEY,
      secretAccessKey: REACT_APP_S3_SECRET 
    } 
  });
  const listParams = {
    Bucket: s3Bucket,
    Prefix: `${ogDir}`
  };

  try {
    const listObjectsCommand = new ListObjectsV2Command(listParams);
    const data = await s3client.send(listObjectsCommand);

    const copyPromises = data.Contents.map(async (item) => {
      const copyParams = {
        Bucket: s3Bucket,
        CopySource: `${s3Bucket}/${item.Key}`,
        Key: `${newDir}/${item.Key.replace(`${ogDir}/`, '')}`
      };

      try {
        const copyCommand = new CopyObjectCommand(copyParams);
        await s3client.send(copyCommand)
      } catch (err) {
        console.log(`Error copying ${item.Key}:`, err);
      }
    });

    await Promise.all(copyPromises);

    if(deleteOnCopy) {
      const deletePromises = data.Contents.map(async (item) => {
        const deleteParams = {
          Bucket: REACT_APP_GHOST_MODEL_BUCKET,
          Key: item.Key
        };
        const deleteCommand = new DeleteObjectCommand(deleteParams);
        await s3client.send(deleteCommand)
      })
      await Promise.all(deletePromises);
    }

  } catch (err) {
    console.log("Error", err);
  }

  return true;
};

