// import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// Mui
import { styled } from '@mui/material/styles'
import { HexColorInput, HexColorPicker } from "react-colorful";

// import useClickOutside from '../../hooks/useClickOutside'

// ----------------------------------------------------------------------

const StyledHexColorInput = styled(HexColorInput)(({ theme }) => ({
  marginTop: 6,
  background: '#ffffff !important',
  fontFamily: 'Overpass',
  fontWeight: 500,
  letterSpacing: '.03em',
  outline: 'none',
  border: 'solid 1px #cccccc',
  borderRadius: 3,
  color: '#5b5b5b',
  fontSize: 15,
  width: '100%',
  paddingLeft: 10,
  position: 'relative',
}))


RHFColorPicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFColorPicker({ name, ...other }) {
  const { control } = useFormContext();

  return (

    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, ref }) => (
        <>
        <HexColorPicker 
          {...field}
          color={typeof field.value === 'number' && field.value === 0 ? '' : field.value} 
          ref={ref}
          {...other}
        />
        <StyledHexColorInput prefixed color={typeof field.value === 'number' && field.value === 0 ? '' : field.value}  />
        </>
      )}
    />
  );
}
