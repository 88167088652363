import { fetchSendSlackMessage } from '../../utils/fetchData';
import { getJWTstorage } from '../../utils/jwt'

const { REACT_APP_MODEL_VIEWER } = process.env;


const buildUploadPayload = (adminStatus, storeTitle, storeID, fileInfo, status, step, dataInfo) => {

  const userRole = adminStatus ? 'ADMIN' : 'USER';
  const basePayload = {
    slack_channel: 'self_serve_assets',
    text: ''
  };

  if (status) {
    const stepText = step === 1 ? 'Taking a chance 🎲 🤞' : 'Completed Something Incredible 🚀';
    const stepNumber = `STEP ${step}:`;
    const fileType = dataInfo.type === 'MODEL' ? 'MODEL' : 'PRODUCT';
    const fileName = fileInfo.fileName + (dataInfo.type === 'MODEL' ? '.glb' : '');

    basePayload.text = `${stepNumber} ${fileType} Self-Service: ${userRole} ${stepText}` +
    `\n>Store: ${storeTitle} | ${storeID}` +
    `\n>File Name: \`${fileName}\``;
    if (step === 2) {
      basePayload.text += `\n> Model ID: <${REACT_APP_MODEL_VIEWER}/?model=${dataInfo?.modelID}|${dataInfo?.modelID}>`;
    }
    if (dataInfo.type === 'PRODUCT') {
      basePayload.text += `\n> Product: <${dataInfo?.referer}|${dataInfo?.productTitle}>`;
    }
  } else {
    basePayload.text = `STEP 1: PRODUCT Self-Service: ${userRole} Taking a chance 🎲 🤞` + 
    `\n> Store: ${storeTitle} | ${storeID}` +
    `\n> Product: <${dataInfo?.referer}|${dataInfo.productTitle}>` +
    `\n> File Name: \`${fileInfo?.fileName}\`` +
    `\n*REJECTION*: Sorry, your product did not pass the validation. 😢` +
    `\n> Error Code: \`${fileInfo?.errors[0]?.code}\`` +
    `\n> Error Message: \`${fileInfo?.errors[0]?.message}\``;
  }

  return basePayload;
}

// ***************************************************
export function slackHandleUploadMessage({ 
  status,
  step,
  fileInfo,
  dataInfo
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const payload = buildUploadPayload(admin.adminStatus, store.store_title, store.store_id, fileInfo, status, step, dataInfo)
      await fetchSendSlackMessage(payload, jwt);

      return true;
    } catch(error) {
      console.log('error on slackHandleUploadMessage thunk', error);
      return error;
    }
  }
};

// ***************************************************
// Slack Add To Cart Message
// ***************************************************
export function slackHandleAddToCartMessage({ 
  dataInfo
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const userRole = admin.adminStatus ? 'ADMIN' : 'USER';

      const payload = {
        slack_channel: 'dashboard_events',
        text: ''
      }
      
      if(dataInfo.type === 'success') {
        payload.text = `🎲 🤞 STEP 1: Add To Cart: Self-Service: ${userRole}` + 
        `\n> Store: ${store.store_title} | ${store.store_id}` +
        `\n> Product Info : <${dataInfo?.product?.referer}|${dataInfo?.product?.product_title}> | qty ${dataInfo?.product?.variant_count}` +
        `\n> Cart: \`${dataInfo?.cartResponse?.closestCategory}\` | \`$${dataInfo?.cartResponse?.price}\``  
      } else {
        payload.text = `😢 REJECTION Add To Cart: Self-Service: ${userRole}` + 
        `\n> Store: ${store.store_title} | ${store.store_id}` +
        `\n> Product Info : <${dataInfo?.product?.referer}|${dataInfo?.product?.product_title}> | qty ${dataInfo?.product?.variant_count}` +
        `\n> Cart: \`${dataInfo?.cartResponse}\``  
      }
      await fetchSendSlackMessage(payload, jwt);

      return true;
    } catch(error) {
      console.log('error on slackHandleUploadMessage thunk', error);
      return error;
    }
  }
};


// ***************************************************
// Slack CSV Upload Message
// ***************************************************
export function slackHandleUserUploadedCSVMessage() {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const userRole = admin.adminStatus ? 'ADMIN' : 'USER';

      const payload = { slack_channel: 'dashboard_events', text: '' }
      payload.text = `🤙 ${userRole} just uploaded a CSV file to create products and variants` + 
      `\n> Store: ${store.store_title} | ${store.store_id}`
      
      await fetchSendSlackMessage(payload, jwt);

      return true;
    } catch(error) {
      console.log('error on slackHandleUserUploadedCSVMessage thunk', error);
      return error;
    }
  }
};


// ***************************************************
// Slack CUSTOM Store Signup Message
// ***************************************************
export function slackHandleCustomStoreSignupMessage() {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // const userRole = admin.adminStatus ? 'ADMIN' : 'USER';

      const payload = { slack_channel: 'signups', text: '' }
      payload.text = `${user.business_name} has completed the signup process` + 
      `\n> Store: ${store.store_title} | ${store.store_id}`
      
      await fetchSendSlackMessage(payload, jwt);

      return true;
    } catch(error) {
      console.log('error on slackHandleCustomStoreSignupMessage thunk', error);
      return error;
    }
  }
};