import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
// import Link from '@mui/material/Link';
// @mui
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
// Redux
// import { useSelector, useDispatch } from '../../../../../../../redux/store';
// import { setVariantsByProductId } from '../../../../../../../redux/thunks/assetThunks';
// components
import VariantCardRow from './VariantCardRow';
import Iconify from '../../../Iconify';
// ----------------------------------------------------------------------

VariantImageDrawer.propTypes = {
  onClose: PropTypes.func,
  productImages: PropTypes.array,
  allLinkedProductData: PropTypes.array,
  dataReady: PropTypes.bool,
  methods: PropTypes.any,
}

export default function VariantImageDrawer({ 
  onClose, 
  productImages,
  allLinkedProductData,
  dataReady,
  methods,
}) {


  // Form Hooks / Functions
  const {
    watch,
    setValue,
    getValues,
    trigger,
  } = methods;

  const values = watch()

  const alreadySelected = getValues('additional_product_photos');
  // const dispatch = useDispatch();

  // const { integration } = useSelector(state => state.account);

  const [imagesSelected, setImagesSelected] = useState([]);

  // ----------------------
  // Update local with previously selected
  // ----------------------

  const includesAll = (arr, target) => target.every(v => arr.includes(v));
  // const linkedProducts = getValues('linked_products');

  // ----------------------
  // Check if all images are already selected
  // ----------------------
  useEffect(() => {
    if(alreadySelected?.length >= 1) {
      setImagesSelected((prev) => ([...prev, ...alreadySelected]));
    }
  }, [alreadySelected]);


  // ----------------------
  // Handle Variant Select
  // ----------------------
  const onItemSelect = (data) => {
    if(imagesSelected.includes(data)) {
      // Remove Single
      const cleanedItems = imagesSelected.filter(item => item !== data);
      setImagesSelected([...cleanedItems]);
    } else {
      // Add Single
      setImagesSelected(prev => [...prev, data])
    }
  };
  
  const onSelectAllItems = (index) => {
    const allImages = allLinkedProductData[index].map(item => item.image_url)
    const check = includesAll(imagesSelected, allImages);

    // Check if any are in imageSelected first
    if(imagesSelected.length >= 1 && check) { // Includes All 
      const filteredSelected = imagesSelected.filter(image => !allImages.includes(image));
      setImagesSelected([...filteredSelected]);
    } else if(imagesSelected.length >= 1 && imagesSelected.some(imageURL => allImages.includes(imageURL))) { // Includes some
      const addThese = allImages.filter(image => !imagesSelected.includes(image));
      setImagesSelected(prev => [...prev, ...addThese])
    } else { // Includes None
      setImagesSelected(prev => [...prev, ...allImages])
    }
  };

  const addImagesAndClose = async () => {
      // Duplicate checking
    let filteredParent = imagesSelected.filter(item => !values.additional_product_photos.includes(item));

      // Deselect All
    if(filteredParent.length === 0 && imagesSelected.length === 0) {
      // Remove every image from parent associated to this product
      // filteredParent = values.additional_product_photos.filter(item => !productImages.includes(item));
      // const filteredChild = filteredParent.filter(item => !alreadySelected.includes(item));
      setValue('additional_product_photos', [...filteredParent]);

      // Deselect Singles
    } else if(filteredParent.length === 0 && imagesSelected.length !== 0) {
      // This allows us to not add duplicates to the loadout
      filteredParent = values.additional_product_photos.filter((item) => {
        // So we dont have to recalculate .includes for every condition
        const calcSelectedImg = imagesSelected.includes(item);
        const calcProdImg = productImages.includes(item);

        // Return only selected images for this product
        if(calcSelectedImg && calcProdImg) return item;

        // Remove from the same product (on deselect)
        if(!calcSelectedImg && calcProdImg) return null;

        // Return Everything else from other products (not from this one)
        return item;
      });
      setValue('additional_product_photos', [ ...filteredParent ]);
      
      // Select Singles & Select All
    } else {
      setValue('additional_product_photos', [...values.additional_product_photos, ...filteredParent]);
    }

    await trigger('additional_product_photos'); // Run form validation after adding images

    onClose();
  };

  
  return (
    <Stack 
      direction="column"
      sx={{ width: '100%', height: '100%', overflow: 'auto', flex: 1 }}
    >
      <Stack
        direction="column" 
        sx={{ mb: 4 }}
      >

        {/* Title */}
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} align="center" gutterBottom>
            Add Files
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', top: 20, right: 10 }}>
            <Iconify icon={'ic:round-close'} width="20" height="20" />
          </IconButton>
        </Stack>

        {/* Copy */}
        <Typography variant="body2" align="center" sx={{ mx: 'auto' }}>
          Pick the images from your ecommerce products. Don't see a picture for your product? <br />
          We filter out duplicate images.
        </Typography>
      </Stack>


      {/* Variant Row */}
      <Box sx={{ maxHeight: 500, overflow: 'auto', margin: '0 auto' }}>
        {( !dataReady ? [...Array(values.linked_products.length)] : allLinkedProductData).map((variant, index) =>
        <VariantCardRow
          key={index}
          productNumber={index + 1}
          dataReady={dataReady}
          linkedVariants={variant} // all data
          onItemSelect={onItemSelect} 
          itemsSelected={imagesSelected} 
          onSelectAllItems={onSelectAllItems}
          linkedProducts={values.linked_products} // temp
        />
        )}
      </Box>

      {/* Action */}
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ marginTop: 'auto' }}
      >
        <Button 
          variant="outlined"
          onClick={() => onClose()}
          sx={{ mr: 2 }}
        >
          Close
        </Button>

        <Button 
          variant="contained"
          disabled={!dataReady || (imagesSelected.length === 0 && alreadySelected.length === 0)}
          onClick={() => addImagesAndClose()}
        >
          {(imagesSelected.length === 0 && alreadySelected.length !== 0) ? 'Update': 'Add Images'}
        </Button>
      </Stack>

    </Stack>
  );
}
