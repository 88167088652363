import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
// components
import { 
  FormProvider, 
  RHFTextField, 
  RHFSelect
} from '../../hook-form';
import Iconify from '../../Iconify';
import { useDispatch, useSelector } from '../../../redux/store';
import { setUpdateEcommStoreFields } from '../../../redux/thunks/ecommStoresThunks';
import { delay } from '../../../utils/levarHelpers';
import { fetchUserByUUID, fetchCreatePrivateKey, fetchAdminUpdateWizardState } from '../../../utils/fetchData';
import { getJWTstorage } from '../../../utils/jwt';

// ----------------------------------------------------------------------

StoreTools.propTypes = {
  storeData: PropTypes.object.isRequired,
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function StoreTools({ storeData }) {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { enqueueSnackbar } = useSnackbar();
  
  const PricingSchema = Yup.object().shape({
    store_title: Yup.string().nullable(),
    store_id: Yup.string().nullable(),
    platform: Yup.string().nullable(),
    recurring_application_charge_id: Yup.string().nullable(),

    // Pricing
    setup_wizard_state: Yup.number().nullable(),
    implementation_type: Yup.string().nullable(),
    store_status: Yup.string().nullable(),
    store_type: Yup.string().nullable(),
    levar_private_key: Yup.string().nullable(),
    store_id_links: Yup.array().nullable(),
    new_store_id_to_link: Yup.string().nullable(),
  });

  const defaultValues = {
    store_title: storeData.store_title,
    store_id: storeData.store_id,
    platform: storeData.platform,

    // Pricing
    setup_wizard_state: null,
    implementation_type: '',
    store_status: '',
    store_type: '',
    levar_private_key: '',
    store_id_links: [],
    new_store_id_to_link: '',
  };

  const methods = useForm({
    resolver: yupResolver(PricingSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [state, setState] = useState({
    user: null,
    pageReady: false,
    isWriting: '',
  });


  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && storeData) {
      const getResource = async () => {

        const{ foundUser, foundUserIntergration } = await fetchUserByUUID(storeData.levar_user_account_id);
        if(!foundUser) {
          
          setState((prevState) => ({
            ...prevState,
            pageReady: true,
          }))
          enqueueSnackbar("User not found!", { variant: 'error' });
          return;
        }
        setValue("setup_wizard_state", foundUser.setup_wizard_state);
        setValue("implementation_type", storeData?.implementations?.asset_theme);
        setValue("store_status", storeData?.store_status);
        setValue("levar_private_key", storeData?.levar_private_key);
        setValue("store_id_links", storeData?.store_id_links ?? []);

        setState((prevState) => ({
          ...prevState,
          user: foundUser,
          pageReady: true,
        }))
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [storeData, dispatch, setValue, enqueueSnackbar]);

  const handleCreatePrivateKey = async () => {
    setState((prevState) => ({
      ...prevState,
      isWriting: 'levar_private_key',
    }));
    const jwt = getJWTstorage(admin, storeData.levar_user_account_id);
    const pk = await fetchCreatePrivateKey(storeData.store_id, jwt);
    if(pk.error) {
      enqueueSnackbar(`Private Key Creation Failed!`, { variant: 'error' });
      return;
    }
    setValue("levar_private_key", pk.levar_private_key);

    setState((prevState) => ({
      ...prevState,
      isWriting: '',
    }));
    enqueueSnackbar(`Private Key Created Success!`, { variant: 'success' });
  };

  const handleUpdateWizardStage = async () => {
    setState((prevState) => ({ ...prevState, isWriting: 'wizard_stage' }));
    const payload = { uuid: state.user.uuid, setup_wizard_state: values.setup_wizard_state };
    await fetchAdminUpdateWizardState(payload); 
    setState((prevState) => ({ ...prevState, isWriting: '' }));
    enqueueSnackbar(`Stage Updated to ${values.setup_wizard_state} success!`, { variant: 'success' });
  };

  const handleUpdateStoreFields = async (field) => {
    setState((prevState) => ({ ...prevState, isWriting: field }));
    const valueOfField = getValues(field) // Need this for value updating before rerender
    const fieldsToUpdate = { [field]: valueOfField }
    await dispatch(setUpdateEcommStoreFields({ fieldsToUpdate, storeID: storeData.store_id }));
    setState((prevState) => ({ ...prevState, isWriting: '' }));
    enqueueSnackbar(`Update ${field} Success!`, { variant: 'success' });
  };


  // Submit edited options
  const onSubmit = async () => {
    try {
      enqueueSnackbar("Update Success!", { variant: 'success' });
    } catch (error) {
      console.error("onSubmit error", error);
    } finally {
      await delay(200)
      reset();
    }
  };

  const handleRemoveStoreIDLink = (storeID) => {
    const newStoreIDLinks = values.store_id_links.filter((id) => id !== storeID);
    setValue("store_id_links", newStoreIDLinks);
    handleUpdateStoreFields('store_id_links');
  };

  const handleAddStoreIDLink = () => {
    values.store_id_links.push(values.new_store_id_to_link);
    setValue("new_store_id_to_link", '');
    handleUpdateStoreFields('store_id_links');
  };

  if(!state.pageReady) {
    return (
      <Card sx={{ p: 2 }}>
        <Stack direction="column">
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
              Loading...
            </Typography>
          </Box>
        </Stack>
      </Card>
    )
  }

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column">
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
              Store Tools
            </Typography>
          </Box>
        
          <Grid container spacing={2} direction="row">
            <Grid item xs={6} md={6}>
              <Item>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                <RHFSelect name="setup_wizard_state" size="small" label="Setup Wizard Stage" type="number">
                  <MenuItem value={5}>Discovery & Demo Call (5)</MenuItem> 
                  <MenuItem value={6}>Request or Upload 3D Models (6)</MenuItem>
                  <MenuItem value={7}>Select Your Implementation (7)</MenuItem>
                  <MenuItem value={8}>Review Models (8)</MenuItem>
                  <MenuItem value={9}>Go Live! (9)</MenuItem>
                  <MenuItem value={1}>Incomplete Signup (1)</MenuItem> 
                  <MenuItem value={2}>Unfinished Integration (2)</MenuItem> 
                  <MenuItem value={3}>Unfinished Integration (3)</MenuItem> 
                  <MenuItem value={4}>Unfinished Integration (4)</MenuItem> 
                </RHFSelect>
                <IconButton onClick={handleUpdateWizardStage}>
                  <Iconify icon={ state.isWriting === "wizard_stage" ? "eos-icons:three-dots-loading" : "mdi:content-save-edit"} color="custom.light" sx={{ height: 20, width: 20}} />
                </IconButton>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <RHFSelect name="store_status" size="small" label="Store Status" type="number">
                    <MenuItem value={""}>N/A</MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                  </RHFSelect>
                  <IconButton onClick={() => handleUpdateStoreFields('store_status')}>
                    <Iconify icon={ state.isWriting === "store_status" ? "eos-icons:three-dots-loading" : "mdi:content-save-edit"} color="custom.light" sx={{ height: 20, width: 20}} />
                  </IconButton>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                <RHFSelect name="implementation_type" size="small" label="Implementation Type" type="number">
                  <MenuItem value={"n/a"}>N/A</MenuItem>
                  <MenuItem value={"viewer"}>Default</MenuItem>
                  <MenuItem value={"carousel"}>Carousel</MenuItem>
                  <MenuItem value={"signal"}>Signal</MenuItem>
                  <MenuItem value={"hotspot"}>Hotspot</MenuItem>
                  <MenuItem value={"combination"}>Combination</MenuItem>
                </RHFSelect>
                <IconButton onClick={() => handleUpdateStoreFields('implementation_type')}>
                    <Iconify icon={ state.isWriting === "implementation_type" ? "eos-icons:three-dots-loading" : "mdi:content-save-edit"} color="custom.light" sx={{ height: 20, width: 20}} />
                  </IconButton>
                </Stack>
              </Item>
            </Grid>

            <Grid item xs={6} md={6}>
              <Item>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <RHFTextField name="levar_private_key" size="small" label="Levar Private Key" type="text" disabled/>
                  { values.levar_private_key ? 
                    <IconButton onClick={() => window.navigator.clipboard.writeText(values.levar_private_key)}>
                      <Iconify icon="mdi:content-copy" color="custom.light" sx={{ height: 20, width: 20}} />
                    </IconButton>
                  : 
                    <IconButton onClick={handleCreatePrivateKey}>
                      <Iconify icon={ state.isWriting === "levar_private_key" ? "eos-icons:three-dots-loading" : "gridicons:create" } color="custom.light" sx={{ height: 20, width: 20}} />
                    </IconButton>
                  }
                </Stack>
              </Item>
            </Grid>


            <Grid container item xs={12} flexDirection="row" alignItems="stretch">
              
                <Grid item xs={6}>
                  <Item sx={{ height: '100%'}}>
                    <Typography variant="body2" color="custom.light" align="left" sx={{ px: 1, pb: 1}}><b>Store Links:</b></Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      { values.store_id_links?.length > 0 ? 
                          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, border: '1px solid #000', borderRadius: 1, p: 1, width: '100%', overflow: 'auto'}}>
                          { values.store_id_links?.map((storeID, index) => (
                                <Chip
                                  key={index}
                                  label={storeID}
                                  onDelete={() => handleRemoveStoreIDLink(storeID)}
                                />
                            ))
                          }
                        </Box>
                        :
                        <Typography variant="body2" color="custom.light" align="left" sx={{ px: 1, pb: 1}}>No Store Links</Typography>
                        
                      }
                      <IconButton onClick={() => handleUpdateStoreFields('store_id_links')}>
                        <Iconify icon={ state.isWriting === "store_id_links" ? "eos-icons:three-dots-loading" : "mdi:content-save-edit" } color="custom.light" sx={{ height: 20, width: 20}} />
                      </IconButton>
                    </Stack>
                  </Item>
                </Grid>


                <Grid item xs={6} >
                  <Item sx={{ height: '100%'}}>
                    <Typography variant="body2" color="custom.light" align="left" sx={{ px: 1, pb: 1}}><b>Add Store ID Link:</b></Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1}}>
                      <RHFTextField name="new_store_id_to_link" size="small" label="Add a Store ID" type="text"  />
                      <IconButton onClick={handleAddStoreIDLink}>
                        <Iconify icon={ state.isWriting === "new_store_id_to_link" ? "eos-icons:three-dots-loading" : "mdi:plus" } color="custom.light" sx={{ height: 20, width: 20}} />
                      </IconButton>
                    </Stack>
                  </Item>
                </Grid>

            </Grid>


          </Grid>
        </Stack>
      </FormProvider>
    </Card>
  )
};