import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
//
import Editor from '../editor';

// ----------------------------------------------------------------------

RHFDatepicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatepicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          label={other?.label ?? "Date"}
          value={field.value}
          inputRef={field.ref}
          onChange={(date) => {
            field.onChange(date);
          }}
          slotProps={{
            textField: {
              size: other?.size ?? 'medium',
            },
          }}
          {...other}
        />
      )}
    />
  );
}

