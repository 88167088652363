// import { useMemo, memo, useCallback } from 'react'
import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';
// import { FileThumbnail } from '../file-thumbnail'

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  showPreview: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  type: PropTypes.string,
  productPhotosPrevSelected: PropTypes.array
};


export default function MultiFilePreview({ showPreview = false, files, onRemove, selected, setSelected, type, productPhotosPrevSelected }) { 
  const hasFile = files.length > 0;

  // TODO: figure out how to not rerender these on every state change

  return (
    <List disablePadding sx={{ ...(hasFile && { mt: 1 }) }}>
      <AnimatePresence>
      {files.map((file, index) => {
          const { key, name, size, preview } = getFileData(file, index);

          // Show File Icons and ability to click remove
          // if(!showPreview && type === "selectable"){
          //   return(
          //     <Badge   
          //       anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          //       key={key}
          //       invisible={selected !== undefined && !selected.includes(preview) && !productPhotosPrevSelected.includes(preview)}
          //       badgeContent={"√"}
          //       color="success"
          //       overlap="circular"
          //     >
          //       <ListItem
          //         component={m.div}
          //         key={key}
          //         {...varFade().inRight}
          //         sx={{
          //           p: 0,
          //           m: 0.5,
          //           width: 40,
          //           height: 40,
          //           borderRadius: 1.25,
          //           overflow: 'hidden',
          //           position: 'relative',
          //           display: 'inline-flex',
          //           border: (theme) => `solid 1px ${theme.palette.divider}`,
          //         }}
          //       >
          //         <FileThumbnail file={file} />
          //         {onRemove && (
          //           <IconButton
          //             size="small"
          //             onClick={() => onRemove(file)}
          //             sx={{
          //               top: 2,
          //               p: '2px',
          //               right: 2,
          //               position: 'absolute',
          //               color: 'common.white',
          //               bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
          //               '&:hover': {
          //                 bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
          //               },
          //             }}
          //           >
          //             <Iconify icon={'eva:close-fill'} width={10} height={10} />
          //           </IconButton>
          //         )}
          //       </ListItem>
          //     </Badge> 
          //   )
          // }

          // Show File Preview Image and ability to click remove
          if(showPreview && type === "selectable"){
            return(
              <Badge   
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                key={key}
                invisible={selected !== undefined && !selected.includes(preview) && !productPhotosPrevSelected.includes(preview)}
                badgeContent={"√"}
                color="success"
                overlap="circular"
              >
                <ListItem
                  component={m.div}
                  key={key}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 40,
                    height: 40,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                    <Image alt="preview" src={preview} sx={{ cursor: 'copy' }} ratio="1/1" onClick={() => setSelected(preview)} />
                  {onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        top: 2,
                        p: '2px',
                        right: 2,
                        position: 'absolute',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <Iconify icon={'eva:close-fill'} width={10} height={10} />
                    </IconButton>
                  )}
                </ListItem>
              </Badge> 
            )
          }
          
          // Show File Preview Image and disable remove
          if (showPreview && type !== 'selectable') {
            return (
              
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 40,
                    height: 40,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Image alt="preview" src={preview} ratio="1/1" onClick={() => window.open(preview)} />
                  {onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        top: 2,
                        p: '2px',
                        right: 2,
                        position: 'absolute',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <Iconify icon={'eva:close-fill'} width={10} height={10} />
                    </IconButton>
                  )}
                </ListItem>
            );
          }

          // Basic Icon
          return (
            <ListItem
              key={key}
              component={m.div}
              {...varFade().inRight}
              sx={{
                my: 1,
                px: 2,
                py: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

              <ListItemText
                primary={typeof file === 'string' ? file : name}
                secondary={typeof file === 'string' ? '' : fData(size || 0)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />

              {onRemove && (
                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                  <Iconify icon={'eva:close-fill'} />
                </IconButton>
              )}
            </ListItem>
          );
        })}
      </AnimatePresence>
    </List>
  );
};