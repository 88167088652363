import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// Redux
import { useSelector, useDispatch } from '../../redux/store';
import { setCreateProductVariantFromCSV } from '../../redux/thunks/ecommVariantThunks';
import { slackHandleUserUploadedCSVMessage } from '../../redux/thunks/slackThunks';
// Components
import Iconify from '../Iconify';
import { 
  FormProvider, 
  RHFUploadSingleFile, 
} from '../hook-form';
// Utils
import { s3Connector } from '../../utils/s3Connector';
import { delay } from '../../utils/levarHelpers';

ProductCSVUploadDialog.propTypes = {
  onClose: PropTypes.func,
}


// Used for making our customers pay their open invoices
export default function ProductCSVUploadDialog({ onClose }) {
  // shopify or custom
  // shopify 
  const { enqueueSnackbar } = useSnackbar();
  const ReduxStore = useSelector((state) => state.account.store);
  const dispatch = useDispatch();

  const defaultValues = { csvFile: [], csvValidated: false, exampleDownloaded: false, parsingInProgress: false };

  const CSVSchema = Yup.object().shape({
    csvFile: Yup.array().min(1, 'CSV file is required').max(1, 'Only one CSV file is allowed'),
    csvValidated: Yup.bool(),
    exampleDownloaded: Yup.bool(),
    parsingInProgress: Yup.bool(),
  });

  const methods = useForm({ resolver: yupResolver(CSVSchema), defaultValues });
  const { setValue, watch, reset, handleSubmit, formState: { isSubmitting } } = methods;
  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setValue('csvFile', [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue]
  );

  const handleRemove = () => {
    setValue('csvFile', []);
    setValue('csvValidated', false);
    setValue('exampleDownloaded', false);
    setValue('parsingInProgress', false);
  };

  const onSubmit = async (values) => {
    try {
      setValue('parsingInProgress', true);
      const storeID = ReduxStore.store_id;
      await s3Connector(values.csvFile, 'csv', `public/${storeID}`);
      await dispatch(setCreateProductVariantFromCSV({ csvName: values.csvFile[0].name }));
      enqueueSnackbar('CSV parsing in progress', { variant: 'info' });
    } catch (error) {
      console.log("error", error)
    } finally {
      await delay(60000); // Delay for a minute to let products and variants get created.
      await dispatch(slackHandleUserUploadedCSVMessage())
      sessionStorage.removeItem('shopType');
      enqueueSnackbar('CSV Import Complete', { variant: 'success' });
      onClose();
    }
  };

  const handleDownloadExample = () => {
    window.open("https://levar-integration-csv-imports.s3.amazonaws.com/levar-mfr-example-csv.csv", '_blank');
    setValue('exampleDownloaded', true);
    enqueueSnackbar('Example CSV downloaded', { variant: 'success' });
  };

  if(values.parsingInProgress) {
    return (
    <Stack direction="column" alignItems="center" justifyContent="space-evenly" sx={{ width: '100%', height: 220 }}>
      <Card sx={{ width: '100%', height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'flex-start', p: 3 }}>
        <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={1} sx={{ height: '100%' }}>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Typography variant="h4">CSV Import in Progress</Typography>
            <Typography variant="subtitle1">Sit tight, this will only take a few minutes</Typography>
          </Stack>
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress variant="indeterminate" color="info" sx={{ width: '100%' }} />
          </Box>
          <Typography variant="caption">This dialog will close automatically when the import is complete</Typography>
        </Stack>
      </Card>
    </Stack>
    )
  }
  return (
    <Stack direction="column" alignItems="center" justifyContent="space-evenly" sx={{ width: '100%', height: '100%', p: 1 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

        <Stack direction="column" alignItems="center">
          <Typography variant="h4">Welcome to LevAR</Typography>
          <Typography variant='subtitle1' textTransform="capitalize">Start by uploading your product catalog</Typography>
        </Stack>

        {/* values.csvFile.length > 0 */}

        <Box sx={{ width: '100%', height: '100%', p: 3 }}>
          <RHFUploadSingleFile
            name="csvFile"
            acceptedFileTypes={{ 'text/csv': [] }}
            disabled={isSubmitting}
            // disabled={isSubmitting}
            maxSize={99999999} // 95 MB
            onDrop={handleDrop}
            onRemove={handleRemove}
            onUpload={() => console.log('ON UPLOAD')}
          />
        </Box>

        <Stack direction="row" justifyContent="center" alignItems="center">
          <Card sx={{ p: 2, m: 1, width: 425 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Typography variant="body1" textTransform="capitalize">Download our example product CSV</Typography>
              <Button 
                variant="outlined" 
                onClick={handleDownloadExample} 
                size="small" 
                endIcon={<Iconify icon={values.exampleDownloaded ? "material-symbols-light:check-circle" : "material-symbols-light:sim-card-download-outline"} 
                color={values.exampleDownloaded ? 'green' : 'gray'} />}
              >
                Example CSV
              </Button>
            </Stack>
          </Card>
        </Stack>

        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={reset} size="medium">Reset</Button>

          <Button variant="contained" type="submit" size="medium" disabled={!values?.csvFile?.length}>Upload CSV</Button>
        </Stack>

      </FormProvider>
    </Stack>
  )
};