import { 
  fetchSingleProduct, // 2023
  // fetchSingleVariant 
} from '../../../utils/fetchData';
import { getJWTstorage } from '../../../utils/jwt';


// Product Beef Up
const beefUpProduct = async (productInfo, bodyHTML) => {
  // const newProduct = ""  
  // const productDescriptionHTML = productInfo;
  
  // const request = await fetch("http://localhost:3001/get-beefup-my-product", {
  const request = await fetch("https://kroggrok.ngrok.io/get-beefup-my-product", {
    method: "POST",
    body: JSON.stringify({
      productInfo, bodyHTML
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const description = await request.json();

//   descriptionText += `
//   <p>${description}</p>
//   <p><br></p>
// `;

  return description
};

// Description Summarizer
const summarizeData = async (productBody) => {
  let descriptionText = ""  
  const productDescriptionHTML = productBody;

  const request = await fetch("https://kroggrok.ngrok.io/get-summarize-html", {
  // const request = await fetch("http://localhost:3001/get-summarize-html", {
      method: "POST",
      body: JSON.stringify({
        productDescriptionHTML
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const description = await request.json();

    descriptionText += `
    <p>${description}</p>
    <p><br></p>
  `;

    return descriptionText
};

// Variant Summarizer
const summarizeVariants = async (info, variantIDsList) => {
  let variantText = "";

  variantText += `
  <p><strong>3D Variants of Product Requesting:</strong></p>
  <ol>`;
  
  info.search_response.variants.map(async (variant) => {
    const variantID = variant.variant_id;
    if(variantIDsList.includes(variantID)) {
      variantText += `<li><strong>${variant.title}</strong> - (<i>add dimensions</i>)</li>`;
    }
  });

  variantText += `</ol>
  <p><br></p>`;

  return variantText;
};


// STEP 1: build an AI template (ChatGPT)
export const buildTemplateAI = async (linkedProducts, admin, user) => {
  let loadout = '';

  const jwt = getJWTstorage(admin, user.uuid);

  await Promise.all(
    linkedProducts.map(async (product, productIndex) => {
      let productText = "";
      const variantIDsList = product.variants;
      const productID = product.product_id

      const info = await fetchSingleProduct(productID, jwt, admin.adminStatus);
      
      productText += `
        <p><strong>Product ${productIndex + 1}: ${info.search_response.product_title}</strong></p>
      `;
      
      let bodyHTML = info.search_response.body_html;
      if(!bodyHTML) {
        bodyHTML = 'No Information Available'
      }

      const descriptionText = await summarizeData(bodyHTML);
      productText += descriptionText

      const variantText = await summarizeVariants(info, variantIDsList);
      productText += variantText

      const finalizedProduct = await beefUpProduct(productText, bodyHTML)

      loadout += finalizedProduct;
    })
  );

  return loadout;
};


// STEP 1: Build a Simple Template (Only Products API Call)
export const buildTemplateSimple = async (linkedProducts, admin, user) => {
  let loadout = `
  <strong><i>----------------Please Confirm / Edit All Information Below-----------------</i></strong>
  <p><br></p>
  `;

  const jwt = getJWTstorage(admin, user.uuid);
  await Promise.all(
    linkedProducts.map(async (product) => {
      let productText = "";
      const variantIDsList = product.variants;
      const productID = product.product_id;

      const info = await fetchSingleProduct(productID, jwt, admin.adminStatus);
      // NOTE: BEN CULLY WILL COME BACK TO THIS
      // shopify link === info.search_response.store_url / products / info.search_response.handle 
      // bigcommerce === 
      productText += `
        <h3>${info.search_response.product_title}</h3>
        <h3>Variant: ${admin.adminStatus ? info.search_response.variants[0].variant_title : ""}</h3>
        <h3>MPN: ${info.search_response.mpn}</h3>
        <p><a href="${info.search_response.referer}" rel="noopener noreferrer" target="_blank">Product Link</a></p>
        <i>(Add detailed product description)</i>
        <p><br></p>
      `;
      
      const variantText = await summarizeVariants(info, variantIDsList);
      
      productText += variantText;

      productText += `
      -----------------------------------------------------------------------------------------
      <p><br></p>
      `;

      loadout += productText;
    })
  );

  return loadout;
};

// STEP 1: build a statis template
export const buildTemplateStatic = () => (
  `
  <strong><i>----------------Please Confirm / Edit All Information Below--------------</i></strong>
  <p><br></p>
  <p><strong>Product 1 Sample:</strong></p>
  <p>(Add detailed description of product here) Example: Has four colors all of which are the same size. 
  The stem of the table has two size ribbons, one of which comes out approx. 1.5" and the other 
  that comes out about .75". The table is 22" X 17" X 17"</p>
  <p><br></p>
  <p>
    <strong>3D Variants of Products Requesting:</strong>
  </p>
  <ol>
    <li><strong>Red</strong> - (<i>add dimensions</i>)</li>
    <li><strong>Blue</strong> - (<i>add dimensions</i>)</li>
    <li><strong>Orange</strong> - (<i>add dimensions</i>)</li>
    <li><strong>Purple</strong> - (<i>add dimensions</i>)</li>
  </ol>
  `
)

// ALL ----------------------------------------------------------------------


// export const TABLE_HEAD_FAKE = [
//   { id: '1', label: ' ', align: 'left', minWidth: 300},
//   { id: '2', label: ' ', align: 'left' },
//   { id: '3', label: ' ', align: 'left' },
//   { id: '4', label: ' ', align: 'left' },
//   { id: '5', label: ' ', align: 'left' },
//   { id: '6', label: ' ', align: 'left' },
//   { id: '7', label: '', align: 'left'},
// ];


export const TABLE_HEAD_FAKE = (columnCount) =>  {
  const columns = [{ id: '1', label: ' ', align: 'left', minWidth: 300}];
  Array.from(Array(columnCount)).forEach((x, index) => {
    columns.push({ id: `${index + 2}`, label: ' ', align: 'left' })
  });
  return columns;
}