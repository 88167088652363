import React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
// components
import { 
  FormProvider, 
  RHFTextField, 
  RHFDatepicker
} from '../../hook-form';
import { useDispatch } from '../../../redux/store';
import { setUpdateEcommStoreFields } from '../../../redux/thunks/ecommStoresThunks';

// ----------------------------------------------------------------------

PricingCard.propTypes = {
  storeData: PropTypes.object.isRequired,
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function PricingCard({ storeData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    store_title: storeData.store_title ?? '',
    store_id: storeData.store_id ?? '',
    platform: storeData.platform ?? '',
    recurring_application_charge_id: storeData?.shopify_billing?.recurring_application_charge_id ?? '',

    // Pricing
    model_limit: storeData.model_limit ?? 0,
    fixed_price: storeData?.shopify_billing?.fixed_price ?? 0,
    capped_amount: storeData?.shopify_billing?.capped_amount ?? 0,
    billing_on: dayjs(storeData?.shopify_billing?.billing_on ?? dayjs()),
  };

  const PricingSchema = Yup.object().shape({
    store_title: Yup.string().nullable(),
    store_id: Yup.string().nullable(),
    platform: Yup.string().nullable(),
    recurring_application_charge_id: Yup.string().nullable(),

    // Pricing
    model_limit: Yup.number().nullable(),
    fixed_price: Yup.number().nullable(),
    capped_amount: Yup.number().nullable(),
    billing_on: Yup.string().nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(PricingSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();


  // Submit edited options
  const onSubmit = async () => {
    try {
      const payload = {};
      const fields = ['fixed_price', 'model_limit', 'capped_amount', 'billing_on'];
      fields.forEach(field => {
        if (field === 'billing_on' && values.billing_on.toISOString() !== defaultValues.billing_on.toISOString()) {
          payload[field] = values.billing_on;
        } else if (field !== 'billing_on' && values[field] !== defaultValues[field]) {
          payload[field] = values[field];
        }
      });

      if(Object.keys(payload).length > 0) {
        await dispatch(setUpdateEcommStoreFields({ fieldsToUpdate: payload, storeID: values.store_id }));
        enqueueSnackbar("Update Success!", { variant: 'success' });
      } else {
        enqueueSnackbar("No changes to update!", { variant: 'info' });
      }
    } catch (error) {
      console.error("onSubmit error", error);
    }
  };

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column">
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
              Pricing
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Item>
                <RHFTextField name="fixed_price" size="small" label="Fixed Price" type="number" />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <RHFTextField name="model_limit" size="small" label="Model Limit" type="number" />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <RHFTextField name="capped_amount" size="small" label="Capped Amount" type="number" />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <RHFDatepicker name="billing_on" label="Billing On" size="small" />
                </LocalizationProvider>
              </Item>
            </Grid>
          </Grid>

          <Box sx={{ mt: 1, p: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Button
                variant="outlined" 
                size="medium" 
                onClick={() => reset()}
              >
                Reset
              </Button>
              <LoadingButton 
                variant="contained" 
                size="medium" 
                type="submit"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </FormProvider>
    </Card>
  )
};