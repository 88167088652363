import PropTypes from 'prop-types';
// @components
import { DialogAnimate } from '../animate';

// ----------------------------------------------------------------------

BasicDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
};

export default function BasicDialog({ 
  onClose, 
  open,
  children,
  ...other
}) {
    return (
      <DialogAnimate 
        fullWidth 
        open={open}
        onClose={onClose} 
        sx={{ flexShrink: 0, p: 3 }}
        {...other}
      >
        { children }
      </DialogAnimate>
    );
}

// size => lg || md || sm || xs