import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

// components
import { 
  FormProvider, 
  RHFSelect, 
  RHFTextField, 
} from '../hook-form';
import Iconify from '../Iconify';
import { setUpdateEcommVariants, setCopyStoreImplementationToVariants } from '../../redux/thunks/ecommVariantThunks';
import { setEcommerceVariantsByProductId } from '../../redux/thunks/ecommProductThunks';
import { useSelector, useDispatch } from '../../redux/store';
// ----------------------------------------------------------------------

VariantImplementationsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  dialogData: PropTypes.object.isRequired,
};

const options = {
  ar_experience_type: [
    { label: 'Reset', value: 'false' },
    { label: 'Try On', value: 'levar_try_on' },
  ],
  asset_theme: [
    { label: 'Reset', value: 'false' },
    { label: 'levar Debut', value: 'levar_debut' },
    { label: 'levAR Player', value: 'levar_player' },
  ],
  billboard_status: [
    { label: 'Inactive', value: 'false' },
    { label: 'Active', value: 'true' },
  ],
  custom_button: [
    { label: 'Inactive', value: 'false' },
    { label: 'Active', value: 'true' },
  ],
  direct_qr: [
    { label: 'Inactive', value: 'false' },
    { label: 'Active', value: 'true' },
  ],
  pdp_variant_switch: [
    { label: 'Inactive', value: 'false' },
    { label: 'Active', value: 'true' },
  ],
  preload_viewer: [
    { label: 'Inactive', value: 'false' },
    { label: 'Active', value: 'true' },
  ],
  model_placement: [
    { label: 'None / Null', value: 'reset' },
    { label: 'Ceiling', value: 'ceiling' },
    { label: 'Wall', value: 'wall' },
  ],
  translation_inactive: [
    { label: 'False', value: 'false' },
    { label: 'True', value: 'true' },
  ],
  view_in_space_off: [
    { label: 'False', value: 'false' },
    { label: 'True', value: 'true' },
  ],

};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function VariantImplementationsDialog({ onClose, dialogData }) {
  const dispatch = useDispatch();
  const { implementations } = useSelector((state) => state.account.store);
  const { enqueueSnackbar } = useSnackbar();
  
  const UpdateVariantSchema = Yup.object().shape({
    ar_experience_type: Yup.string(),
    asset_theme: Yup.string(),
    billboard_status: Yup.string(),
    custom_button: Yup.string(),
    qr_header: Yup.string(),
    qr_footer: Yup.string(),
    direct_qr: Yup.string(),
    levar_exit_url: Yup.string(),
    pdp_variant_switch: Yup.string(),
    preload_viewer: Yup.string(),
    model_placement: Yup.string(),
    translation_inactive: Yup.string(),
    view_in_space_off: Yup.string(),
  });

  const defaultValues = {
    ar_experience_type: '',
    asset_theme: '',
    billboard_status: '',
    custom_button: '',
    qr_header: '',
    qr_footer: '',
    direct_qr: '',
    levar_exit_url: '',
    pdp_variant_switch: '',
    preload_viewer: '',
    model_placement: '',
    translation_inactive: '',
    view_in_space_off: ''
  };

  const methods = useForm({
    resolver: yupResolver(UpdateVariantSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [state, setState] = useState({
    products: [],
    liveVariants: [],
    pageReady: false,
    isWriting: false,
  });

  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && dialogData.products || dialogData.variants) {
      const getResource = async () => {
        if(dialogData.variants && !dialogData.products) {
          let liveVariants = []
          if(dialogData.selectedVariant) { // Singular Variant
            liveVariants = dialogData.variants.filter(variant => variant.variant_id === dialogData.selectedVariant) // Find selected variant 
          } else { // All Variants
            liveVariants = dialogData.variants.filter(variant => (variant.model_3d_id !== null || variant.interactive_viewer_0 !== null || variant.static_viewer_0 !== null || variant.static_vto_face_0 !== null)) // Filter out non live variants
          }

          Object.entries(liveVariants[0]).forEach(([key, value]) => { // Prefill form values
            switch(key) {
              case 'view_in_space_off':
              case 'translation_inactive':
              case 'preload_viewer':
              case 'pdp_variant_switch':
              case 'direct_qr':
              case 'custom_button':
              case 'billboard_status':
              case 'asset_theme':
              case 'ar_experience_type':
                setValue(key, value)
                break
              default:
                break
            }
          })

          setState({
            liveVariants,
            pageReady: true,
            isWriting: false,
          })
        } 
        if(dialogData.products && !dialogData.variants) {
          const filteredProducts = dialogData.products.filter((product) => product?.existing_model_count > 0)
          const prodIds = filteredProducts.map(product => product.product_id)
          const variants = await dispatch(setEcommerceVariantsByProductId({ productIDs: prodIds}))
          const liveVariants = variants.filter(variant => variant.model_3d_id !== null || variant.interactive_viewer_0 !== null || variant.static_viewer_0 !== null || variant.static_vto_face_0 !== null)
          setState({
            products: filteredProducts,
            liveVariants,
            pageReady: true,
            isWriting: false,
          })
        }
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [dialogData, dispatch, setValue]);

  const handleWriteDefaultImplementation = async () => {
    setState((prev) => ({...prev, isWriting: true}))
    const variantIDList = state.liveVariants.map(variant => variant.variant_id)
    await dispatch(setCopyStoreImplementationToVariants({ 
      variantIDList,
      implementationToCopy: implementations
    }));
    setState((prev) => ({...prev, isWriting: false}))
    enqueueSnackbar("Update Success!", { variant: 'success' });
  };
  
  const onSubmit = async () => {
    try {
      const ecommVariantIds = state.liveVariants.map(variant => variant.variant_id)
      const payload = Object.entries(values).reduce((acc, [key, value]) => { // Filter out empty stuff
          if (value !== "" || value !== null || value !== undefined) {
              acc[key] = value;
          }
          if (value === "true" || value === "false") {
            acc[key] = JSON.parse(value);
          }
          return acc;
      }, {});

      await dispatch(setUpdateEcommVariants({ ecommVariantIds, settingsToWrite: payload, destructure: true }));
      enqueueSnackbar("Update Success!", { variant: 'success' });
    } catch (error) {
      console.error("onSubmit error", error);
    } finally {
      reset() // reset form
      onClose();
    }
  };

  return (
    <Box >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
          <Typography variant="h4" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
            Variant Implementation Settings
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 20, top: 1 }}>
            <Iconify icon={'ic:round-close'} width="20" height="20" />
          </IconButton>

        <Divider />

        <Stack direction="row" alignItems="center" justifyContent="space-evenly" spacing={1} sx={{ px: 8 }}>
          <Typography variant="subtitle1">Write store implementation values to {state.liveVariants.length} variant(s)</Typography>
          <LoadingButton variant="contained" size="small" onClick={handleWriteDefaultImplementation}>Write</LoadingButton>
        </Stack>
        
        <Divider />

        <Box sx={{ mt: 1, px: 1 }}>
        
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Select Specific Settings and write to live variants
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={3}>
              <Item>
                <RHFSelect name="ar_experience_type" size="small" label="AR Experience Type" >
                  {options.ar_experience_type.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={3}>
            <Item>
                <RHFSelect name="asset_theme" size="small" label="Asset Theme" >
                  {options.asset_theme.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={3}>
              <Item>
                <RHFSelect name="billboard_status" size="small" label="Billboard Status" >
                  {options.billboard_status.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={3}>
              <Item>
                <RHFSelect name="custom_button" size="small" label="Custom Button" >
                  {options.custom_button.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="direct_qr" size="small" label="Direct QR" >
                  {options.direct_qr.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="pdp_variant_switch" size="small" label="PDP Variant Switch" >
                  {options.pdp_variant_switch.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="preload_viewer" size="small" label="Preload Viewer" >
                  {options.preload_viewer.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="translation_inactive" size="small" label="Translation Inactive" >
                  {options.translation_inactive.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFSelect name="model_placement" size="small" label="Model Placement" >
                  {options.model_placement.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>

            <Grid xs={4}>
              <Item>
                <RHFSelect name="view_in_space_off" size="small" label="View In Space Off" >
                  {options.view_in_space_off.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Item>
            </Grid>

            <Grid xs={4}>
              <Item>
                <RHFTextField name="levar_exit_url" size="small" label="Levar Exit URL" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="qr_header" size="small" label="QR Header" />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item>
                <RHFTextField name="qr_footer" size="small" label="QR Footer" />
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 1, px: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button
              variant="outlined" 
              size="medium" 
              onClick={() => reset()}
            >
              Reset
            </Button>
            <LoadingButton 
              variant="contained" 
              size="medium" 
              type="submit"
              loading={isSubmitting}
            >
              Write
            </LoadingButton>
          </Stack>
        </Box>

      </Stack>
      </FormProvider>
    </Box>
  )
};