// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// ----------------------------------------------------------------------

export default function FAQDialog() {

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h3" align="center" color="custom.light" gutterBottom sx={{ mx: 4, whiteSpace: 'pre-line' }}>
        {`LEVAR Art Creation \n Frequently Asked Questions:`}
      </Typography>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">How many products should I start with in 3D/AR?</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          We recommend starting with your best selling products. 
          For the best results, adding AR and 3D functionality to your entire product catalogue is ideal.
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">Where can I find my art pricing?</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          3D art creation depends on the complexity of your products and the number of products. Your art pricing will be included in your Scope of Work document. 
          If you have more questions, reach out to us with any questions at <Link underline="always" href="mailto:sales@levar.io">sales@levar.io</Link>. 
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">What if I already have 3D/AR models?</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          LEVAR's in-house Design Team can review and remaster (when necessary) existing 3D, CAD, or other model types for use with the LEVAR platform. Any existing models should be discussed during the sales and setup process.
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">Which eCommerce platforms does LEVAR integrate with?</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          LEVAR integrates with Shopify and BigCommerce and can be customized to integrate with other platforms for additional fees.
        </Typography>
      </Stack>
    </Stack>
  )
};