import PropTypes from 'prop-types';
// MUI
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

IFrameDialog.propTypes = {
  dialogData: PropTypes.string,
}

export default function IFrameDialog({ dialogData }) {

  return (
    <Stack direction="column" alignItems="center" >
      <iframe 
        style={{ border: 0, borderRadius: 10 }}
        src={dialogData} 
        title="LEVAR" 
        height="700px" 
        width="100%"
      />
    </Stack>
  )
};