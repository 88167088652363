import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Maintenance from './pages/Maintenance';

const { REACT_APP_MAINTENANCE_MODE } = process.env;

export default function App() {  

  if(REACT_APP_MAINTENANCE_MODE === 'true') {
    return (
      <MotionLazyContainer>
        <ThemeProvider>
          <Maintenance />
        </ThemeProvider>
      </MotionLazyContainer>
    );
  }
  
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}