import PropTypes from 'prop-types';
// MUI
import Stack from '@mui/material/Stack';


SOWDialog.propTypes = {
  dialogData: PropTypes.any,
}

export default function SOWDialog({ dialogData }) {

  return (
    <Stack spacing={1} direction="column" alignItems="center">
      <iframe 
        style={{ border: 0, borderRadius: 10 }}
        src={dialogData}
        title="Statement of Work"
        height="800px" 
        width="100%"
      />
    </Stack>
  )
};