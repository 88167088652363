import axios from 'axios';

const { 
  REACT_APP_RDS_API, 
  REACT_APP_ECOMMERCE_API,
  REACT_APP_PROJECTS_API
} = process.env;

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({ baseURL: REACT_APP_RDS_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong axiosInstance')
);

export const axiosInstanceRDS = axios.create({ baseURL: REACT_APP_RDS_API });

axiosInstanceRDS.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong axiosInstanceRDS')
);


export const axiosInstanceEcom = axios.create({ baseURL: REACT_APP_ECOMMERCE_API });

axiosInstanceEcom.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong axiosInstanceEcom')
);

export const axiosInstanceProjects = axios.create({ baseURL: REACT_APP_PROJECTS_API });

axiosInstanceProjects.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong axiosInstanceProjects')
);
// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config, method, baseType, jwt] = Array.isArray(args) ? args : [args];
  let configMutated = config;
  if(jwt) {
    configMutated = { ...config, headers: { Authorization: `Bearer ${jwt}` } };
  }
  

  let res;
  switch(method) {
    case 'GET': 
      switch(baseType) {
        case 'projectsAPI':
          res = await axiosInstanceProjects.get(url, { ...configMutated });
          break;
        case 'ecommerceAPI':
          res = await axiosInstanceEcom.get(url, { ...configMutated });
          break;
        default:
          break;
      }
      return res.data;
    case 'PUT':
      switch(baseType) {
        case 'projectsAPI':
          res = await axiosInstanceProjects.put(url, { ...configMutated });
          break;
        case 'ecommerceAPI':
          res = await axiosInstanceEcom.put(url, { ...configMutated });
          break;
        default:
          break;
      }
      return res.data;
    case 'POST':
      switch(baseType) {
        case 'projectsAPI':
          res = await axiosInstanceProjects.post(url, { ...configMutated });
          break;
        case 'ecommerceAPI':
          res = await axiosInstanceEcom.post(url, { ...configMutated });
          break;
        default:
          break;
      }
      return res.data;
    case 'DELETE':
      switch(baseType) {
        case 'projectsAPI':
          res = await axiosInstanceProjects.delete(url, { ...configMutated });
          break;
        case 'ecommerceAPI':
          res = await axiosInstanceEcom.delete(url, { ...configMutated });
          break;
        default:
          break;
      }
      return res.data;
    default:
      return null;
  };
};

// ----------------------------------------------------------------------

export const endpoints = (adminStatus) => ({
  product: {
    all: [`/${adminStatus ? 'admin' : 'user'}/products`, 'GET', 'ecommerceAPI'],
    single: [`/${adminStatus ? 'admin' : 'user'}/product`, 'GET', 'ecommerceAPI'],
    search: [`/user/products/match`, 'PUT', 'ecommerceAPI'], // Normal no model count
    searchCount: [`user/products/match/count`, 'PUT', 'ecommerceAPI'], // With model count (no admin endpoint for this)
  },
  ghostModels: {
    allGhosts: [`/ghosts/match/count`, 'PUT', 'projectsAPI'],
    allModels: [`/models/match/count`, 'PUT', 'projectsAPI'],
  },
  admin: {
    allStores: [`/admin/stores`, 'GET', 'ecommerceAPI'],
    activeStores: [`/admin/stores?store_status=active`, 'GET', 'ecommerceAPI'],
    projects: [`/projects`, 'GET', 'projectsAPI'],
    loadouts: [`/loadout/`, 'GET', 'projectsAPI']
  }
});