import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  mainLoadingScreen: {
    status: false,
    text: ''
  },
  secondaryMainLoading: {
    status: false,
    text: ''
  },
  pageLoadingScreen: {
    status: false,
    text: ''
  }
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {

    // SET LAYOUT APP LOADING
    getMainLoading(state, action) {
      state.mainLoadingScreen = { ...action.payload };
    },

    // NOTE: This is for redirects back when primary main loading is being used by a bunch of stuff.
    // This prevents loading from getting interupted by a different process.
    // SET LAYOUT APP LOADING 
    getSecondaryMainLoading(state, action) {
      state.secondaryMainLoading = { ...action.payload };
    },
    // SET PAGE LOADING
    getPageLoading(state, action) {
      state.pageLoadingScreen = { ...action.payload };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getMainLoading,
  getSecondaryMainLoading,
  getPageLoading
} = slice.actions;
