import { 
  fetchAllProducts, // 2023
  fetchProductWithVariants,
  fetchVariantsByProductId, // 2023
  // fetchVariantsByProductId, // 2023
  // fetchSingleProduct, // 2023
  // fetchSingleVariant, // 2023
  // fetchModelsForLoadout,  // 2023
  // fetchGhostsByConnectedVariant, // 2023
  // fetchCreateFlowCode,

} from '../../utils/fetchData';
// actions
import { 
  getDialog 
} from '../slices/dialog';
// actions
import {
  hasError,
} from '../slices/account';
// helpers
import { getJWTstorage } from '../../utils/jwt'




// **************************************************************************
// 2023
// WHERE: used in AllProducts.js in the user dashboard
// DESCRIPTION: Grabs all products for a given store_id
// IMPORTANT: Custom stores do not have products so it fetches and cleans no stage or stage 0 variants
// ALSO: If the user doesnt have a store it will fire the after the fact store integration dialog
// QUEREY: store_id 
// **************************************************************************
export function setAllProductsNEW() {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      
      const jwt = getJWTstorage(admin, user.uuid);

      // NOTE: this is here for after the fact store connection (internal A but they dont connct a store)
      if(!store?.store_id){
        dispatch(getDialog({ dialogStatus: true, dialogType:'afterTheFactSignup', dialogData: '' }));
        return allProducts
      }
      const allProducts = await fetchAllProducts(store.store_id, jwt, admin.adminStatus);
      if(allProducts.statusCode !== 200) { 
        // NOTE: throw new Error causes render loop
        // throw new Error(`Unable To Set Ecommerce Products`) 
        return false
      }
      return allProducts.search_response;

    } catch (error) {
      console.log(error);
      return error;
    }
  }
};

// **************************************************************************
// TODO: Get rid of this in the future. Its literally the same as setVariantsByProductId
// DESCRIPTION: 2023 Get Ecommerce Variants of Many Product
// IMPORTANT: 
// ALSO: 
// QUEREY: product_id 
// **************************************************************************
export function setEcommerceProductVariantMulti({ productIDs }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      
      const jwt = getJWTstorage(admin, user.uuid);
      
      const allEcommerceProductVariants = [];

      await Promise.all(
        productIDs.map(async(productID) => {
          const ecommerceVariants = await fetchProductWithVariants(productID, store.store_id, jwt, admin.adminStatus);

          if(ecommerceVariants.statusCode === 200) {
              allEcommerceProductVariants.push(
                {
                  [productID]: ecommerceVariants.search_response
                }
              )
            }
        })
      );

      return allEcommerceProductVariants;

    } catch (error) {
      console.log('Error Fetching Multi Ecommerce Product Variants', error);
      dispatch(hasError(error));
      return error;
    }
  }
};

// **************************************************************************
// TODO: Get rid of this in the future. Its literally the same as setVariantsByProductId
// DESCRIPTION: 2023 Get Ecommerce Variants of Many Product
// IMPORTANT: 
// ALSO: 
// QUEREY: product_id 
// **************************************************************************
export function setEcommerceVariantsByProductId({ productIDs }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      
      const jwt = getJWTstorage(admin, user.uuid);
      
      const allEcommerceProductVariants = [];

      await Promise.all(
        productIDs.map(async(productID) => {
          const ecommerceVariants = await fetchVariantsByProductId(productID, store.store_id, jwt, admin.adminStatus);

          if(ecommerceVariants.statusCode === 200) {
              allEcommerceProductVariants.push(...ecommerceVariants.search_response)
            }
        })
      );

      return allEcommerceProductVariants;

    } catch (error) {
      console.log('Error Fetching Multi Ecommerce Product Variants', error);
      dispatch(hasError(error));
      return error;
    }
  }
};

// This is used to see variants of a loadout
// This is only for admin table (THERE IS NO STORE ID IF YOU ARE NOT IMPERSONATING) <<<<<<<-------
export function setEcommerceVariantsForLoadout({ productIDs, variantIDs, storeID }) {
  return async (dispatch, getState) => {

    try {
      const { account: { user }, admin } = getState();
      
      const jwt = getJWTstorage(admin, user.uuid);
      
      const allEcommerceProductVariants = [];

      await Promise.all(
        productIDs.map(async(productID) => {
          const ecommerceVariants = await fetchProductWithVariants(productID, storeID, jwt, admin.adminStatus);

          if(ecommerceVariants.statusCode === 200) {

            // Pull up from search response
            const res = ecommerceVariants.search_response

            const onlyConnectedVars = res?.variants.map((variant) => {

              // Filter out if the variant is not in the loadout
              if(variantIDs.includes(`${storeID}_${res.product_id_raw}_${variant.id}`)) {

                // Connect variants to images
                const imageURL = res.images.find(image => {
                  if(image.variant_ids.includes(parseInt(variant.id, 10))) {
                    return image.src
                  }
                  return null
                });

                // Assign an image URL
                if(!variant?.image_url) {
                  variant.image_url = imageURL ?? res.image_url
                }
                
                // Give it a store link
                variant.product_title = res.product_title
                variant.referer = res.referer
                return variant
              }
              return null
            }).filter(item => item !== null);
            
            // Push it into the list with product id as key
            allEcommerceProductVariants.push(...onlyConnectedVars);
          }
        })
      );

      return allEcommerceProductVariants;

    } catch (error) {
      console.log('Error Fetching Ecommerce Variants for Loadout thunk', error);
      dispatch(hasError(error));
      return error;
    }
  }
};