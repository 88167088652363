import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  dialogStatus: false,
  dialogType: '',
  dialogData: '',
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // SET DIALOG OPEN
    getDialog(state, action) {
      state.dialogStatus = action.payload.dialogStatus;
      state.dialogType = action.payload.dialogType;
      state.dialogData = action.payload.dialogData;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getDialog,
} = slice.actions;
