import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// Redux
import { useSelector, useDispatch } from '../../redux/store';
import {
  getIntegrationSuccess, 
  getStoreSuccess, 
} from '../../redux/slices/account';
// import { setCreateProductVariantFromCSV } from '../../redux/thunks/ecommVariantThunks';
import { setCustomStoreUploaderComplete } from '../../redux/thunks/loginThunks';

import { slackHandleUserUploadedCSVMessage } from '../../redux/thunks/slackThunks';
// Components
import Iconify from '../Iconify';
import { 
  FormProvider, 
  RHFUploadSingleFile, 
  RHFTextField
} from '../hook-form';
// Utils
// import { s3Connector } from '../../utils/s3Connector';
import { delay } from '../../utils/levarHelpers';
import { PATH_DASHBOARD } from '../../routes/paths';

CustomStoreCreationDialog.propTypes = {
  onClose: PropTypes.func,
}


export default function CustomStoreCreationDialog({ onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStore, setSelectedStore] = useState(null);
  const { dialogData } = useSelector((state) => state.dialog);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultValues = { 
    csvFile: [], 
    step: 0,
    csvValidated: false, 
    exampleDownloaded: false, 
    parsingInProgress: false,
    storeName: '', // Add this line
    storeURL: '' // Add this line
  };
  
  const CSVSchema = Yup.object().shape({
    csvFile: Yup.array().min(1, 'CSV file is required').max(1, 'Only one CSV file is allowed'),
    step: Yup.number(),
    csvValidated: Yup.bool(),
    exampleDownloaded: Yup.bool(),
    parsingInProgress: Yup.bool(),
    storeName: Yup.string().required('Store Name is required'), // Add this line
    storeURL: Yup.string().url('Must be a valid URL').required('Store URL is required') // Add this line
  });

  const methods = useForm({ resolver: yupResolver(CSVSchema), defaultValues });
  const { setValue, watch, reset, handleSubmit, formState: { isSubmitting } } = methods;
  const values = watch();

  React.useEffect(() => {
    if (dialogData && dialogData.step) {
      setValue('step', dialogData.step);
    }
  }, [dialogData, setValue]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setValue('csvFile', [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue]
  );

  const handleRemove = () => {
    setValue('csvFile', []);
    setValue('csvValidated', false);
    setValue('exampleDownloaded', false);
    setValue('parsingInProgress', false);
  };

  const onSubmit = async (values) => {
    try {
      setValue('parsingInProgress', true);

      const response = await dispatch(setCustomStoreUploaderComplete({ data: values }));
      console.log('no here')
      enqueueSnackbar('CSV parsing in progress', { variant: 'info' });
      await delay(60000); // Delay for a minute to let products and variants get created.
      await dispatch(slackHandleUserUploadedCSVMessage())
      sessionStorage.removeItem('shopType');
      dispatch(getIntegrationSuccess({ ...response.updatedIntegration }));
      dispatch(getStoreSuccess({ ...response.updatedStore }));
    } catch (error) {
      console.log("error", error)
    } finally {
      enqueueSnackbar('CSV Import Complete', { variant: 'success' });
      onClose();
    }
  };

  const handleDownloadExample = () => {
    window.open("https://levar-integration-csv-imports.s3.amazonaws.com/levar-mfr-example-csv.csv", '_blank');
    setValue('exampleDownloaded', true);
    enqueueSnackbar('Example CSV downloaded', { variant: 'success' });
  };

  const chooseStoreType = () => {
    if(selectedStore === 'shopify') {
      onClose();
      navigate(PATH_DASHBOARD.store.overview, { replace: true })
    } else if(selectedStore === 'custom') {
      setValue('step', 1); // Set step to 1
    }
  }

  const chooseStoreName = () => {
    setValue('step', 2); // Set step to 2
  }

  const backButton = () => {
    setValue('step', 1); // Set step to 2
  }

  if(values.parsingInProgress) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="space-evenly" sx={{ width: '100%', height: 220 }}>
        <Card sx={{ width: '100%', height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'flex-start', p: 3 }}>
          <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={1} sx={{ height: '100%' }}>
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <Typography variant="h4">Store Creation in Progress</Typography>
              <Typography variant="subtitle1">Sit tight, this will only take a few minutes</Typography>
            </Stack>
            <Box sx={{ width: '100%', mb: 2 }}>
              <LinearProgress variant="indeterminate" color="info" sx={{ width: '100%' }} />
            </Box>
            <Typography variant="caption">This dialog will close automatically when the import is complete</Typography>
          </Stack>
        </Card>
      </Stack>
    )
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-evenly" sx={{ width: '100%', height: '100%', p: 1 }}>
      <FormProvider methods={methods}>

        {/* STEP 0 */}
        {values.step === 0 && (
        <Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="h4">Welcome to LEVAR</Typography>
            <Typography variant="body1" align='center'>
              {selectedStore === 'shopify' && 'You have selected Shopify as your store.'}
              {selectedStore === 'custom' && 'You have selected Custom Store.'}
              {!selectedStore && <>We did not find any store or products. <br/>Please initiate the process by selecting your store type.</>}
            </Typography>
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: 400 }}>
              <Button 
                variant="contained" 
                size="large" 
                sx={{ m: 1, height: '100%', flexGrow: 1, backgroundColor: selectedStore === 'custom' ? 'primary.main' : 'grey.500' }}
                onClick={() => setSelectedStore('custom')}
              >
                <Stack direction="column" alignItems="center">
                  <Iconify icon={'material-symbols:store'} width={75} height={75} />
                  <Typography variant='subtitle1' sx={{ my: 1 }}>Custom Store</Typography>
                </Stack>
              </Button>
              <Button 
                variant="contained" 
                size="large" 
                sx={{ m: 1, height: '100%', flexGrow: 1, backgroundColor: selectedStore === 'shopify' ? 'primary.main' : 'grey.500' }}
                onClick={() => setSelectedStore('shopify')}
              >
                <Stack direction="column" alignItems="center">
                  <Iconify icon={'simple-icons:shopify'} width={75} height={75} />
                  <Typography variant='subtitle1' sx={{ my: 1 }}>Shopify</Typography>
                </Stack>
              </Button>
            </Box>
          </Box>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={onClose}>Exit</Button>
            <Button 
              variant="contained" 
              size="medium" 
              disabled={!selectedStore}
              onClick={chooseStoreType}
            >
              {selectedStore === 'shopify' && 'Integrate Shopify Store'}
              {selectedStore === 'custom' && 'Custom Store'}
              {!selectedStore && 'Choose Store Type'}
            </Button>
          </Stack>

        </Box>
        )}

        {/* STEP 1 */}
        {values.step === 1 && (
        <Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="h4">Custom Store</Typography>
            <Typography variant='body1'>
              Please choose a Store Title and URL
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <Stack spacing={2} alignItems="center" direction="column" sx={{ width: '100%', minWidth: 300 }}>
              <RHFTextField name="storeName" type="text" label="Store Title" sx={{ width: '100%' }} />
              <RHFTextField name="storeURL" type="text" label="Store URL" sx={{ width: '100%' }} />
            </Stack>
          </Box>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={onClose}>Exit</Button>
            <Button 
              variant="contained" 
              size="medium" 
              disabled={!values.storeName} // Check if storeName is not filled in
              onClick={chooseStoreName}
            >
              {!values.storeName ? 'Enter Store Title' : 'Continue'}
            </Button>
          </Stack>
        </Box>
        )}

        {/* STEP 2 */}
        {values.step === 2 && (
        <Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="h4">Welcome to LevAR</Typography>
            <Typography variant='body1' sx={{ maxWidth: 500 }} align="center">
              Do you have a product catalog you'd like to upload? Download our sample CSV, fill in the columns with your product details, and upload it now!
            </Typography>
          </Stack>
          <Box sx={{ width: '100%', height: '100%', py: 3 }}>
            <Box position="relative">
              <RHFUploadSingleFile
                name="csvFile"
                acceptedFileTypes={{ 'text/csv': [] }}
                disabled={isSubmitting || values.csvFile.length > 0}
                maxSize={99999999} // 95 MB
                onDrop={handleDrop}
                onRemove={handleRemove}
                onUpload={() => console.log('ON UPLOAD')}
              />
              {(isSubmitting || values.csvFile.length > 0) && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: 1 }}
                  bgcolor="rgba(187, 255, 190, 0.5)"
                />
              )}
            </Box>
          </Box>

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Card sx={{ p: 2, width: '100%', borderRadius: 1 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Typography variant="body1">Download our example product CSV</Typography>
                <Button 
                  variant="outlined" 
                  onClick={handleDownloadExample} 
                  size="small" 
                  endIcon={<Iconify icon={values.exampleDownloaded ? "material-symbols-light:check-circle" : "material-symbols-light:sim-card-download-outline"} 
                  color={values.exampleDownloaded ? 'green' : 'gray'} />}
                >
                  Example CSV
                </Button>
              </Stack>
            </Card>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <div>
              <Button variant="outlined" onClick={backButton} sx={{ mr: 1 }} size="medium">Back</Button>
              <Button variant="outlined" onClick={() => setValue('csvFile', [])} size="medium" disabled={!values?.csvFile?.length}>Reset CSV</Button>
            </div>
            <Button 
              variant="contained" 
              // type="submit" 
              size="medium" 
              onClick={() => onSubmit(values)}
              disabled={!values?.csvFile?.length}
            >
                Create Custom Store
            </Button>
          </Stack>
        </Box>
        )}

      </FormProvider>
    </Stack>
  )
};