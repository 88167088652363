import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '../redux/store';
import { mainSignInDefault, mainSignInAccountOwner, mainSignInAdmin } from '../redux/thunks/loginThunks';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  // console.log("isAuthenticated", isAuthenticated)
  // console.log("isInitialized", isInitialized)
  // console.log("user", user)
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const dispatch = useDispatch();
  const { isReady } = useSelector(state => state.app);

  useEffect(() => {
    const handleLoginCheck = async () => {
      if(user.role === 'default') await dispatch(mainSignInDefault({ user }));
      if(user.role === 'account_owner') await dispatch(mainSignInAccountOwner({ user }));
      if(user.role === 'admin') await dispatch(mainSignInAdmin({ user }));
    };

    if(isAuthenticated) handleLoginCheck();
  }, [dispatch, isAuthenticated, user, isReady]);

  // if (!isInitialized || !isReady) {
  //   return <LoadingScreen />;
  // }

  // if (!isAuthenticated) {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   return <Login />;
  // }

  // return <>{children}</>;

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if(!isReady) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}