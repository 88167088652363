// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function IntegrationDialog() {

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h3" align="center" gutterBottom color="common.main" sx={{ mx: 4 }}>Integrations</Typography>
      <Typography variant="body1" sx={{ px: 2 }}>
        LEVAR provides easy integration solutions across your eCommerce marketing channels—from 
        email to SMS to social to advertising. By providing beautiful, detailed, and accurate product 
        visuals wherever your customers are, shoppers can inspect each product online and accurately 
        see them displayed in their space.
      </Typography>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">eCommerce Store</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          LEVAR integrates with Shopify and BigCommerce with just a few clicks. You can see you products and variants 
          in one place before selecting where to begin with AR (no uploading necessary!). Easily match your store's 
          theme and style with a custom implementation designed to maximize conversions.  
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">Email Marketing</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          LEVAR's email integration with Klaviyo helps businesses drive more email (and SMS) engagement by implementing dynamic 
          augmented reality and 3D product visualization in any email or flow you send. It's easy, fast, and effective!
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">SMS</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          SMS is fast becoming the best way to connect with your customers and put your products and promotions directly into their hands. 
          LEVAR provides an easy SMS solution so you can send better messages that get better results.  
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2 }}>
        <Typography variant="h6">Mobile</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          LEVAR's entire AR and 3D experience is optimized for mobile, unlike many other services. With LEVAR, your shoppers can view a product in 
          their space, change variants and add-to-cart, all without leaving their mobile device. 
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6">Social & Ads</Typography>
        <Typography variant="body1" sx={{ px: 2 }}>
          Augmented reality is fast becoming a major component in both social media and online advertising. Reach out to your onboarding specialist to 
          learn more about how you can add AR and 3D to your social and ads.
        </Typography>
      </Stack>
    </Stack>
  )
};