import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function Icon3D({ fill, ...other }) {
  return (
    <Box {...other}>
      <svg 
        fill={fill ?? "#000000"} 
        // height="800px" 
        // width="800px" 
        viewBox="0 0 457.478 457.478" 
        >
        <g>
          <path d="m423.173,110.709l-189.434-109.369c-3.094-1.786-6.906-1.786-10-3.33067e-15l-189.433,109.369c-3.094,1.786-5,5.087-5,8.66v218.739c0,3.573 1.906,6.874 5,8.66l189.434,109.37c1.547,0.893 3.273,1.34 5,1.34s3.453-0.447 5-1.34l189.434-109.37c3.094-1.786 5-5.087 5-8.66v-218.739c-0.001-3.572-1.908-6.874-5.001-8.66zm-15,206.884l-6.459-3.729c-4.781-2.762-10.898-1.123-13.66,3.66-2.762,4.783-1.123,10.899 3.66,13.661l9.226,5.327-162.201,93.647v-188.638c0.128,0.005 0.255,0.024 0.383,0.024 3.456,0 6.817-1.793 8.67-5.001 1.421-2.46 1.669-5.271 0.932-7.799l159.449-92.058v180.906zm-338.747-.069c-2.761-4.782-8.874-6.422-13.66-3.66l-6.46,3.729v-180.905l159.449,92.058c-0.737,2.527-0.488,5.338 0.932,7.798 1.853,3.208 5.213,5.001 8.67,5.001 0.127,0 0.255-0.02 0.383-0.024v188.637l-162.202-93.647 9.227-5.327c4.784-2.761 6.422-8.877 3.661-13.66zm159.314-275.941c5.522,2.84217e-14 10-4.477 10-10v-4.263l159.431,92.048-159.634,92.165c-0.931-4.559-4.964-7.989-9.797-7.989-4.834,0-8.867,3.43-9.798,7.99l-159.635-92.166 159.433-92.048v4.264c0,5.522 4.478,9.999 10,9.999z"/>
          <path d="m304.003,280.544l17.839,10.3c1.575,0.909 3.294,1.341 4.99,1.341 3.456,0 6.817-1.793 8.67-5.001 2.762-4.783 1.123-10.898-3.66-13.66l-17.839-10.3c-4.784-2.761-10.898-1.123-13.66,3.66s-1.123,10.898 3.66,13.66z"/>
          <path d="m260.147,255.224l17.84,10.299c1.575,0.91 3.294,1.341 4.99,1.341 3.456,0 6.818-1.793 8.67-5.001 2.762-4.783 1.123-10.899-3.66-13.66l-17.84-10.299c-4.784-2.763-10.899-1.123-13.66,3.66-2.762,4.783-1.123,10.899 3.66,13.66z"/>
          <path d="m347.857,305.864l17.84,10.3c1.575,0.909 3.294,1.341 4.99,1.341 3.456,0 6.818-1.793 8.67-5.001 2.762-4.783 1.123-10.899-3.66-13.66l-17.84-10.3c-4.784-2.762-10.9-1.123-13.66,3.66-2.762,4.783-1.123,10.899 3.66,13.66z"/>
          <path d="m174.501,266.865c1.696,0 3.416-0.432 4.99-1.341l17.84-10.3c4.783-2.761 6.422-8.877 3.66-13.66-2.761-4.783-8.877-6.421-13.66-3.66l-17.84,10.3c-4.783,2.761-6.422,8.877-3.66,13.66 1.852,3.209 5.213,5.001 8.67,5.001z"/>
          <path d="m86.791,317.505c1.696,0 3.415-0.432 4.99-1.341l17.84-10.299c4.783-2.761 6.422-8.877 3.66-13.66-2.76-4.782-8.874-6.421-13.66-3.66l-17.84,10.299c-4.783,2.761-6.422,8.877-3.66,13.66 1.852,3.208 5.213,5.001 8.67,5.001z"/>
          <path d="m130.646,292.185c1.696,0 3.416-0.432 4.99-1.341l17.839-10.3c4.783-2.762 6.422-8.877 3.66-13.66-2.761-4.783-8.877-6.421-13.66-3.66l-17.839,10.3c-4.783,2.762-6.422,8.877-3.66,13.66 1.853,3.208 5.213,5.001 8.67,5.001z"/>
          <path d="M218.74,82.223c0,5.523,4.478,10,10,10s10-4.477,10-10V61.624c0-5.523-4.478-10-10-10s-10,4.477-10,10V82.223z"/>
          <path d="m228.74,102.264c-5.522,0-10,4.477-10,10v20.599c0,5.523 4.478,10 10,10s10-4.477 10-10v-20.599c0-5.523-4.477-10-10-10z"/>
          <path d="m228.74,152.904c-5.522,0-10,4.477-10,10v20.599c0,5.523 4.478,10 10,10s10-4.477 10-10v-20.599c0-5.523-4.477-10-10-10z"/>
        </g>
      </svg>
    </Box>
  );
}

export default memo(Icon3D);