
export const LOADOUT_STATUS = [
  { value: 0, label: 'No Infomation Provided' },
  { value: 1, label: 'Loadout Ready To Send' },
  { value: 2, label: 'Loadout Needs Revisions' },
  { value: 3, label: 'Pending Artist Review' },
  { value: 4, label: 'Loadout Finalized' },
]

export const LOADOUT_STATUS_OPTIONS = [
  'all',
  'link products',
  'build loadout',
  'saved',
  'submitted',
  'needs revisions',
  'accepted',
];

export const PROJECT_STATUS_OPTIONS = [
  'all',
  'initialized',
  'in progress',
  'completed',
];

export const STORE_PLATFORM_OPTIONS = [
  'all',
  'shopify',
  'bigcommerce',
  'levar'
];

export const SUBSCRIPTION_PLANS = [
  [
    "STARTER",
    "$59/month",
    "3D/AR",
    "3D Configurator & Augmented Reality",
    "No code implementation",
    "Upload your own 3D Models",
    "Email & SMS API",
    "Unlimited Views",
    "Up to 20 Models"
  ],
  [
    "ESSENTIALS",
    "$99/month",
    "3D/AR",
    "3D Configurator & Augmented Reality",
    "No code implementation",
    "API to Email / SMS / Integrations",
    "4k/HD quality",
    "Animation",
    "Up to 40 Models"
  ],
  [
    "PRO",
    "$249/month",
    "Everything in Essentials",
    "White Glove Onboarding",
    "Custom CTA",
    "3D / AR Spotlight Viewer",
    "1000% ROI Guarantee",
    "Simple Sharing API",
    "Up to 100 Models"
  ]
];

// 2024
export const LEVAR_3D_MODEL_ID_TYPES = {
  model_3d_id: "",
  interactive_viewer_0: "",
  static_viewer_0: "",
  static_vto_face_0: "",
  interactive_vto_face_0: "",
  interactive_absolute_ground_0: "",
  interactive_absolute_wall_0: "",
  interactive_absolute_lighting_0: "",
  room_viewer_0: "",
}

// 2024
export const LEVAR_ECOMM_VARIANT_DEFAULT_RESET = {
  ar_experience_type: false, // null
  asset_theme: false, // null
  billboard_status: false, 
  custom_button: false,
  direct_qr: false,
  pdp_variant_switch: false,
  preload_viewer: false,
  translation_inactive: false,
  view_in_space_off: false,
  ...LEVAR_3D_MODEL_ID_TYPES
};

// variant Implementation Template
// export const ECOM_VARIANT_IMPLEMENTATION_TEMPLATE = {
//   ar_experience_type: null, // levar_try_on || null
//   asset_theme: null, // "levar_debut" || "levar_player" || null
//   billboard_status: false, 
//   custom_button: false, // true || false
//   direct_qr: false, // true || false
//   pdp_variant_switch: false,
//   preload_viewer: false,
//   translation_inactive: false, // true || false
//   view_in_space_off: false,
// };

// vList Implementation Template
export const V_LIST_IMPLEMENTATION_TEMPLATE = {
  ar_experience_type: null, // levar_try_on || null
  asset_theme: null, // "levar_debut" || "levar_player" || null
  billboard_list: null, 
  billboard_status: false, 
  custom_button: false, // true || false
  custom_text: null,
  direct_qr: false, // true || false
  levar_exit_url: null, 
  pdp_variant_switch: false,
  preload_viewer: false,
  translation_inactive: false, // true || false
  view_in_space_off: false,
};

// 2024 IMPLEMENTATION DISPLAY NAMES
export const IMPLEMENTATION_NAMES = {
  direct_qr: 'Direct QR Button',
  asset_theme: '3D Button',
  translation_inactive: 'Translation Off'
};

// 2024 DEFAULT Store Implementation Template
export const IMPLEMENTATION_STORE_DEFAULTS = {
  asset_theme: false, // "levar_debut" || "levar_player" || null
  direct_qr: false, // true || false (Modal)
  translation_inactive: false, // true || false
};

// 2024 Store Implementation Template
export const IMPLEMENTATION_STORE_DEFAULTS_FOR_CHECK = {
  asset_theme: null, // "levar_debut" || "levar_player" || null
  direct_qr: false, // true || false (Modal)
  translation_inactive: false, // true || false
};

// 2024 values for implementation Store Possible Implementation Values
export const IMPLEMENTATION_VALUES = {
  asset_theme: [
    {
      value: true,
      label: 'On',
    },
    {
      value: false,
      label: 'Off',
    }
  ],
  // asset_theme: [
  //   {
  //     value: '',
  //     label: 'RESET',
  //   },
  //   {
  //     value: 'levar_player',
  //     label: 'Player',
  //   },
  //   {
  //     value: 'levar_debut',
  //     label: 'Debut (Deprecated)',
  //   }
  // ],
  direct_qr: [
    {
      value: true,
      label: 'On',
    },
    {
      value: false,
      label: 'Off',
    }
  ]
};