import { 
  fetchApproveGhostVariant, // 2023
  fetchCreateGhostVariant, // 2023
  fetchUpdateGhostVariant, // 2023
  fetchDeleteModel, // 2023
  fetchCreateUSDZselfService, // 2023
  fetchCreateUSDZselfServiceTaskCHECK, // 2023
  fetchSingleGhostsByID, // 2023
  fetchCreateModel
} from '../../utils/fetchData';
import { getJWTstorage } from '../../utils/jwt'
import { generateUUID } from '../../utils/levarHelpers'
import { s3Connector, s3DataCopier } from '../../utils/s3Connector'


// **************************************************************************
// (GHOST => Create MODEL_3D)
// 1, Update Ghost Stage => (ghost_stage: 4)
// 2. Create Model Doc
// 3. Copy Ghost S3 Data => Model S3 Bucket
// **************************************************************************
export function setApproveGhostFinal({ ghostIDs, connectEcommerce }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      
      // Holds all good responses
      const variantsUpdated = [];
      
      // Multi variant
      if(Array.isArray(ghostIDs)) {
        await Promise.all(
          ghostIDs.map(async(item) => {
            const dataToUpdate = { 
              ghost_variant_id: item, 
              store_id: store.store_id,
              connect_ecommerce: connectEcommerce
            };
            const response = await fetchApproveGhostVariant(dataToUpdate, jwt);
            if(response.statusCode === 200) variantsUpdated.push(response);
          })
        );
        return variantsUpdated;
      }

      // Single variant
      const dataToUpdate = {
        ghost_variant_id: ghostIDs,
        store_id: store.store_id,
        connect_ecommerce: connectEcommerce
      };
      
      const ghostVariantApprovalRes = await fetchApproveGhostVariant(dataToUpdate, jwt);
      
      return ghostVariantApprovalRes;
      
    } catch(error) {
      console.log('error on setApproveGhostFinal thunk', error);
      return error;
    }
  }
};

// **************************************************************************
// Connects Remove model ID from ghost and delete the model
// Input: 
//    GhostID => ghost_variant_id 
//    ModelID => model_3d_id
// Output: Ecom Variants with that model id
// **************************************************************************
export function setRemoveModelFromGhost({ GhostID, ModelID }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      
      const ghostPayload = {
        ghost_variant_id: GhostID,
        model_3d_id: "",
        ghost_stage: 2
      };

      const modelPayload = {
        model_3d_id: ModelID
      };

      const updateGhost = await fetchUpdateGhostVariant(ghostPayload, jwt);
      const deleteModel = await fetchDeleteModel(modelPayload, jwt);
      
      if(updateGhost.statusCode > 399 && deleteModel.statusCode > 399) {
        return false;
      }
      return true;
      
    } catch(error) {
      console.log('error on setRemoveModelFromGhost thunk', error);
      return error;
    }
  }
};


// **************************************************************************
// Creates a Ghost variant for the glb's self service clients upload
// Input: 
//    ardtFileName => the literal file name of the glb uploaded
//    bundleId => bundle id generated on upload of glb
//    loadoutId => generated on upload
//    ecomProductVariantTitle => the title of the first variant in the list to connect
//    ecomVariantId => the first ecom variants id
// Output: Creates an entry in the ghost index 
// **************************************************************************
export function setCreateGhostSelfServiceUpload({ 
  ardtFileName, 
  geoFileName,
  bundleId, 
  loadoutId, 
  title, 
  ecomVariantId 
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      
      const ghostPayload = {
        store_id: store.store_id,
        ardt: `ardt_${ardtFileName}`,
        geo: `${geoFileName}`,
        bundle: `${bundleId}`,
        loadout_id: `${loadoutId}`,
        ghost_client_title: title,
        ghost_title: title,
        geo_type: 2, // packed glb
        model_type: "static_viewer_0", // model viewer
        ghost_stage: 4,
        materials: null,
        orbit_controls_angles: [0, 3.14, -1, -1],
        self_service: true,
        ...(ecomVariantId && { ecommerce_variant_id: ecomVariantId}),
      };

      const createdGhost = await fetchCreateGhostVariant(ghostPayload, jwt);

      // const modelPayload = {        
      //   ghost_variant_id: createdGhost._id  
      // }
      // const createdModel = await fetchCreateModel(modelPayload, jwt)
      // const ssCreateResponse = await fetchCreateUSDZselfService(ardtFileName, store.store_id, store.store_title, bundleId);
      
      return { createdGhost };
      // return { createdGhost, ssCreateResponse };
      
    } catch(error) {
      console.log('error on setCreateGhostSelfServiceUpload thunk', error);
      return error;
    }
  }
};


// **************************************************************************
// GLB TO Ghost To Model Full
// FULL PROCESS UPLOAD MODEL VIEWER
// **************************************************************************
export function setCreateGhostModelUploadFullSelfService({ 
  file2Upload,
  ardtFileName, 
  loadoutId, 
  modelTitle, 
  ecomVariantId,
  lightFixtureCheck
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const bundleUUID = generateUUID();
      const geoFileNameCleaned = modelTitle.replace(/[^a-zA-Z0-9]/g, '_');

      const originalFile = file2Upload;
      const blob = originalFile.slice(0, originalFile.size, originalFile.type); 

      const glbFile = new File([blob], `${geoFileNameCleaned}.glb`, {type: originalFile.type});
      await s3Connector([glbFile], 'ghost-model', `ghosts/${store.store_id}/${bundleUUID}`);
      
      const ghostPayload = {
        store_id: store.store_id,
        ardt: `ardt_${ardtFileName}`,
        geo: `${geoFileNameCleaned}`,
        bundle: `${bundleUUID}`,
        loadout_id: `${loadoutId}`,
        ghost_client_title: `${modelTitle} Self Service`,
        ghost_title: modelTitle,
        geo_type: 2, // packed glb
        model_type: lightFixtureCheck ? "interactive_absolute_wall_0" : "static_viewer_0", // light fixture upload (fiborous and 8th) || model viewer 
        ghost_stage: 4,
        materials: null,
        orbit_controls_angles: [0, 3.14, -1, -1],
        self_service: true,
        ...(ecomVariantId && { ecommerce_variant_id: ecomVariantId}),
      };

      const createdGhost = await fetchCreateGhostVariant(ghostPayload, jwt);

      await s3Connector([glbFile], 'ghost-model', `models/${store.store_id}/${bundleUUID}`);

      const modelPayload = {        
        ghost_variant_id: createdGhost._id,
        model_status: "on",
      }

      const createdModel = await fetchCreateModel(modelPayload, jwt)
      
      return { createdModel };
    } catch(error) {
      console.log('error on setCreateGhostSelfServiceUpload thunk', error);
      return error;
    }
  }
};





// **************************************************************************
// Duplicate a Ghost From One Store To Another Forbidden Admin Tool
// Input values: 
//    ogGhostID => the literal file name of the glb uploaded
//    storeID => store id of store B one being copied to
//    loadoutId => loadout_id of store b
//    ecomVariantID => ecommerce_variant_id on store b
// Output: Creates a new ghost copy into another store 
// **************************************************************************
export function setDuplicateGhostAdminForbiddenTool({ values }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // 1. fetch current live ghost with Ghost ID
      const ghostResponse = await fetchSingleGhostsByID(values.ghostID, jwt);
      if(ghostResponse.statusCode > 399) { throw new Error("Ghost Doesnt exist") }

      // 2. clean the fetched ghost data
      const ogGhostPayload = ghostResponse.ghost_search_response_hits_source[values.ghostID]
      delete ogGhostPayload.ghost_variant_id
      delete ogGhostPayload.model_3d_id
      delete ogGhostPayload.model_3d_id_updated_at
      delete ogGhostPayload.updated_at
      delete ogGhostPayload.created_at

      // OG frame my tv
      // Store A: shp_1162084407
      // ogGhostID: f35ee9a5-4716-4f98-b9a5-1157d23a78d3

      // NEW deco tv frames
      // Store B: shp_24828969005
      // loadout id: c6e17676-2700-4193-9c73-b02abc891409

      // ------------------------------------
      // OG levar-test-store-4
      // Store A: shp_43587502230
      // *ogGhostID: 193ecfd2-d449-451c-b088-274a1367803e
      // OGLoadout: 071686af-981a-4e92-a057-782b13b77997
      // OGBundle: bundle_0GKIPFu0dEXtOX
      // OGEcommVariant: shp_43587502230_6541671694486_39264579518614

      // NEW levar-test-store-3
      // *Store B: shp_31476744237
      // *loadout_id: e6b7e7de-7239-4422-9c39-093fd88e2286
      // *ecommerce_variant_id: shp_31476744237_4529667113005_32077606715437
      
      // 3. then write in new payload with store id / loadout id / ecommerce_variant_id*
      const newGhostPayload = {
        ...ogGhostPayload,
        ghost_title: `COPY ${ogGhostPayload.ghost_title}`,
        ghost_client_title: `COPY ${ogGhostPayload.ghost_client_title}`,
        ghost_stage: 2,
        store_id: values.storeID,
        loadout_id: values.loadoutID,
        ...(values.ecomVariantID !== '' && { ecommerce_variant_id: values.ecomVariantID}),
        // ecommerce_variant_id: values.ecomVariantID,
        bundle: generateUUID()
      };
      
      // 4. then call fetchCreateGhostVariant with new payload
      await fetchCreateGhostVariant(newGhostPayload, jwt);

      // 5. Copy S3 contents from og bundle folder to new bundle folder
      const ogGhostFolder = `ghosts/${ogGhostPayload.store_id}/${ogGhostPayload.bundle}`;
      const newGhostFolder = `ghosts/${newGhostPayload.store_id}/${newGhostPayload.bundle}`;
      
      await s3DataCopier(ogGhostFolder, newGhostFolder, 'ghost-model', false)

      return true
      
    } catch(error) {
      console.log('error on setDuplicateGhostAdminForbiddenTool thunk', error);
      return error.message;
    }
  }
};



// **************************************************************************
// Checks the satus of USDZ creation for a given task_id
// Input: 
//    taskID => the literal file name of the glb uploaded
// Output: Creates an entry in the ghost index 
// **************************************************************************
export function setCheckSelfServiceTaskStatus({ taskID }) {
  return async (dispatch, getState) => {
    try {
      const createStatusArray = [];
      
      await Promise.all(
        taskID.map(async (taskID) => {
          const statusRes = await fetchCreateUSDZselfServiceTaskCHECK(taskID);
          createStatusArray.push(statusRes.response.status);
        })
      );
      
      const overAllCheck = createStatusArray.every(status => status === "FINISHED");
      
      const howManyDone = createStatusArray.reduce((a, v) => (v === "FINISHED" ? a + 1 : a), 0);

      // NOTE: keep these logs
      console.log("taskID up", howManyDone / taskID.length) // 1
      console.log("overAllCheck", overAllCheck) 
      console.log("doneCount", howManyDone) 
      return { overAllCheck, doneCount: howManyDone / taskID.length };
      
    } catch(error) {
      console.log('error on setCheckSelfServiceTaskStatus thunk', error);
      return error;
    }
  }
};



export function setUpdateGhostVariant({ ghostID, fieldsToUpdate }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const updatedGhosts = [];

      if(Array.isArray(ghostID)) {
        await Promise.all(
          ghostID.map(async(item) => {
            const dataToUpdate = { 
              ...fieldsToUpdate,
              ghost_variant_id: item
            };
            const response = await fetchUpdateGhostVariant(dataToUpdate, jwt);
            if(response.statusCode === 200) updatedGhosts.push(response);
          })
        );
        return updatedGhosts;
      }

      fieldsToUpdate.ghost_variant_id = ghostID
      const updatedGhost = await fetchUpdateGhostVariant(fieldsToUpdate, jwt);
      return updatedGhost;
    } catch(error) {
      console.log('error on setUpdateGhostVariant thunk', error);
      return error;
    }
  }
}

