
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// Components
import { DialogAnimate } from '../animate';
// dialog sections
import CartCheckoutDialog from './CartCheckoutDialog';
import AfterTheFactSignupDialogForm from './AfterTheFactSignupDialogForm';
import IFrameDialog from './IFrameDialog';
import IntegrationDialog from './IntegrationDialog';
import CaseStudyDialog from './CaseStudyDialog';
import FAQDialog from './FAQDialog';
import SOWDialog from './SOWDialog';
import PayUsNowDialog from './PayUsNowDialog';
import VariantImplementationsDialog from './VariantImplementationsDialog';
import VariantOptionsDialog from './VariantOptionsDialog';
import ModelImplementationsDialog from './ModelImplementationsDialog';
import AdminEditStoreDialog from './AdminEditStoreDialog/AdminEditStoreDialog';
import AdminArtToolsDialog from './AdminArtToolsDialog/AdminArtToolsDialog';
import ProductCSVUploadDialog from './ProductCSVUploadDialog';
import CustomStoreCreationDialog from './CustomStoreCreationDialog';


// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  }
}));

// ----------------------------------------------------------------------

DialogController.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  dialogType: PropTypes.string,
  dialogData: PropTypes.any
};

export default function DialogController({ onClose, open, dialogType, dialogData }) {

  const blockClickAway = dialogType === 'customStoreCreationDialog';

  const handleModalType = () => {
    switch(dialogType) {
      case 'playground':
        return (
          <Box sx={{ p: 0, minWidth: 1200, margin: 'auto' }}>
            <IFrameDialog dialogData={dialogData} />
          </Box>
        )
      case 'iframeDefault':
        return (
          <Box sx={{ p: 0, minWidth: 900, margin: 'auto' }}>
            <IFrameDialog dialogData={dialogData} />
          </Box>
        )
      case 'sowDialog':
        return (
          <Box sx={{ p: 2, minWidth: 900, margin: 'auto' }}>
            <SOWDialog dialogData={dialogData} />
          </Box>
        )        
      case 'integrationDialog':
        return (
          <Box sx={{ p: 2, maxWidth: 1000, margin: 'auto' }}>
            <IntegrationDialog />
          </Box>
        )
      case 'casestudyDialog':
        return (
          <Box sx={{ p: 2, maxWidth: 900, margin: 'auto' }}>
            <CaseStudyDialog />
          </Box>
        )        
      case 'afterTheFactSignup':
        return (
          <Box sx={{ p: 2, minWidth: 400, margin: 'auto' }}>
            <AfterTheFactSignupDialogForm onClose={onClose} />
          </Box>
        )
      case 'faqDialog':
        return (
          <Box sx={{ p: 2, maxWidth: 900, margin: 'auto' }}>
            <FAQDialog />
          </Box>
        )
      case 'unpaid-invoice':
        return (
          <Box sx={{ p: 2, minWidth: 400, margin: 'auto' }}>
            <PayUsNowDialog dialogData={dialogData} onClose={onClose} />
          </Box>
        )
      case 'cartCheckoutDialog':
        return (
          <Box sx={{ p: 2, minWidth: 1100, margin: 'auto' }}>
            <CartCheckoutDialog onClose={onClose} />
          </Box>
        )     
      case 'variantImplementationsDialog':
        return (
          <Box sx={{ p: 2, minWidth: 700, maxWidth: 800, margin: 'auto' }}>
            <VariantImplementationsDialog onClose={onClose} dialogData={dialogData} />
          </Box>
        )
      case 'variantOptionsDialog':
        return (
          <Box sx={{ p: 2, minWidth: 700, maxWidth: 800, margin: 'auto' }}>
            <VariantOptionsDialog onClose={onClose} dialogData={dialogData} />
          </Box>
        )
      case 'modelImplementationsDialog':
        return (
          <Box sx={{ p: 2, minWidth: 700, maxWidth: 800, margin: 'auto' }}>
            <ModelImplementationsDialog onClose={onClose} dialogData={dialogData} />
          </Box>
        )
      case 'adminEditStoreDialog':
        return (
          <Box sx={{ p: 2, minWidth: 800, maxWidth: 900, margin: 'auto' }}>
            <AdminEditStoreDialog onClose={onClose} dialogData={dialogData} />
          </Box>
        )
      case 'adminArtToolsDialog':
        return (
          <Box sx={{ p: 2, minWidth: 800, maxWidth: 900, margin: 'auto' }}>
            <AdminArtToolsDialog onClose={onClose} dialogData={dialogData} />
          </Box>
        )
      case 'productCSVUploadDialog':
        return (
          <Box sx={{ p: 2, minWidth: 700, maxWidth: 800, margin: 'auto' }}>
            <ProductCSVUploadDialog onClose={onClose} />
          </Box>
        )
      case 'customStoreCreationDialog':
        return (
          <Box sx={{ p: 2, minWidth: 700, maxWidth: 800, margin: 'auto' }}>
            <CustomStoreCreationDialog onClose={onClose} />
          </Box>
        )
      default:
        return <div>No Info</div>
    }
  };

  return (
    <DialogStyle 
      open={open} 
      fullWidth={false} 
      onClose={blockClickAway ? (e, reason) => reason !== undefined && onClose : onClose}
    >
      { handleModalType() }
    </DialogStyle>
  );
}