import { v4 } from 'uuid';
import { 
  V_LIST_IMPLEMENTATION_TEMPLATE, 
  IMPLEMENTATION_STORE_DEFAULTS_FOR_CHECK,
  IMPLEMENTATION_STORE_DEFAULTS
} from './levar_data';
import { 
  fetchSingleProduct, // 2023
} from './fetchData';
import { getJWTstorage } from './jwt';

const { REACT_APP_VIEWER_URL, REACT_APP_8TH_WALL, REACT_APP_MODEL_VIEWER } = process.env;


// interactive_absolute_ground_0
// interactive_absolute_wall_0
// interactive_absolute_lighting_0

export const determineModelTypeInfo = (modelType) => {
  let info = { text: "Standard", url: "defaultURL" }; // Default values

  switch(modelType) {
    case 'static_viewer_0':
      info = { text: "Basic", url: REACT_APP_MODEL_VIEWER };
      break;
    case 'interactive_viewer_0':
      info = { text: "Standard", url: REACT_APP_VIEWER_URL };
      break;
    case 'static_vto_face_0':
      info = { text: "VTO", url: REACT_APP_8TH_WALL };
      break;
    case 'interactive_vto_face_0':
      info = { text: "VTO Interactive", url: "vtoIntURL" };
      break;
    case 'interactive_absolute_ground_0':
      info = { text: "Ground", url: "vtoIntURL" };
      break;    
    case 'interactive_absolute_wall_0':
      info = { text: "Wall", url: "vtoIntURL" };
      break;  
    case 'interactive_absolute_lighting_0':
      info = { text: "Lighting", url: "vtoIntURL" };
      break;  
    case 'room_viewer_0':
      info = { text: "Room Viewer", url: REACT_APP_VIEWER_URL };
      break;         
    default:
      info = { text: "Standard", url: REACT_APP_VIEWER_URL };
      break;
  }

  return info;
};

export const generateUUID = () => v4();

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// Delay function just to give timed await
export const delay = ms => new Promise(res => setTimeout(res, ms));

export const getRandomNumber = (max) => {
  const x = Math.floor(Math.random() * max)
  return x
}

// Tells you if every item in the array is eqaul
export const allEqual = arr => arr.every(val => val === arr[0]);

export const arraysEqual = (a, b) => {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i+=1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const findLastObjectWithKey = (array, key, value) => {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i >= 0; i--) {
    // eslint-disable-next-line no-prototype-builtins
    if (array[i].hasOwnProperty(key) && array[i][key] === value) {
      return array[i];
    }
  }
  return null; // Return null if no match is found
};

export const getStoreType = (storeTypeValue) => {
  let wantedValue;
  switch(storeTypeValue){
    case 'shp':
      wantedValue = 1;
      break;
    case 'cus':
      wantedValue = 2;
      break;
    case 'bco':
      wantedValue = 3;
      break;
    case 1:
      wantedValue = 'shp';
      break;
    case 2:
      wantedValue = 'cus';
      break;
    case 3:
      wantedValue = 'bco';
      break;
    default:
      break;
  }
  return wantedValue;
};

// Open Link in new tab
export const handleOpenTab = (href) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href,
  }).click();
};

// Cleaner Tool For Variant Implementation Copying
export const ecommerceVariantImplementationCopyHelper = (currentEcommVariant) => {
  const cleanedData = {};

  Object.keys(V_LIST_IMPLEMENTATION_TEMPLATE).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(currentEcommVariant, key)) {
      cleanedData[key] = currentEcommVariant[key];
    }
  });

  return cleanedData;
};

// // Find the fields that have been changed from default on a given variant obj and return those fields
// export const ecommerceVariantImplementationDifferences = (ogVariant) => {
//   const diff = Object.keys(ECOM_VARIANT_IMPLEMENTATION_TEMPLATE).reduce((acc, key) => {
//     // Adjusted condition to treat null and false as equivalent
//     const ogValue = ogVariant[key];
//     const templateValue = ECOM_VARIANT_IMPLEMENTATION_TEMPLATE[key];
//     const isDifferent = (ogValue !== templateValue) && !(ogValue === null && templateValue === false) && !(ogValue === false && templateValue === null);
//     const isNotNone = ogValue !== "None";

//     if (Object.prototype.hasOwnProperty.call(ogVariant, key) && isDifferent && isNotNone) {
//       acc[key] = ogValue;
//     }
//     return acc;
//   }, {});

//   if (Object.keys(diff).length < 1) return false;
//   return diff;
// };

// Find the fields that have been changed from default on a given variant obj and return those fields
export const vListImplementationDifferences = (ogVariant) => {
 const diff = Object.keys(V_LIST_IMPLEMENTATION_TEMPLATE).reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(ogVariant, key) && (ogVariant[key] !== V_LIST_IMPLEMENTATION_TEMPLATE[key] && ogVariant[key] !== "None")) {
        acc[key] = ogVariant[key];
      }
      return acc;
  }, {});

  if(Object.keys(diff).length < 1) return false;
  return diff;
};
  

// Cleaner Tool For Variant Implementation Copying
export const storeImplementationCopyHelper = (checkedImplementationValues, storeImplementation) => {
  let cleanedData = {};
  if(storeImplementation) {
    Object.keys(storeImplementation).forEach((key) => {
      if (checkedImplementationValues[key]) {
        cleanedData[key] = storeImplementation[key];
      } else {
        cleanedData[key] = IMPLEMENTATION_STORE_DEFAULTS[key];
      }
    });
  } else {
    cleanedData = IMPLEMENTATION_STORE_DEFAULTS;
  }
  return cleanedData;
};

// 
function sortObjectByKey(obj) {
  const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  return Object.fromEntries(sortedEntries);
};

// Used for naming the form checkboxes
// const IMPLEMENTATION_NAMES = {
//   levar_player: '3D Button',
//   direct_qr: 'Direct QR Button',
//   levar_debut: 'Levar Debut (Deprecated)',
//   asset_theme: 'Asset Theme',
//   translation_inactive: 'Translation Off'
// };

// // 2024
// // Find differing values in the storesImplementations object and return those with key, value, name
// export const storeImplementationDifferingValues = (storeImplementationObj) => {
//   const sortedEntries1 = Object.entries(storeImplementationObj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
//   const sortedEntries2 = Object.entries(IMPLEMENTATION_STORE_DEFAULTS_FOR_CHECK).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

//   const sortedObject1 = Object.fromEntries(sortedEntries1);
//   const sortedObject2 = Object.fromEntries(sortedEntries2);

//   const differingValues = Object.entries(sortedObject1).reduce((result, [key, value]) => {
//     if (sortedObject2[key] !== value && value !== false) {
//       result.push({ key, value, name: IMPLEMENTATION_NAMES[value] ?? IMPLEMENTATION_NAMES[key]});
//     }
//     return result;
//   }, []);

//   return differingValues;
// };

// Check if the values on the store are the same as default values (true if default)
export const storeImplementationCheckIsDefault = (storeImplementationObj) => {
  const stringifiedObj1 = JSON.stringify(sortObjectByKey(storeImplementationObj));
  const stringifiedObj2 = JSON.stringify(sortObjectByKey(IMPLEMENTATION_STORE_DEFAULTS_FOR_CHECK));

  return stringifiedObj1 === stringifiedObj2;
};


// TODO: come back to this its kind of interesting 
// https://www.redbitdev.com/post/conditional-logging-to-the-javascript-console
// export const createDebugConsole = (consoleObj, options = {}) => {
//   const nextConsoleObj = { ...consoleObj };
//   const { enabled = true } = options;
//   const keys = Object.keys(nextConsoleObj);
//   const values = Object.values(nextConsoleObj)
//   let key;


//   /* eslint-disable no-prototype-builtins */
//   if (keys.indexOf(key) && typeof nextConsoleObj[key] === 'function') {
//     const func = nextConsoleObj[key];
//     nextConsoleObj[key] = function () {
//       if (enabled) {
//         func.apply(nextConsoleObj, arguments);
//       }
//     };
//   }
//   /* eslint-enable no-prototype-builtins */


//   return nextConsoleObj;
// };

// Variant Summarizer
const summarizeVariants = async (info, variantIDsList) => {
  let variantText = "";

  variantText += `
  <p><strong>3D Variants of Product Requesting:</strong></p>
  <ol>`;
  
  info.search_response.variants.map(async (variant) => {
    const variantID = variant.variant_id;
    if(variantIDsList.includes(variantID)) {
      variantText += `<li><strong>${variant.title}</strong> - (<i>add dimensions</i>)</li>`;
    }
  });

  variantText += `</ol>
  <p><br></p>`;

  return variantText;
};

// STEP 1: Build a Simple Template (Only Products API Call)
export const buildTemplateSimple = async (linkedProducts, admin, user) => {
  let loadout = `
  <strong><i>----------------Please Confirm / Edit All Information Below-----------------</i></strong>
  <p><br></p>
  `;

  const jwt = getJWTstorage(admin, user.uuid);
  await Promise.all(
    linkedProducts.map(async (product) => {
      let productText = "";
      const variantIDsList = product.variants;
      const productID = product.product_id;

      const info = await fetchSingleProduct(productID, jwt, admin.adminStatus);
      // NOTE: BEN CULLY WILL COME BACK TO THIS
      // shopify link === info.search_response.store_url / products / info.search_response.handle 
      // bigcommerce === 
      productText += `
        <h3>${info.search_response.product_title}</h3>
        <h3>Variant: ${admin.adminStatus ? info.search_response.variants[0].variant_title : ""}</h3>
        <h3>MPN: ${info.search_response.mpn}</h3>
        <p><a href="${info.search_response.referer}" rel="noopener noreferrer" target="_blank">Product Link</a></p>
        <i>(Add detailed product description)</i>
        <p><br></p>
      `;
      
      const variantText = await summarizeVariants(info, variantIDsList);
      
      productText += variantText;

      productText += `
      -----------------------------------------------------------------------------------------
      <p><br></p>
      `;

      loadout += productText;
    })
  );

  return loadout;
};