import { batch } from 'react-redux';
// import { push } from 'react-router-redux';
// Thunks
// import { setInvoiceHistory, setStripeInvoicesAndSubscriptions } from './billingThunks'
// api
import { 
  fetchUserByUUID, 
  fetchStore, 
  fetchImpresonateUser, 
  fetchLevarAccountId, 
  fetchSignUpInitial, 
  elasticInitialDataSync, 
  setFinalDataInRDS, 
  setWizardData,
  setFinalDataMFR,
  fetchCreateStore,
  fetchCreateVariantsFromCSV,
  fetchCreateProductsFromCSV,
} from '../../utils/fetchData';
// actions
import {
  getUserSuccess, 
  getIntegrationSuccess, 
  getStoreSuccess, 
  hasError, 
  resetAccount, 
  getUserInfoUpdates 
} from '../slices/account';
import { 
  setImpersonationUUID, 
  setAccountOwnerStatus, 
  setAdminStatus, 
  resetAdmin 
} from '../slices/admin';
import { resetCart } from '../slices/cart';
import { slackHandleCustomStoreSignupMessage } from './slackThunks';
// import { getDialog } from '../slices/dialog'
import { getMainLoading } from '../slices/loading';
import { setAppStatus } from '../slices/app';
// helpers
import { getJWTstorage } from '../../utils/jwt'
import { getStoreType, delay } from '../../utils/levarHelpers'
import { s3Connector } from '../../utils/s3Connector'
// Auth Hooks
// import useAuth from '../../hooks/useAuth';


// ----------------------------------------------------------------------

// **************************************************************************
// WHERE: used for regular users (shp,bco,cus and users without stores)
// DESCRIPTION: grabs all data relating to their store, user details, and levar account from RDS
// **************************************************************************
export function mainSignInDefault({ user }) {
  return async (dispatch) => {
    try {
      dispatch(getMainLoading({ status: true, text: "Signing In..." }));
      
      const jwt = getJWTstorage(false, user.sub);
      
      const { foundUser, foundUserIntergration } = await fetchUserByUUID(user.sub);
      
      if(!foundUser) { 
        throw new Error(`User does not exist`)
      }
      const resoAccount = await fetchLevarAccountId(user.sub);

      // For people who have not connected a store yet
      if(!foundUserIntergration) {
        batch(() => {
          dispatch(getUserSuccess(foundUser));
          dispatch(getIntegrationSuccess({
            id: null,
            integration_id: null,
            levar_account_id: resoAccount.account.levar_account_id,
            levar_user_id: resoAccount.account.admin_user_id
          }));
          dispatch(setAppStatus(true));
        });
        
      } else {
        // For SHP and BIG c we query the store info by account or UUID
        // For CUS we query the store info by store id
        let foundUserStore;
        switch(foundUserIntergration?.integration_id) {
          case 1:
            foundUserStore = await fetchStore(resoAccount.account.store_id, jwt, false);
            break;
          case 2:
            foundUserStore = await fetchStore(resoAccount.account.store_id, jwt, false);   
            foundUserStore.store_name = foundUserStore.store_title;
            foundUserStore.store_domain = foundUserStore.store_url;
            break;
          case 3:
            foundUserStore = await fetchStore(resoAccount.account.store_id, jwt, false);
            break;
          default:
            foundUserStore = await fetchStore(resoAccount.account.store_id, jwt, false);
            break;
        };

        // Fetching invoice history to make those MF's pay up
        if(foundUserStore?.search_response){
          foundUserStore = foundUserStore.search_response
        }

        batch(() => {
          dispatch(getUserSuccess(foundUser));
          dispatch(getIntegrationSuccess(foundUserIntergration))
          dispatch(getStoreSuccess(foundUserStore));
          dispatch(setAppStatus(true));
        });
      }
    } catch (error) {
      console.log(error)
      localStorage.clear();
      alert(error.message)
      // setTimeout(() => {
        window.location.assign(window.location.origin)
      // }, 1000)

      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500)
    }
  };
};


// **************************************************************************
// WHERE: used for admin users
// DESCRIPTION: grabs basic data from RDS and sets up redux state for post login views
// **************************************************************************
export function mainSignInAccountOwner({ user }) {
  return async (dispatch) => {

    dispatch(getMainLoading({ status: true, text: "Loading..." }));
    try {
      const { foundUser } = await fetchUserByUUID(user.sub);

      batch(() => {
        dispatch(getUserSuccess(foundUser));
        dispatch(setAccountOwnerStatus(true));
        dispatch(setAppStatus(true));
      })
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500)
    }
  };
};


// **************************************************************************
// WHERE: used for account owners (currently this would be LSA)
// DESCRIPTION: grabs basic data from RDS and sets up redux state for post login views
// **************************************************************************
export function mainSignInAdmin({ user }) {
  return async (dispatch, getState) => {
    dispatch(getMainLoading({ status: true, text: "Loading..." }));
    try {
      const { admin } = getState();
      // Trying to fix reload while impersonating
      // Re authenticate imperonated user when context is lost
      let param;
      if(admin.impersonatedUUID) { // Impersonate reload admin
        param = admin.impersonatedUUID
      } else { // Main sign in admin
        param = user.sub
      }

      const { foundUser } = await fetchUserByUUID(param);
      if(!foundUser) { // User not found
        throw new Error(`User does not exist`)
      }

      batch(() => {
        dispatch(getUserSuccess(foundUser));
        dispatch(setAdminStatus(true))
        dispatch(setAppStatus(true));
      });
    } catch (error) {
      console.log(error)
      localStorage.clear();
      window.location.assign(window.location.origin)
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500)
    }
    dispatch(setAppStatus(true));
  }
};


// **************************************************************************
// WHERE: used for admins and account owners (currently this would be LSA)
// DESCRIPTION: grabs basic data from RDS about the user being impersonated 
// sets up redux state.
// **************************************************************************
export function setDashboardImpersonate({ storeInfo }) {
  return async (dispatch) => {
    try {
      dispatch(getMainLoading({ status: true, text: `${storeInfo.store_title}...` }));
      // Get the correct query param
      // Custom stores dont have a platform variable on the index
      // which is why i use .includes
      const param = storeInfo.store_id.includes('cus') ? storeInfo.store_owner_uuid : storeInfo.levar_user_account_id

      // Grab user data 
      const impersonationData = await fetchImpresonateUser(param);
      const { foundUser, foundUserIntergration } = await fetchUserByUUID(impersonationData.levar_user.uuid);

      // Setup redux to match that user
      // The important part of this is the impersonation UUID
      batch(() => {
        dispatch(getStoreSuccess(storeInfo));
        dispatch(getIntegrationSuccess(foundUserIntergration));
        dispatch(getUserSuccess(foundUser));
        dispatch(setImpersonationUUID(param));
      });
    } catch(error) {
      console.log("error on impersonate", error)
      dispatch(hasError(error));
    } finally {
      // const charges = await dispatch(setInvoiceHistory())
      // const trigger = charges.filter(item => item.status === 'pending');

      // if(trigger.length > 0) {
      //   dispatch(getDialog({ 
      //     dialogStatus: true, 
      //     dialogType: 'unpaid-invoice', 
      //     dialogData: trigger
      //   }));
      // } 
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 100);

    }
  }
};


// **************************************************************************
// WHERE: used for every user type
// DESCRIPTION: resets redux state to initial state after user has been -
// deauthenticated through the useAuth context callback logout()
// **************************************************************************
export function setDashboardLogout() {
  return async (dispatch) => {
    dispatch(getMainLoading({ status: true, text: "Loading..." }));
    try {
      // Reset all redux state
      batch(() => {
        dispatch(resetAdmin());
        dispatch(resetAccount());
        dispatch(resetCart());
      })
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500);
    }
  };
};


// **************************************************************************
// WHERE: used for account owners and admin user type
// DESCRIPTION: resets redux imperonationUUID state to null and also resets
// redux account (store, user, integration) data to initial state
// **************************************************************************
export function setReturnFromImpersonation() {
  return async (dispatch) => {
    dispatch(getMainLoading({ status: true, text: "Loading..." }));
    try {
      // Reset redux (clean up for next impersonation)
      batch(() => {
        dispatch(setImpersonationUUID(null));
        dispatch(resetAccount());
        dispatch(resetCart());
      })
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500);
    }
  };
};


// **************************************************************************
// WHERE: used in the signup process right after the cognito email code is verified
// DESCRIPTION: Finds the intial entry made by the cognito post confirmation lambda -
// trigger and updates the users store url if that exists while also creating an account 
// if one doesnt already exist
// Store Table - Integration ID is connected (users index value from the user table) & account_id
// Integration Table - Account ID, Integration ID
// Account Table - store ID (if it exists inside the query params)
// User Table - Store url
// INPUT: email, storeurl, storeId, storetype
// **************************************************************************
export function setDataAfterVerify({ data, storeType }) {
  return async (dispatch) => {
    try {
      // This can be hardcoded since the stage at this point should always be 2
      const setupWizardState = 2;

      // Get a number
      const integrationID = getStoreType(storeType);

      // Works for all users
      // Updates or creates a basic user and account
      const signupIntialPayload = {
        email: data.email,
        setupWizardState,
        storeURL: data.storeURL,
        storeID: data.storeID,
        storeType,
        ...(storeType === 'mfr' && { accountOwnerUUID: data.accountOwnerUUID })
      }
      const { updatedUser, updatedAccount } = await fetchSignUpInitial(signupIntialPayload);

      // Works for all users
      // The user integration may or may not be returned by the above fetch call so we need to refetch it here
      // In cases where its not created means the user signed up through internal A 
      const { foundUserIntergration } = await fetchUserByUUID(updatedUser.uuid);

      // TODO: update dashboard-api-production so we dont have to do this
      // Handline for BCO users since integration doesnt get created until the end
      // Update user info in redux. This will come in handy later
      batch(() => {
        dispatch(getIntegrationSuccess({...(foundUserIntergration ? { ...foundUserIntergration } : {id: null, levar_user_id: updatedUser.id, levar_account_id: updatedAccount.levar_account_id, integration_id: integrationID})}));
        dispatch(getUserSuccess(updatedUser))
        dispatch(getStoreSuccess({ store_id: updatedAccount.store_id, store_url: data.storeURL }))
      })
    } catch(error){
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500);
    }
  }
};


// **************************************************************************
// WHERE: used in the signup wizard right after the user enters their name, country, phone etc
// DESCRIPTION: Finds the entry related entrys to the user and inputs the rest of the data
// -- will also run a sync on elastic search if its an external ecom route.
// User table - first name, last name, buisness name, phone number, country, setup wizard state, (if internal A store_url)
// Store Table - Nothing (if internal A then store_url and account ID)
// Integration Table - Nothing (if internal A then user id and account ID)
// Account Table - Nothing 
// LOCAL: externalRoute - this controls if the store will get synced
// INPUT: email, first_name, last_name, business_name, phone_number, country
// **************************************************************************
export function setWizardDataSync({ data, externalRoute }) {
  return async (dispatch, getState) => {
    dispatch(getMainLoading({ status: true, text: "One moment while we make note of the info provded"}));
    try {
      const { account: { user, store, integration }} = getState();

      // Status Used for redirecting to shopify (internal A)
      let status = true;
      let integrationID;

      // Set persons actual data in RDS
      await setWizardData(user.email, data.first_name, data.last_name, data.business_name, data.phone_number, data.country);
      
      // External A, External B
      if(externalRoute){
        console.log("EXTERNAL ROUTE")
        // This is for internal routes. We will have letters (bco,shp,cus) but we need a number
        if(!integration.integration_id || integration?.integration_id === null) integrationID = getStoreType(data.shop_type)
        integrationID = integration.integration_id
        
        // This is the final call that connects everything and creates the missing links if they are missing.      
        const finalRDSData = await setFinalDataInRDS(user.id, integration.levar_account_id, store.store_url, data.setupWizaredState, integrationID);

        // Figure out what variable we need to use in order to sync with elastic
        const storeHASHorID = integrationID === 1 ? finalRDSData.updatedStore.store_id : finalRDSData.updatedStore.store_hash

        // If store exists then sync it (External Routes)
        // TODO: this check really isnt needed 
        if(finalRDSData?.storeExists && finalRDSData?.storeExists === true) {
          await elasticInitialDataSync(user.uuid, store.store_url, storeHASHorID, integrationID);
        }
        status = true;

        // Internal A (provided url)
      } else if(!externalRoute){
        console.log("INTERNAL ROUTE")
        // Figure out integration ID
        if(!integration?.integration_id || integration?.integration_id === null) integrationID = getStoreType(data.shop_type);
        else integrationID = integration.integration_id;
        // This is the final call that connects everything and creates the missing links if they are missing.      
        const finalRDSData = await setFinalDataInRDS(user.id, integration.levar_account_id, data.store_url, data.setupWizaredState, 1);
        console.log("finalRDSData", finalRDSData)
        // if(finalRDSData !== undefined && finalRDSData?.storeExists === true) status = true;
        // else status = false; // This will allow a redirect to happen on WizardFrom
      } 
    
      // Update user info in redux
      dispatch(getUserInfoUpdates({        
        "email": user.email,
        "first_name": data.firstName,
        "last_name": data.lastName,
        "business_name": data.businessName,
        "country": data.country,
        "phone_number": data.phoneNumber,
      }));

      // Set app status to true for push into dashboard
      dispatch(setAppStatus(true));
      return status
    } catch(error){
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500);
    }
  }
};


export function setWizardDataSyncMFR({ data }) {
  return async (dispatch) => {
    dispatch(getMainLoading({ status: true, text: "One moment while we make note of the info provded"}));
    try {
      // Set persons actual data in RDS
      await setWizardData(data.email, data.first_name, data.last_name, data.business_name, data.phone_number, data.country);
      
      // This is the final call that connects everything and creates the missing links if they are missing.      
      const payload = {
        email: data.email,
        account_owner_uuid: data.accountOwnerUUID,
        store_url: data.store_url,
        setup_wizard_state: data.setupWizaredState
      }
      const finalRDSData = await setFinalDataMFR(payload);
      console.log("Final RDS DATA", finalRDSData)
      const createStorePayload = {
        add_to_cart: false,
        implementation_type: "viewer",
        store_id: `cus_${finalRDSData.customStoreEntry.store_id}`,
        account_owner_uuid: data.accountOwnerUUID,
        levar_user_account_id: finalRDSData.updatedUser.uuid,
        store_owner_uuid: finalRDSData.updatedUser.uuid,
        manufacturer_uuid: finalRDSData.updatedUser.uuid,
        store_url: data.store_url,
        store_title: data.business_name,
        account_owner_email: data.email,
        platform: "levar"
      };
      console.log("createStorePayload", createStorePayload)

      const esCreateStoreResponse = await fetchCreateStore(createStorePayload, data.jwt, false);
      console.log("esCreateStoreResponse", esCreateStoreResponse);
      console.log("delaying for 3 seconds")
      await delay(3000);

      // Send slack Signup message
      await dispatch(slackHandleCustomStoreSignupMessage())
      
      // Update user info in redux and set app status
      batch(() => {
        dispatch(getUserInfoUpdates({        
          "email": data.email,
          "first_name": data.firstName,
          "last_name": data.lastName,
          "business_name": data.businessName,
          "country": data.country,
          "phone_number": data.phoneNumber,
        }));
        dispatch(getIntegrationSuccess({ ...finalRDSData.userIntegration }));
        dispatch(getStoreSuccess({ ...esCreateStoreResponse._source }));
        dispatch(setAppStatus(true));
      });  
    } catch(error){
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(getMainLoading({ status: false, text: "" }));
      }, 500);
    }
  }
};

// Way to build custom store into store sign up
export function setCustomStoreUploaderComplete({ data }) {
  return async (dispatch, getState) => {
    try {
      const { account, admin } = getState();
      const jwt = getJWTstorage(admin, account.user.uuid);

      // console.log('account', account)
      // console.log('DATA:::', data)

      // Creates Custom Store In RDS, Updates users shop url and setup wizard stage
      const finalRDSData = await setFinalDataInRDS(account.user.id, account.integration.levar_account_id, data.storeURL, 5, 2);

      const storeID = finalRDSData.updatedAccount.store_id
      // console.log("storeID", storeID)

      const createESstorePayload = {
        add_to_cart: false,
        implementation_type: "viewer",
        store_id: storeID,
        // account_owner_uuid: "74a9a519-da21-46f9-a7bf-76d3af2c6bb6",
        account_owner_uuid: finalRDSData.userIntegration.levar_account_id,
        levar_user_account_id: finalRDSData.updatedUser.uuid,
        store_owner_uuid: finalRDSData.updatedUser.uuid,
        store_url: finalRDSData.updatedUser.user_shopify_url,
        store_title: data.storeName,
        account_owner_email: finalRDSData.updatedUser.email,
        platform: "levar",
        model_limit: 1
      };

      const esCreateStoreResponse = await fetchCreateStore(createESstorePayload, jwt, false);
      console.log("esCreateStoreResponse", esCreateStoreResponse);
      
      await delay(3000);
      
      // Upload CSV > S3
      await s3Connector(data.csvFile, 'csv', `public/${storeID}`);
      await delay(1000); // Delay for laggy uploads

      // create products and variants
      const csvName = data.csvFile[0].name;

      const payload = {
        csv_name: `public/${storeID}/${csvName}`,
        // account_owner_uuid: "74a9a519-da21-46f9-a7bf-76d3af2c6bb6",
        account_owner_uuid: finalRDSData.userIntegration.levar_account_id,
        levar_user_account_id: finalRDSData.updatedUser.uuid,
        store_owner_uuid: finalRDSData.updatedUser.uuid,
        store_id: storeID,
        store_title: data.storeName,
        email: finalRDSData.updatedUser.email
      };

      const variantCreateResponse = await fetchCreateVariantsFromCSV(payload, jwt, admin.adminStatus);
      console.log("variantCreateResponse", variantCreateResponse);

      await delay(9000);

      const productCreateResponse = await fetchCreateProductsFromCSV(payload, jwt, admin.adminStatus);
      console.log("productCreateResponse", productCreateResponse);

      return { 
        updatedIntegration: finalRDSData.userIntegration, 
        updatedStore: esCreateStoreResponse._source 
      };

    } catch(error){
      dispatch(hasError(error));
    }
  }
}


// **************************************************************************
// WHERE: used in the dashboard after the fact integration dialog. This dialog is controlled by redux
// DESCRIPTION: Takes an ecom store URL as entry and connects the user to that store. It will then direct them to install the app.
// User table - Store_url
// SHP Store Table - Store_url
// BCO Store Table - None (not supported yet)
// Integration Table - Integration ID and account ID
// Account Table - Nothing (the shopify app will update their store_id)
// INPUT: storeURL, shopType(string)
// **************************************************************************
export function setConnectStoreLater({ storeURL, storeType }) {
  return async (dispatch, getState) => {
    dispatch(getMainLoading({ status: true, text: "One moment while we make note of the info provded"}));
    try {
      const { account: { user, integration }} = getState();

      const integrationID = getStoreType(storeType);

      const updateRDSData = await setFinalDataInRDS(user.id, integration.levar_account_id, storeURL, 5, integrationID);
      console.log("updateRDSData", updateRDSData)

    } catch(error) {
      console.log(error)
    }
  }
};