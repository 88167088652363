import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
// import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
// component
import {
  RHFEditor,
} from '../hook-form';

// ----------------------------- Constants -----------------------------------

const EXAMPLE_LOADOUT = `Product Name

Variant
Color, Size, Scale (whatever makes up the variant)
Dimensions
Images

Variant
Color, Size, Scale (whatever makes up the variant)
Dimensions
Images`;

// ----------------------------- Styles --------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

const BlockCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  background: '#fefefef5',
  position: 'absolute',
  top: 0,
  left: 0,
  marginLeft: '16px',
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: borderActive ? `solid 2px ${theme.palette.gold.main}` : 'none',
  padding: 24
}));

const MainCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({
    padding: 22, 
    width: '100%', 
    border: borderActive ? `solid 2px ${theme.palette.gold.light}` : 'solid 2px #ffffff00',
}));

// ------------------------------------------------------------------------

FormTextEditorCard.propTypes = {
  ready: PropTypes.bool,
  methods: PropTypes.any,
  // handleTemplateAI: PropTypes.func,
  handleTemplateSimple: PropTypes.func,
  handleTemplateStatic: PropTypes.func,
  blockedUI: PropTypes.bool,
  setBlockedUI: PropTypes.func,
};

export default function FormTextEditorCard({
  ready,
  methods,
  // handleTemplateAI,
  handleTemplateSimple,
  handleTemplateStatic,
  blockedUI,
  setBlockedUI
}) {

  // Form
  const { watch } = methods;
  const values = watch();

  // state
  const [loading, setLoading] = useState(false);
  

  // ----------------------
  // Start Auto Generate AI Loadout
  // ----------------------
  const initiateLoadoutAI = async () => {
    setLoading(true);
    if(values.loadout_type === 1) {
      await handleTemplateSimple();
      // await handleTemplateAI(); // ChatGPT
    } else {
      handleTemplateStatic()
    }
    setLoading(false);
    setBlockedUI();
  };


  // ----------------------
  // Start Auto Generate AI Loadout
  // ----------------------
  const initiateLoadoutSimple = async () => {
    setLoading(true);
    if(values.loadout_type === 1) {
      await handleTemplateSimple();
    } else {
      handleTemplateStatic()
    }
    setLoading(false);
  };


  if(!ready) {
    return (
      <Skeleton
        sx={{ bgcolor: 'grey.200', borderRadius: 1, margin: '0 auto' }}
        variant="rectangular"
        height={510}
        animation="wave"
        width={'100%'}
      />
    )
  }

  return (
    <>
      <MainCardStyle borderActive={values.product_details.length <= values.minValueProductDetails}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <LabelStyle>
            Notes: Details & Dimension
          </LabelStyle>
          <LoadingButton 
            size="small" 
            color="primary" 
            variant="outlined"
            loading={loading}
            onClick={() => initiateLoadoutSimple()}
          >
            { values.loadout_type === 1 && 'Generate Template' }
            { values.loadout_type === 2 && 'Build Example' }
          </LoadingButton>
        </Stack>
        <RHFEditor 
          simple 
          name="product_details" 
          placeHolderText={EXAMPLE_LOADOUT} 
          sx={{ height: '100%', minHeight: 440, maxHeight: 440, overflowY: 'auto' }} 
        />
      </MainCardStyle>

      { blockedUI && 
      <BlockCardStyle borderActive={values.product_details.length <= values.minValueProductDetails}>
        
        <CardContent sx={{ padding: 0, mb: 2 }}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Step 1:
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Create Loadout Information
          </Typography>
          <Typography gutterBottom variant="body1">
            Providing detailed loadout information is critical to having accurate 3D models. 
            Either auto generate your loadout or do it yourself.
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div" sx={{ pt: 1, pb: 1, fontWeight: 500 }}>
            Below is an example of what you want to include in a loadout:
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 225,
              px: 2,
              py: 2,
              background: '#f9f9f98f',
              borderRadius: .5,
              boxShadow: 'inset 0px 0px 6px 1px #a7a7a74f'
            }}
          >
            <code style={{ fontSize: 15 }}>
            <p style={{ paddingBottom: 5 }}><strong>Product Title:</strong></p>
            <p style={{ paddingBottom: 10 }}>Detailed product description and accurate dimensions, example of size, and weight.</p>
            <p><strong>Variants of Product (Requesting):</strong></p>
            <ol style={{ marginLeft: 32 }}>
              <li>Red 1" x 2"</li>
              <li>Teal 2" x 3"</li>
              <li>Walnut 4" x 3"</li>
            </ol>
            </code>
          </Box>
        </CardContent>

        <CardActions sx={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            size="small" 
            color="primary" 
            variant="outlined"
            onClick={setBlockedUI}
          >
            Start From Scratch
          </Button>
          <LoadingButton 
            size="small" 
            color="primary" 
            variant="contained"
            loading={loading}
            onClick={() => initiateLoadoutAI()}
          >
            { values.loadout_type === 1 && 'Generate Template' }
            { values.loadout_type === 2 && 'Build Example' }
          </LoadingButton>
        </CardActions>

      </BlockCardStyle>
    }
    </>
  )
};