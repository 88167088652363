import jwtDecode from 'jwt-decode';
import { PATH_AUTH } from '../routes/paths';
import { axiosInstanceEcom } from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  // TO TEST BAD TOKEN
  // return false
  if (!accessToken) {
    console.log('MISSING JWT')
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axiosInstanceEcom.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axiosInstanceEcom.defaults.headers.common.Authorization;
  }
};

const getJWTstorage = (reduxAdmin, uuid) => {
  // const session = await Auth.currentSession();
  const { REACT_APP_USER_POOL_WEB_CLIENT_ID } = process.env;

  let jwt;
  if(reduxAdmin.adminStatus === true) {
    const adminUUID = localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`];
    jwt = localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.${adminUUID}.idToken`];
  } else if(reduxAdmin.accountOwnerStatus){
    const accountOwnerUUID = localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`];
    jwt = localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.${accountOwnerUUID}.idToken`];
  } else {
    jwt = localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.${uuid}.idToken`];
  }
  // return jwt;
  if (jwt && isValidToken(jwt)) {
    // Everyrthing is fine ??
    return jwt;
  }

  console.log('RETURNING EXPIRED BAD TOKEN')
  // Need to re validate token
  // const newToken = await refreshToken(userKey); // Implement this function to refresh the token
  //   console.log('NEEDING TO REFRESH TOKEN::', newToken)
  //   if (newToken) {
  //     localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.${userKey}.idToken`] = newToken;
  //     return newToken;
  //   }
  
  //   // Handle the case where the token can't be refreshed
  //   console.error('Unable to refresh token or session expired.');
  
  //   // Optionally, you can alert the user or display a message using your UI framework
  //   alert('Session expired. Please log in again.');
  
  //   // Redirect to login page
  //   window.location.href = PATH_AUTH.login;
  
  //   // You might also want to clear any stored session information
  //   localStorage.removeItem('accessToken');
  //   // Clear any other local storage or session storage items related to the user session
  //   // ...
  //   return null;
}

// This function should be implemented to call your auth server or use an SDK method to refresh the token
// const refreshToken = async (userKey) => {
//   try {
//     const { REACT_APP_USER_POOL_WEB_CLIENT_ID } = process.env;
//     // Assuming you have an endpoint to refresh the token
//     const response = await axiosInstance.post('/auth/refresh-token', {
//       // You'll need to send the necessary data to refresh the token
//       // This could be a refresh token or other relevant information
//     });

//     // The response should contain the new access token
//     const { accessToken } = response.data;

//     // Store the new token
//     localStorage[`CognitoIdentityServiceProvider.${REACT_APP_USER_POOL_WEB_CLIENT_ID}.${userKey}.idToken`] = accessToken;

//     // Update axios instance if you're using it for API calls
//     axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

//     return accessToken;
//   } catch (error) {
//     console.error('Error refreshing token:', error);
//     // Handle error, possibly by redirecting to login or showing a message
//     return null;
//   }
// };


export { isValidToken, setSession, getJWTstorage };
