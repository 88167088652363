// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  file: getIcon('ic_file'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  order: getIcon('ic_order'),
  product: getIcon('ic_product'),
  tour: getIcon('ic_tour'),
};


// DAHSBOARD
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.general.overview, icon: ICONS.dashboard },
    ],
  },
  // LEVAR STUDIOS
  // ----------------------------------------------------------------------
  {
    subheader: 'LEVAR Studio',
    items: [
      // {
      //   title: '3D Model Creation',
      //   path: PATH_DASHBOARD.creation.root,
      //   icon: ICONS.kanban,
      //   children: [
      //     // { title: 'Project & Loadouts', path: PATH_DASHBOARD.creation.projects, specialChar: 'mdi:number-one-circle-outline' },
      //     // { title: 'Loadouts In Progress', path: PATH_DASHBOARD.creation.progress, specialChar: 'mdi:number-two-circle-outline' },
      //     // { title: 'Models Pending Review', path: PATH_DASHBOARD.creation.review, specialChar: 'mdi:number-three-circle-outline' },
      //     // { title: 'Approved Models', path: PATH_DASHBOARD.creation.approved, specialChar: 'mdi:number-four-circle-outline' },
      //   ],
      // },
      // { title: '3D Art Projects', path: PATH_DASHBOARD.creation.projects, specialChar: 'grommet-icons:projects' },
      // { title: 'Loadouts In Progress', path: PATH_DASHBOARD.creation.progress, specialChar: 'grommet-icons:in-progress' },
      
      // {
      //   title: 'Create 3D Models',
      //   path: PATH_DASHBOARD.creation.root,
      //   // icon: ICONS.dashboard,
      //   specialChar: 'gis:cube-3d'
      // },
      {
        title: 'Create 3D Models',
        path: PATH_DASHBOARD.create.root,
        specialChar: 'fluent-mdl2:edit-create'
      },
      {
        title: 'Upload 3D Models',
        path: PATH_DASHBOARD.upload.root,
        specialChar: 'material-symbols:upload'
      },
      // { 
      //   title: '3D Models Pending Review', 
      //   path: PATH_DASHBOARD.creation.review, 
      //   specialChar: 'mdi:account-pending-outline' 
      // },
      {
        title: '3D Model Library',
        path: PATH_DASHBOARD.modelLibrary.root,
        specialChar: 'bi:badge-3d' 
        // icon: ICONS.banking,
      },
      { 
        title: 'Ecommerce Library', 
        path: PATH_DASHBOARD.ecommerce.root, 
        specialChar: 'solar:library-bold'
      },
    ],
  },

  // {
  //   subheader: 'Marketing',
  //   items: [
  //     { title: '3D/AR Activations', path: '/dashboard/three', icon: ICONS.menuItem, disabled: true },
  //     { title: 'Product Spotlight', path: '/dashboard/two', icon: ICONS.menuItem, disabled: true },
  //   ],
  // },

  // ANALYTICS
  // ----------------------------------------------------------------------
  {
    subheader: 'Analytics',
    items: [
      { title: 'Overview', path: PATH_DASHBOARD.analytics.overview, icon: ICONS.analytics },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // Account Settings
      {
        title: 'account',
        path: PATH_DASHBOARD.account.root,
        icon: ICONS.user,
        children: [
          { title: 'General', path: PATH_DASHBOARD.account.general, disabled: false  },
          { title: 'Billing', path: PATH_DASHBOARD.account.billing },
          { title: 'Change Password', path: PATH_DASHBOARD.account.password },
        ],
      },
      {
        title: 'store',
        path: PATH_DASHBOARD.store.root,
        icon: ICONS.cart,
        children: [
          { title: 'Overview', path: PATH_DASHBOARD.store.overview, disabled: false  },
        ],
      },
      {
        title: 'admin',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.kanban,
        children: [
          { title: 'Micro Site Builder', path: PATH_DASHBOARD.admin.micro_overview, disabled: false  },
        ],
      },


    ],
  },
];

export default navConfig;
