import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2'; // TODO:  probably need to update this import in the future
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// components
import { 
  FormProvider, 
  RHFTextField, 
} from '../../hook-form';
import useAuth from '../../../hooks/useAuth';
import { useDispatch, useSelector } from '../../../redux/store';
import { delay } from '../../../utils/levarHelpers';
import { fetchSetupInvoice } from '../../../utils/fetchData';
import { getJWTstorage } from '../../../utils/jwt';

const { REACT_APP_SHOPIFY_TEST } = process.env;


// ----------------------------------------------------------------------

OneTimeCharge.propTypes = {
  storeData: PropTypes.object.isRequired,
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.lighter,
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function OneTimeCharge({ storeData }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const admin = useSelector((state) => state.admin);
  const { enqueueSnackbar } = useSnackbar();
  
  const ChargeSchema = Yup.object().shape({
    store_title: Yup.string().nullable(),
    store_id: Yup.string().nullable(),
    platform: Yup.string().nullable(),
    recurring_application_charge_id: Yup.string().nullable(),

    // Pricing
    charge_name: Yup.string().nullable(),
    price: Yup.number().nullable(),
  });

  const defaultValues = {
    store_title: storeData.store_title,
    store_id: storeData.store_id,
    platform: storeData.platform,
    recurring_application_charge_id: storeData.recurring_application_charge_id,

    // Pricing
    charge_name: '',
    price: 0,
  };

  const methods = useForm({
    resolver: yupResolver(ChargeSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [state, setState] = useState({
    store: null,
    pageReady: false,
    isWriting: false,
  });

  useEffect(() => {
    let isComponentMounted = true;

    if(isComponentMounted && storeData) {
      const getResource = async () => {

        setState((prevState) => ({
          ...prevState,
          store: storeData,
          pageReady: true,
          isWriting: false,
        }))
      };
      getResource();
    }
    return function cleanup() {
        isComponentMounted = false;
    }
  }, [storeData, dispatch, setValue])

  // Submit edited options
  const onSubmit = async () => {
    try {
      const jwt = getJWTstorage(admin, user.sub);
      let chargeBody;
      let createdChargeID;
      let chargeResponse;
      const status = false // IF true it sets the thing to ACTIVE which we dont want
      switch(state.store.platform) {
        case 'shopify':
          chargeBody = {
            price: values.price,
            charge_name: values.charge_name,
            shopify_url: state.store.store_domain,
            store_id: state.store.store_id,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true,
            ...( status && { status: 'ACTIVE' }),
          };
          chargeResponse = await fetchSetupInvoice(chargeBody, jwt, 1);
          createdChargeID = chargeResponse.confirmation.charge_id;
          break;
        case 'levar': 
        case 'stripe':
          chargeBody = {
            customer_id: state.store.stripe_customer_id,
            amount: values.price * 100,
            currency: "usd",
            description: values.charge_name,
            receipt_email: state.store.levar_email,
            store_id: state.store.store_id,
            test: REACT_APP_SHOPIFY_TEST === 'true' && true,
            ...( status && { status: 'active' }),
          };
          chargeResponse = await fetchSetupInvoice(chargeBody, jwt, 3);
          createdChargeID = chargeResponse.index_response._id
          break;
        default:
          break;
      };
      
      enqueueSnackbar(`Charge ${createdChargeID} Created Success!`, { variant: 'success' });
    } catch (error) {
      console.error("onSubmit error", error);
    } finally {
      await delay(200)
      reset();
    }
  };

  return (
    <Card sx={{ p: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column">
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" align="center" color="custom.light" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
              One Time Charge
            </Typography>
          </Box>

            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item xs={12}>
                <Item>
                  <RHFTextField name="charge_name" size="small" label="Charge Name" />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <RHFTextField name="price" size="small" label="Price" type="number" />
                </Item>
              </Grid>
            </Grid>
          <Box sx={{ mt: 1, p: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Button
                variant="outlined" 
                size="medium" 
                onClick={() => reset()}
              >
                Reset
              </Button>
              <LoadingButton 
                variant="contained" 
                size="medium" 
                type="submit"
                loading={isSubmitting}
              >
                Charge
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </FormProvider>
    </Card>
  )
};