import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// MUI
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Components
import useRouter from '../../hooks/useRouter';
// Routes
import { PATH_DASHBOARD } from '../../routes/paths';

PayUsNowDialog.propTypes = {
  dialogData: PropTypes.any,
  onClose: PropTypes.func,
}

// Used for making our customers pay their open invoices
export default function PayUsNowDialog({ dialogData, onClose }) {
  const router = useRouter()
  const handleView = () => {
    onClose()
    router.push(PATH_DASHBOARD.account.billing)
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-evenly" sx={{ height: '100%',  minHeight: 300}}>
      <Typography variant="h4">Attention</Typography>
      <Typography variant="subtitle1">
        You have {dialogData.length} unpaid invoices
      </Typography>
      <Button variant="contained" onClick={handleView} size="large">
        Pay Now
      </Button>
    </Stack>
  )
};