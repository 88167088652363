import { 
  // fetchApproveGhostVariant, // 2023
  // fetchModel, // 2024
  // fetchSingleVariant,
  // fetchUpdateGhostVariant, // 2023
  // fetchDeleteModel, // 2023
  fetchUpdateEcommerceVariantFields, // 2023
  fetchUpdateModelFields, // 2023
  fetchCreateVariantsFromCSV,
  fetchCreateProductsFromCSV,
  // fetchGhostsByConnectedVariant, // 2023
  // fetchVariantsByModelId,
} from '../../utils/fetchData';
// helpers
import { getJWTstorage } from '../../utils/jwt'
import { 
  V_LIST_IMPLEMENTATION_TEMPLATE,
  LEVAR_ECOMM_VARIANT_DEFAULT_RESET
} from '../../utils/levar_data';
// import { vListImplementationDifferences } from '../../utils/levarHelpers';
import { delay } from '../../utils/levarHelpers';


// **************************************************************************
// Connects model_3d_id to ecommerce variant
// Input: 
//    model3dID => model_3d_id (from model index) , 
//    ecommVariantIds => single string or multi list
// Output: Normal update response from es
// **************************************************************************
export function setCreateProductVariantFromCSV({ 
  csvName
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user, store }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      await delay(1000); // Delay for laggy uploads

      const payload = {
        csv_name: `public/${store.store_id}/${csvName}`,
        account_owner_uuid: store.account_owner_uuid, // 
        levar_user_account_id: store.levar_user_account_id ?? user.uuid,
        store_owner_uuid: store.store_owner_uuid ?? user.uuid,
        manufacturer_uuid: store.manufacturer_uuid, //
        store_id: store.store_id,
        store_title: store.store_title,
        email: user.email
      };
      
      // console.log('payload', payload)

      const variantCreateResponse = await fetchCreateVariantsFromCSV(payload, jwt, admin.adminStatus);
      console.log("variantCreateResponse", variantCreateResponse);

      await delay(9000);

      const productCreateResponse = await fetchCreateProductsFromCSV(payload, jwt, admin.adminStatus);
      console.log("productCreateResponse", productCreateResponse);
      return { variantCreateResponse, productCreateResponse };
    } catch(error) {
      console.log('error on setCreateProductVariantFromCSV thunk', error);
      return error;
    }
  }
};

// **************************************************************************
// Connects model_3d_id to ecommerce variant
// Input: 
//    model3dID => model_3d_id (from model index) , 
//    ecommVariantIds => single string or multi list
// Output: Normal update response from es
// **************************************************************************
export function setConnectModelToMultipleEcommVariants({ 
  model3dID, 
  ecommVariantIds,
  implementationToTransfer,
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const modelPayload = { 
        model_status: "on", 
        model_3d_id: model3dID 
      };

      const updatedModel = await fetchUpdateModelFields(modelPayload, jwt);
      const modelType = updatedModel.update_response.model_type;
      
      const variantsUpdated = [];

      const updateVariant = async (item) => {
        const dataToUpdate = { 
          variant_id: item, 
          ...implementationToTransfer
        };

        switch(modelType) {
          case "static_viewer_0":
          case "static_vto_face_0":
          case "room_viewer_0":
            dataToUpdate[modelType] = model3dID;
            break;
          default:
            dataToUpdate.model_3d_id = model3dID;
            break;
        };

        const response = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
        
        if(response.statusCode === 200) variantsUpdated.push(item);
      };

      if(Array.isArray(ecommVariantIds)) {
        await Promise.all(ecommVariantIds.map(updateVariant));
        return variantsUpdated;
      } 

      const dataToUpdate = {
        variant_id: ecommVariantIds,
        ...implementationToTransfer
      };

      switch(modelType) {
        case "static_viewer_0":
        case "static_vto_face_0":
        case "room_viewer_0":
          dataToUpdate[modelType] = model3dID;
          break;
        default:
          dataToUpdate.model_3d_id = model3dID;
          break;
      };

      const variantResponse = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
      return variantResponse;
      
    } catch(error) {
      console.log('error on setConnectModelToMultipleEcommVariants thunk', error);
      return error;
    }
  }
};


// Update single variant
// Input: 
//    ecommVariantIds => single string or multi list
//    settingsToWrite => Object of settings to write
//    destructure => Boolean to destructure settingsToWrite
// Output: Normal update response from es
// **************************************************************************
export function setUpdateEcommVariants({ 
  ecommVariantIds,
  settingsToWrite,
  destructure = false
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);
      const variantsUpdated = [];

      const updateVariant = async (item) => {
        const dataToUpdate = { variant_id: item, ...(destructure ? settingsToWrite : { settingsToWrite }) };
        const response = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
        if(response.statusCode === 200) variantsUpdated.push(item);
      };

      if(Array.isArray(ecommVariantIds)) {
        await Promise.all(ecommVariantIds.map(updateVariant));
        return variantsUpdated;
      } 

      const dataToUpdate = { variant_id: ecommVariantIds, ...(destructure ? settingsToWrite : { settingsToWrite })};
      const variantResponse = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
      return variantResponse;
      
    } catch(error) {
      console.log('error on setUpdateEcommVariants thunk', error);
      return error;
    }
  }
};


// **************************************************************************
// Connects model_3d_id to ecommerce variant
// Input: 
//     modelVariantArray: [{ecommerce_variant_id, model_3d_id}, {ecommerce_variant_id, model_3d_id}]
//    implementationToTransfer => Object of implementation setting
// Output: Normal update response from es
// **************************************************************************
export function setObjectsOfOneModelToOneVariant({ 
  modelVariantArray, 
  implementationToTransfer,
  modelType
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // Holds all good responses
      const variantsUpdated = [];

      // AN array of many {variant, model}
      // one variant to one model
      await Promise.all(
        modelVariantArray.map(async(item) => {

          const key = modelType || 'model_3d_id';
          
          const dataToUpdate = {
            variant_id: item.ecommerce_variant_id, 
            [key]: item[key],
            ...implementationToTransfer
          };

          const response = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
          
          if(response.statusCode === 200) variantsUpdated.push(item);
        })
      );
      return variantsUpdated;

    } catch(error) {
      console.log('error on setObjectsOfOneModelToOneVariant thunk', error);
      return error;
    }
  }
};


// 2024
// **************************************************************************
// Connects model_3d_id to SINGLE levar_ecommerce_variant
// Input: 
//      ecommStatus, 
//      model3dId, 
//      ecommVariantId,
//      modelType = 'model_3d_id'
// Output:
// **************************************************************************
export function setConnectModelToSingleEcommVariant({ 
  ecommStatus, 
  model3dId, 
  ecommVariantId,
  modelType = 'model_3d_id'
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      // // if ecommStatus === false want to write model_3d_id
      // // if ecommStatus === true want to delete model_3d_id and reset IMPLEMENTATION_TEMPLATE
      const defaultImplementation = V_LIST_IMPLEMENTATION_TEMPLATE

      
      // static_viewer_0
      //   * Model Viewer
      // interactive_viewer_0 || model_3d_id NOTE: model_3d_id will be deprecated in the future
      //     * Levar Fibrous
      // static_vto_face_0
      //     * VTO PDP / VTO APP
      // interactive_vto_face_0
      //     * VTO Interactive App
      // interactive_absolute_ground_0  
      //     * 8th wall
      // interactive_absolute_wall_0
      //     * 8th wall
      // interactive_absolute_lighting_0
      //     * 8th wall
      // room_viewer_0


      const dataToUpdate = {
        variant_id: ecommVariantId,
        [modelType]: !ecommStatus ? model3dId : "",
        ...((ecommStatus && modelType === "interactive_viewer_0") && { model_3d_id: "" }), // Old variant compatibility
        ...(ecommStatus && defaultImplementation)
      };
      console.log('dataToUpdate', dataToUpdate);
      const updatedEcommVariant = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);

      return updatedEcommVariant;
    } catch(error) {
      console.log('error on setConnectModelToSingleEcommVariant thunk', error);
      return error;
    }
  }
};

// **************************************************************************
// Copies activation settings to specified variants
// Input: 
//    ModelID => model_3d_id (from model index) , 
//    EcomVariants => single string or multi list
// Output:
// **************************************************************************
// export function setCopyImplementationToEcommVariants({ ogEcomVariantObj, ecommVariantsToCopyTo }) {
//   return async (dispatch, getState) => {
//     try {
//       const { account: { user }, admin } = getState();
//       const jwt = getJWTstorage(admin, user.uuid);

//       // Check ogVariants differences compared to default
//       const differencesFromDefault = vListImplementationDifferences(ogEcomVariantObj);
//       if(!differencesFromDefault) return false; // If no changes exit

//       await Promise.all(
//         ecommVariantsToCopyTo.map(async(variantId) => {
//           const dataToUpdate = {
//             variant_id: variantId,
//             ...(differencesFromDefault && differencesFromDefault)
//           };
//           await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
//         })
//       );
//       return true;
//     } catch(error) {
//       console.log('error on setCopyImplementationToEcommVariants thunk', error);
//       return error;
//     }
//   }
// };

// **************************************************************************
// 2024
// **************************************************************************
export function resetAllEcommerceVariantsFull({ variants }) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const variantsUpdated = []

      await Promise.all(
        variants.map(async(variant) => {
          const payload = {
            variant_id: variant.variant_id,
            ...LEVAR_ECOMM_VARIANT_DEFAULT_RESET
          };
          const vResposne = await fetchUpdateEcommerceVariantFields(payload, jwt, admin.adminStatus);
          variantsUpdated.push(vResposne._source.variant_id)
        })
      );  

      return variantsUpdated;
    } catch(error) {
      console.log('error on resetAllEcommerceVariantsFull thunk', error);
      return error;
    }
  }
};

// **************************************************************************
// 2024
// **************************************************************************
export function setCopyStoreImplementationToVariants({ 
  variantIDList, 
  implementationToCopy 
}) {
  return async (dispatch, getState) => {
    try {
      const { account: { user }, admin } = getState();
      const jwt = getJWTstorage(admin, user.uuid);

      const filteredImplementationToCopy = Object.fromEntries(
        Object.entries(implementationToCopy).filter(([, value]) => value !== false && value !== null)
      );

      await Promise.all(
        variantIDList.map(async(variantId) => {
          const dataToUpdate = {
            variant_id: variantId,
            ...filteredImplementationToCopy
          };

          await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);
        })
      );
      return true;
    } catch(error) {
      console.log(error);
      return error;
    }
  }
};


// export function setUploadProductVariantCSV({ link }) {
//   return async (dispatch, getState) => {
//     try {
//       const { account: { user }, admin } = getState();
//       const jwt = getJWTstorage(admin, user.uuid);

//       const response = await fetchUpdateEcommerceVariantFields(dataToUpdate, jwt, admin.adminStatus);

//       return response;
//     } catch(error) {
//       console.log(error);
//       return error;
//     }
//   }
// }
