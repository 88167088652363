import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// Components
import {
  RHFCheckbox,
} from '../../hook-form';

// ------------------------------------------------------------------------

const BlockCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  background: '#fefefef5',
  position: 'absolute',
  top: 0,
  left: 0,
  marginLeft: '16px',
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  justifyContent: 'space-between',
  border: borderActive ? `solid 2px ${theme.palette.gold.main}` : 'none',
}));

const MainCardStyle = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderActive"
})(({ theme, borderActive }) => ({ 
    border: borderActive ? `solid 2px ${theme.palette.gold.light}` : 'solid 2px #ffffff00',
}));

// ------------------------------------------------------------------------

LoadoutChecklistCard.propTypes = {
  ready: PropTypes.bool,
  methods: PropTypes.any,
  blockedUI: PropTypes.bool,
  setBlockedUI: PropTypes.func,
};

export default function LoadoutChecklistCard({ 
  ready, 
  methods,
  blockedUI,
  setBlockedUI,
}) {
  // Form
  const { watch } = methods;

  const values = watch();
  
  if(!ready) {
    return (
      <Skeleton 
        animation="wave" 
        variant="rectangular" 
        width={'100%'} 
        height={290}
        sx={{ borderRadius: 2 }} 
      />
    )
  };
  
  return (
    <>
      <MainCardStyle 
        borderActive={
          values.additional_product_photos.length >= values.minValueProductPhotos && 
          (!values.productDetailsCheck || !values.dimensionsCheck || !values.additionalProductPhotosCheck)
      }>

        <Stack direction="column" sx={{ pt: 2, pr: 2, pl: 2 }}>
          <Typography variant="h6" sx={{ textTransform: 'capitalize', fontSize: '1rem' }}>
            Loadout Review
          </Typography>
          <Typography variant="body2">
            (Confirm to submit by checking all information has been added)
          </Typography>
        </Stack>
        
        <Box
          sx={{ 
            display: 'flex', 
            flexDirection: "column", 
            mx: 2.5, 
            height: '100%',
            // my: 3
            mt: 1,
            mb: 5
          }}
        >
          {/* checkbox 1 */}
          <RHFCheckbox name="productDetailsCheck" label="Detailed Information Provided" /> 
          {/* checkbox 2 */}
          <RHFCheckbox name="dimensionsCheck" label="Specific Dimensions Included on all Variants"  />
          {/* checkbox 3 */}
          <RHFCheckbox name="additionalProductPhotosCheck" label="All Images Provided"  />
        </Box>
      </MainCardStyle>

      { blockedUI && 
      <BlockCardStyle borderActive={values.additional_product_photos.length >= values.minValueProductPhotos}>
        <CardContent 
          sx={{ 
            maxWidth: 500, 
            padding: 0, 
            mb: 2,
            color: values.additional_product_photos.length < values.minValueProductPhotos ? '#aeaeae' : 'inherit' 
          }}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Step 3:
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Loadout Review & Submit
          </Typography>
          <Typography variant="body1">
            Check all the boxes to confirm that you have provided the necessary information, then click submit for review.
          </Typography>
        </CardContent>
        <CardActions sx={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            size="small" 
            color="primary" 
            variant="contained"
            onClick={() => setBlockedUI()}
            disabled={values.additional_product_photos.length < values.minValueProductPhotos}
          >
            Complete Final Checklist
          </Button>
        </CardActions>

      </BlockCardStyle>
      }      
    </>
  )
};