import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isReady: false
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {

    // SET APP STATUS
    setAppStatus(state, action) {
      state.isReady = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setAppStatus
} = slice.actions;
