import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import { useResponsive } from '../../../hooks/useResponsive';
// utils
import css from '../../../theme/css';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
// import Searchbar from './Searchbar';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -1,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme, usersetupwizardstate }) => ({
  ...css(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: usersetupwizardstate > 5 ? `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)` : '100%',
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  usersetupwizardstate: PropTypes.number,
  openCart: PropTypes.any,
  cartTotal: PropTypes.any
};

export default function DashboardHeader({ 
  onOpenSidebar, 
  isCollapse = false, 
  verticalLayout = false,
  usersetupwizardstate = 5,
  openCart,
  cartTotal
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle 
      isCollapse={isCollapse} 
      isOffset={isOffset} 
      verticalLayout={verticalLayout} 
      usersetupwizardstate={usersetupwizardstate}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}


        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <IconButtonAnimate
            // color={open ? 'primary' : 'default'}
            disabled={cartTotal < 1}
            onClick={openCart}
            sx={{
              width: 40,
              height: 40,
              // ...(open && {
              //   bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              // }),
            }}
          >
            <StyledBadge badgeContent={cartTotal ?? 0} color="gold">
              <Iconify icon={'mdi:cart'} width={20} height={20} />
            </StyledBadge>
          </IconButtonAnimate>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
